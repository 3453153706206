.LotteryPage{
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;


  @include breakpoint('phone-wide', max) {
    padding-top: 10px;
    // Account for the sticky buy section
    padding-bottom: 300px;

    margin-top: 15px;
  }

  &__title{
    font-size: 29px;
    line-height: 35px;
    color: white;
    font-weight: 500;
    text-align: center;

    @include breakpoint('phone-wide', max){
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__subtitle{
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-align: center;
    white-space: pre;

    @include breakpoint('phone-wide', max){
      font-size: 12px;
      line-height: 15px;
    }
  }

  .NFTDrop{
    margin-top: 35px;

    @include breakpoint('phone-wide', max){
      margin-top: 20px;

      .LotterySection--buy{
        position: fixed;
        bottom: 0;
        max-width: 330px;
        width: 100%;
        background-color: $color-background;

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}