.NavBar{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1185px;
  background-color: $color-background;
  z-index: 1000;
  padding-left: 20px;
  padding-right: 20px;

  .NavBarBackButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;

    img{
      height: 12px;
    }
  }

  &__right{
    margin-left: auto;

    .NavBarItem{
      margin-right: 0;
    }
  }

  &__left{
    display: flex;
    align-items: center;
  }

  &__middle{
    display: flex;
    align-items: start;
    justify-content: center;
    flex: 2;
  }

  &__right{
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  @include breakpoint("tablet", max){
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
  }

  &--staking{
    height: 74px;
    border-bottom: 1px solid #382F47;
    border-bottom: 1px solid transparent;
    max-width: 1185px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;

    @include breakpoint("tablet", max){
      padding-left: 12px;
      padding-right: 12px;
    }

    .NavBarItem{
      color: $color-text-grey;
    }

    .PlasmBalances{
      margin-right: 16px;
      margin-left: auto;
    }

    .NavBarUser{
      margin-left: unset;
    }
  }
}

.NavBarItem{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  letter-spacing: 0px;
  color: $color-text-grey;
  line-height: 16px;
  font-size: 13px;
  font-family: $font-family-bold;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;

  @include breakpoint("950px", max){
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px;
  }

  &:hover{
    color: $color-green;
  }

  &--Members{
    color: $color-orange;

    &:hover{
      color: darken($color-orange, 8);
    }
  }
}

.NavBarSocialIcon{
  width: 24px;
  height: 24px;
  margin-left: 12px;

  img{
    width: 100%;
  }

  &:hover{
    opacity: 0.75;
  }
}

.NavBarHamburgerButton{
  position: absolute;
  left: 20px;
}


.NavBarMenuHeader{
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-bottom: 40px;

  &__close-button{

  }

  &__social-icons{
    display: flex;
    flex-direction: row;
  }

  .NavBarSocialIcon {
    width: 28px;
    height: 28px;
  }
}

.NavBarLogo{
  //margin-right: auto;
  //height: 38px;
  margin-right: 12px;
  width: 46px;

  @include breakpoint("tablet-small", max){
    height: 36px;
  }
}

.NavBarUser{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  &__avatar{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    transition: ease-in-out 0.2s;

    &:hover{
      transform: scale(1.05);
    }

    div{
      height: inherit;
      width: inherit;
    }
  }

  &__name{
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    margin-right: 12px;
  }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100% !important;
  width: 300px !important;
  top: 0 !important;
  right: initial;
  left: 0 !important;

  [aria-hidden="true"]{
    left: -300 !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: $color-background !important;
  padding: 0 !important;
  font-size: inherit !important;
  top: 0 !important;

  .NavBarItem{
    margin-left: 20px;
    margin-right: 20px;
    height: 18px;
    margin-bottom: 30px;
  }
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0 !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.NavBarUserDropdown{
  width: 180px;
  background-color: #1C1824;
  border-radius: 8px;
  border: 1px solid #382F47;

  &__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #382F47;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #72638C;

    &:first-of-type{
      color: white;
      font-weight: 500;
    }

    &:last-of-type{
        border-bottom: none;
    }

    &--danger{
      color: $color-red;
    }
  }
}

.NavBarStakeButtonWrapper{
  position: relative;
  display: flex;
}

.NavBarStakeDropdown{
  width: 180px;
  background-color: #1C1824;
  border-radius: 8px;
  border: 1px solid #382F47;

  &__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #382F47;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #72638C;

    &:last-of-type{
      border-bottom: none;
    }
  }
}

.NavBarUserDropdownVIPTier {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  border-bottom: 1px solid #382F47;


  &__title {
    font-size: 11px;
    font-weight: bold;
    color: #72638C;
    line-height: 14px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &__word {
    height: 9px;
    align-self: flex-start;
  }
}