.GetPlasmModal{
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: auto;
  min-height: 300px;

  .PlasmInput{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;

    input{
      text-indent: 66px;
      text-align: left;
    }

    &--empty{

      input{
        font-size: 20px;
        font-weight: 500;
        font-family: $font-family-normal;
      }

      &:after{
        display: none !important;
      }
    }
  }

  @include breakpoint("tablet", max){
    max-height: 90vh;
  }

  .RoundedModal__content{
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__content{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__cost-title{
    font-size: 12px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 16px;
    color: #72638C;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__tfuel-amount{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    color: white;
    font-weight: bold;
    font-family: $font-family-bold;
    width: 100%;
    justify-content: center;
    height: 26px;
    align-items: center;
    margin-bottom: 35px;

    img{
      height: 18px;
      width: 18px;
      margin-right: 8px;
    }
  }

  .Button{
    margin-top: auto;
  }

  input{
    text-align: center;
    color: $color-green;
    font-weight: bold;
    //padding-right: 103px;
    //text-indent: 103px;
    width: 100%;
    font-family: $font-family-bold;
  }

  .PlasmInput{
    &::after{
      display: block;
      content: 'PLASM';
      font-size: 15px;
      font-weight: 500;
      margin-right: 12px;
      margin-left: 12px;
      pointer-events: none;

    }
  }



  //&__content{
  //  display: flex;
  //  flex-direction: column;
  //  justify-content: space-between;
  //  padding-left: 60px;
  //  padding-right: 60px;
  //  padding-top: 40px;
  //
  //  @include breakpoint("tablet", max){
  //    flex-direction: column;
  //    align-items: center;
  //  }
  //}
}