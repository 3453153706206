.TPMCHomePage{
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__cta-cards{
    display: flex;
    flex-direction: row;
    margin-left: -22.5px;
    margin-right: -22.5px;

    @include breakpoint("tablet", max){
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__cta-card{
    flex: 1;
    margin-left: 22.5px;
    margin-right: 22.5px;
    cursor: pointer;

    @include breakpoint("tablet", max){
      margin-bottom: 12px;
      margin-left: 0;
      margin-right: 0;
    }

    img{
      width: 100%;
      border-radius: 20px;
      cursor: inherit;
    }
  }

  &__buy-on-title{
    margin-top: 80px;
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family-bold;
    letter-spacing: 2.1px;
    line-height: 17px;
    text-transform: uppercase;
    color: #72638C;
    margin-bottom: 30px;
  }

  &__buy-on-logos{
    display: flex;
    flex-direction: row;
    margin-left: -35px;
    margin-right: -35px;

    @include breakpoint("tablet", max){
      flex-direction: column;
    }
  }

  &__buy-on-logo{
    flex: 1;
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 30px;

    img{
      width: 100%;
      height: 48px;
    }
  }
}

.TPMCHeroBanners{
  width: 100%;
  margin-bottom: 70px;

  .carousel{
    padding-top: 24px;
    cursor: pointer;
  }

  .control-dots{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 !important;
    margin-top: 20px !important;
  }

  .carousel-slider{
    padding-bottom: 30px;
  }
}

.TPMCHeroBanner{
  width: 100%;
  cursor: pointer;

  img{
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
  }
}

.CarouselNavButton{
  width: 30px;
  height: 72px;
  display: block;
  position: absolute;
  top: calc(50% - 36px);
  z-index: 200;

  @include breakpoint("phone-wide", max) {
    display: none;
  }

  &--prev{
    left: 0;
  }

  &--next{
    left: auto;
    right: 0;
  }

  img{
    width: 100%;
    height: 100%;
  }
}

.CarouselIndicator{
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 8px;

  &__inner{
    width: 8px;
    height: 8px;
    background-color: #72638C;
    cursor: inherit;
    border-radius: 8px;
  }

  &--selected{
    .CarouselIndicator__inner{
      background-color: $color-green;
    }
  }
}