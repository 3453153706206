.EmptyState{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 502px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  &__title{
    color: #382F47;
    text-align: center;
    letter-spacing: 5.85px;
    color: #382F47;
    font-size: 39px;
    font-weight: bold;
    font-family: $font-family-bold;
    text-transform: uppercase;
    white-space: pre-line;

    @include breakpoint("700px", max){
      font-size: 22px;
      letter-spacing: 2.35px;

    }
  }

  &__subtitle{

  }

  .Button{
    margin-top: 30px;
  }
}