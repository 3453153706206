@keyframes idle {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-0.3rem); }
  100% {
    transform: translateY(0);
  }
}

@keyframes idleShadow {
  0% {
    opacity: 0.3;
     }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}


@keyframes rotate{
  to{ transform: rotate(360deg); }
}
