.InventoryItemCard{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 294px;
  border-radius: 14px;
  padding: 10px;
  padding-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;

  &__image{
    width: 176px;
    height: 176px;
    margin-bottom: 10px;
  }

  &__name{
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 18px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description{
    font-size: 12px;
    font-weight: 500;
    color: #72638C;
    line-height: 16px;
    //margin-bottom: 10px;
  }

  &__footer{
    margin-top: auto;

    .Button{
      width: 100%;
    }
  }

  .NumberBadge{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &--PowerUp{
    padding-top: 27px;

    .InventoryItemCard__image{
      width: 150px;
      height: 150px;
      margin-left:auto;
      margin-right: auto;
      margin-bottom: 26px;
    }
  }

  &--no-button{
    height: 264px;
  }
}