.NFTDrop{
  display: flex;
  flex-direction: row;

  @include breakpoint("tablet-small", max) {
    flex-direction: column;
  }

  .PlasmCTA{
    margin-top: 30px;
  }

  .RequiredApprovals{
    margin-top: 18px;
  }

  &__asset{
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    margin-right: 42px;
    cursor: pointer;

    img{
      width: 100%;
      height: 100%;
      cursor: inherit;
    }

    @include breakpoint("tablet-small", max) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;

      width: 100px;
      height: 100px;
    }

    &--expanded{
      position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(0,0,0,0.9);
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        width: 100%;
        object-fit: scale-down;
        height: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__info{
    display: flex;
    flex-direction: column;
    min-width: 270px;
    width: 100%;
    max-width: 300px;
  }

  &__nft-name {
    font-size: 16px;
    line-height: 19px;
    color: white;
    font-weight: 500;
    margin-bottom: 7px;
    text-align: left;

    @include breakpoint("tablet-small", max) {
      text-align: center;
    }
  }

  &__nft-description{
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #72638C;
    text-align: left;
    margin-bottom: 7px;

    @include breakpoint("tablet-small", max) {
      text-align: center;
    }
  }

  .NFTDropSection{
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .NFTDropSection__title{
    font-size: 10px;
    line-height: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #72638C;
    text-align: center;
    margin-bottom: 7px;

    @include breakpoint("phone-wide", max) {
      display: none;
    }
  }

  .NFTDropSection__rows{
    display: flex;
    flex-direction: column;
  }

  .NFTDropSectionRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #382F47;
    border-top: 0;
    border-bottom: 1px solid #382F47;
    height: 32px;

    &--no-top-border{
      border-top: none;
    }

    &__left{
      font-weight: 500;
      color: #72638C;
      font-size: 13px;
      line-height: 16px;
    }

    &__right{
      font-weight: 500;
      color: white;
      font-size: 13px;
      line-height: 16px;
    }
  }

  .NFTDropPurchaseTotal{
    display: flex;
    flex-direction: row;
    align-items: center;

    &.NFTDropPurchaseTotal--tfuel{
      color: $color-tfuel;
    }
    &.NFTDropPurchaseTotal--tdrop{
      color: $color-tdrop;
    }
    &.NFTDropPurchaseTotal--plasm{
      color: $color-plasm;
    }

    &__icon{
      margin-left: 9px;
      width: 20px;
      height: 20px;
    }

    &__amount{
      font-size: 25px;
      line-height: 32px;
      font-weight: 500;
      color: inherit;

      @include breakpoint('tablet', max) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .NFTDropPaymentOptions{
    display: flex;
    flex-direction: column;

    @include breakpoint('phone-wide', max) {
      flex-direction: row;
      margin-left: -6px;
      margin-right: -6px;

      .NFTDropPaymentOption{
        margin-right: 6px;
        margin-left: 6px;
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  .NFTDropPaymentOption{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #382F47;
    height: 37px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 12px;
    margin-bottom: 10px;
    cursor: pointer;

    &__token-icon{
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }

    *{
      cursor: pointer;
    }

    &__left{
      font-weight: 500;
      color: #72638C;
      font-size: 13px;
      line-height: 16px;
    }

    &__right{
      font-weight: 500;
      color: white;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }

    &--selected{
      &.NFTDropPaymentOption--tfuel{
        border-color: $color-tfuel;

        .NFTDropPaymentOption__left, .NFTDropPaymentOption__right{
          color: $color-tfuel;
        }
      }
      &.NFTDropPaymentOption--tdrop{
        border-color: $color-tdrop;

        .NFTDropPaymentOption__left, .NFTDropPaymentOption__right{
          color: $color-tdrop;
        }
      }
      &.NFTDropPaymentOption--plasm{
        border-color: $color-plasm;

        .NFTDropPaymentOption__left, .NFTDropPaymentOption__right{
          color: $color-plasm;
        }
      }
    }
  }

  .QtySelectorWrapper{
    @include breakpoint('phone-wide', max) {
      margin-top: 0px;
      margin-bottom: 4px;
    }
  }

  .QtySelector{
    @include breakpoint('phone-wide', max) {
      margin-top: 14px;
      margin-bottom: 7px;
    }
  }

  &__buy-button, &__sold-out-button{
    margin-top: 20px;
    width: 100%;

    @include breakpoint("phone-wide", max) {
      margin-top: 12px;
    }
  }
}