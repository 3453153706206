.LoginPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__logo{
    height: 148px;
    margin-top: 50px;
    margin-bottom: 30px;

    @include breakpoint("700px", max){
      height: 94px;
    }
  }

  &__show-other-wallets{
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
  }

  &__clear-cache{
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    background-color: $color-background;
    position: fixed;
    bottom: 10px;
  }

  &__other-wallets{
    margin-top: 50px;
    max-width: 330px;

    div{
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 13px;
    }

    p{
      font-size: 15px;
      font-weight: 500;
      text-align: center;
    }
  }

  .LoginButton{
    max-width: 330px;
  }

  &__members-only{
    font-size: 21px;
    line-height: 23px;
    color: $color-red;
    letter-spacing: 3px;
    font-family: $font-family-bold;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__description{
    font-size: 18px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 22px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 12px;

    @include breakpoint("700px", max){
      max-width: 224px;
    }
  }

  &__buy-link{
    font-size: 16px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: $color-orange;
    line-height: 23px;
    margin-top: 30px;
    cursor: pointer;
  }

  &__theta-pass{
    margin-top: 100px;
    height: 27px;
  }
}
