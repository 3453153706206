.PlasmBalances{
  display: flex;
  flex-direction: row;
  background-color: #2E273B;
  border-radius: 20px;
  height: 40px;
  cursor: pointer;

  &:hover{
    background-color: transparentize(#2E273B, 0.20);
  }

  &__logo{
    width: 28px;
    height: 28px;
    margin-left: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 16px;

    @include breakpoint("phone", max) {
      margin-right: 10px;
    }
  }

  &__columns{
    display: flex;
    flex-direction: row;
  }

  &__caret{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    border-left: 1px solid #3C334D;

    @include breakpoint("phone", max) {
      display: none;
    }

    img{
      width: 12px;
    }
  }

  *{
    cursor: inherit;
  }
}

.PlasmBalanceColumn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 24px;

  &:last-of-type{
    margin-right: 0;
  }

  &--staked{
    margin-right: 14px;
  }

  &__title{
    font-size: 11px;
    line-height: 14px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: $color-green;
    text-transform: uppercase;

    @include breakpoint("tablet", max){
      font-size: 9px;
    }
  }

  &__balance{
    font-size: 12px;
    line-height: 17px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: white;

    @include breakpoint("900px", max){
      font-size: 12px;
    }
  }

  &--pending{
    @include breakpoint("900px", max){
      display: none;
    }
  }

  &--on-hand{

  }

  &--staked{
    @include breakpoint("900px", max){
      display: none;
    }
  }
}