.RedeemingModal{
  max-height: 630px;

  .RoundedModalHeader--expanded{
    height: 90px;

    .RoundedModalHeader__title{
      margin-top: 20px;
    }
  }

  .RoundedModalHeader{
    position: relative;
    flex-direction: column;

    &__logo{
      height: 100%;
      position: absolute;
      z-index: -1;
    }

    .TextTabs{
      margin-top: auto !important;
    }

    .TextTabList{
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &--redeemed{

  }

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }

  .RequiredApprovals{
    margin-top: 20px;
  }
}

.RedeemedContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__result{
    height: 100%;
    width: 100%;

    img, video{
      height: 350px;
      width: 100%;
      object-fit: contain;
      margin-bottom: 15px;

      @include breakpoint("tablet", max) {
        height: 250px;
      }
    }
  }

  &__title{
    font-size: 30px;
    font-weight: bold;
    font-family: $font-family-bold;
    line-height: 38px;
    letter-spacing: 1.58px;
    color: white;
    margin-bottom: 9px;
    text-align: center;
    text-transform: uppercase;
  }

  &__description{
    font-size: 13px;
    font-weight: bold;
    font-family: $font-family-bold;
    line-height: 15px;
    color: $color-green;
    text-align: center;
    text-transform: uppercase;
  }

  &__done-button{
    margin-top: 20px;
    width: 132px;
  }
}

.RedeemingContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__back-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    font-family: $font-family-bold;
    color: #72638C;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
    height: 30px;

    *{
      cursor: inherit;
    }

    img{
      margin-right: 8px;
      width: 7px;
    }

    &:hover{
      opacity: 0.9;
    }
  }

  &__title{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: white;
    margin-bottom: 5px;
    text-align: center;
  }

  &__description{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #72638C;
    height: 42px;
    text-align: center;
    margin-bottom: 15px;
  }

  &__warning{
    font-size: 14px;
    font-weight: 500;
    color: $color-red;
  }

  &__inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include breakpoint("tablet", max) {
      overflow-x: scroll;
      justify-content: flex-start;
    }
  }

  &__inputs--1{
    justify-content: center !important;
  }

  &__redeem-button{
    width: 282px;
    margin-bottom: 24px;
  }
}

.RedemptionsContent{
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 24px;
}

.RedemptionCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 14px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #2E273B;
  width: calc(33.333% - 16px);
  margin-bottom: 16px;

  @include breakpoint("700px", max) {
    width: 100%;
  }

  &__info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__locked{
    font-size: 16px;
    font-weight: 500;
    color: white;
    line-height: 19px;
  }

  &__unlocks{
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #72638C;
  }

  &__button{
    margin-left: auto;
  }
}