// Vars
@import "vars";

body {
  margin: 0;
  font-family: $font-family-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-background;
  color: $color-text-grey;
}

html{
  background-color: $color-background;
}

.App {
}

#root{

}

a, button, .TextTabs {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  *{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}


// Utils
@import "utils/reset";
@import "utils/responsive";
@import "utils/animations";

// Components
@import "components/PageWrapper";
@import "components/Page";
@import "components/NavBar";
@import "components/Logo";
@import "components/Loader";
@import "components/Accordian";
@import "components/Button";
@import "components/TPMCWarning";
@import "components/Modal";
@import "components/TextTabs";
@import "components/NumberBadge";
@import "components/staking/PassawayWithMeta";
@import "components/staking/StakingTierBadge";
@import "components/staking/InventoryItemCard";
@import "components/staking/ProductCard";
@import "components/staking/PlasmBalances";
@import "components/staking/CraftableItemCard";
@import "components/staking/CraftableItemInputCard";
@import "components/staking/CraftableItemOrderCard";
@import "components/staking/LockedTokenCard";
@import "components/staking/RedeemableCard";
@import "components/staking/PlasmPrice";
@import "components/CTABanner";
@import "components/QtySelector";
@import "components/EmptyState";
@import "components/staking/NFTDrop";
@import "components/staking/RequiredApprovals";
@import "components/Tooltip";
@import "components/staking/LotteryLeaderboard";
@import "components/staking/PlasmCTA";
@import "components/CTAPillLink";

// Pages
@import "pages/Landing";
@import "pages/tpmc/Login";
@import "pages/tpmc/AccessDenied";
@import "pages/tpmc/Collection";
@import "pages/tpmc/Nft";
@import "pages/tpmc/Airdrop999ers";
@import "pages/tpmc/Discord";
@import "pages/tpmc/ThetaConBadgeSwap";
@import "pages/events/FloorRaiser";
@import "pages/events/DreadfulValentine";
@import "pages/events/CreateDreadfulValentine";
@import "pages/events/OperationManhunt";
@import "pages/tpmc/NFTStaking";
@import "pages/tpmc/PlasmStaking";
@import "pages/tpmc/Home";
@import "pages/tpmc/Approvals";
@import "pages/tpmc/NFTDrop";
@import "pages/tpmc/Lottery";
@import "pages/tpmc/ProofOfTransphorm";
@import "pages/tpmc/VIP";

// Modals
@import "modals/Loader";
@import "modals/tpmc/Welcome";
@import "modals/tpmc/Shop";
@import "modals/tpmc/ProveOwnership";
@import "modals/staking/PassawaySelector";
@import "modals/staking/UseInventory";
@import "modals/staking/SupplyStore";
@import "modals/staking/Plasm";
@import "modals/staking/Product";
@import "modals/staking/Alert";
@import "modals/staking/Crafting";
@import "modals/staking/Redeeming";
@import "modals/staking/Transphorm";
@import "modals/staking/Marketplaces";
@import "modals/staking/Inventory";
@import "modals/tpmc/MobileWallets";
@import "modals/staking/UnstakePlasm";
@import "modals/staking/GetPlasm";
@import "modals/staking/VIPDeposit";
