.DiscordPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #1C1824;

  &__logo{
    width: 540px;
    margin-top: 30px;
    margin-bottom: 60px;

    @include breakpoint("700px", max){
      width: 300px;
      margin-bottom: 30px;
    }
  }

  &__perks{
    font-size: 16px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 19px;
    text-align: center;
    max-width: 520px;
    margin-bottom: 45px;
    width: 100%;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
    }

    a.Link{
      color: $color-orange;
    }
  }

  &__perks-title{
    font-size: 18px;
    line-height: 17px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: $color-text-grey;
    margin-bottom: 10px;
  }

  .CodeInput{
    display: flex;
    align-items: center;
    background-color: #100E14;
    border-radius: 6px;
    height: 40px;
    border: 1px solid #382F47;
    position: relative;
    transition: all 0.2s ease-in-out;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    &--focused{
      border-color: $color-green;
    }

    input::placeholder {
      opacity: 0.75;
      color: #72638C;
    }

    input{
      background-color: transparent;
      height: 100%;
      border: none;
      outline: none;
      color: white;
      margin-left: 2px;
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      text-align: center;
    }

    // Hide the arrows on number inputs
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
