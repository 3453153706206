.FloorRaiserEventPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__title{
    font-size: 58px;
    font-family: $font-family-bold;
font-weight: bold;
    color: $color-green;
    line-height: 69px;

    @include breakpoint("700px", max){
      font-size: 40px;
      line-height: 50px;
    }
  }

  &__passaway{
    width: 310px;
    margin-bottom: 26px;
    margin-top: 15px;
  }

  &__badges{
    width: 650px;
    margin-bottom: 47px;

    @include breakpoint("700px", max){
      width: 100%;
    }
  }

  &__welcome{
    font-size: 20px;
    color: white;
    line-height: 24px;
    font-weight: 500;
    text-align: center;

    @include breakpoint("700px", max){
      font-size: 18px;
    }
  }

  &__welcome-subtitle{
    font-size: 16px;
    color: #75808C;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  &__section{
    font-size: 20px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 24px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 45px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
    }
  }

  &__section-title{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: white;
  }
}

.LeaderboardTabContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 100%;
}

.ParticipantsCounter{
  font-size: 14px;
  letter-spacing: 2.1px;
  font-weight: 500;
  color: $color-text-grey;
  text-transform: uppercase;
  font-family: $font-family-bold;
  font-weight: bold;

  &__count{
    color: white;
    margin-right: 6px;
  }
}

.PrizeCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #202326;
  padding: 15px;
  max-width: 440px;
  margin-top: 17px;
  margin-bottom: 20px;

  img{
    margin-right: 20px;
    width: 61px;
  }

  div{
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 17px;
  }
}

.LeaderboardTable{
  width: calc(100vw - 12px);
  max-width: 700px;
  margin-top: 20px;
  max-width: 462px;

  @include breakpoint("700px", max){
    max-width: 270px;
  }


  th{
    font-size: 14px;
    line-height: 24px;
    color: #75808C;
    text-align: left;
    padding-bottom: 8px;
    font-weight: 500;
    font-weight: 600;
  }

  td{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #54476B;
    vertical-align: middle;
  }

  .PlacePrize{
    display: flex;
    align-items: center;
  }

  .Place{
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    font-family: $font-family-bold;
    color: #75808C;
    width: 36px;
    padding-left: 6px;
  }

  .Prize{
    display: flex;
    flex-direction: row;
  }

  .PrizeInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;

    @include breakpoint("700px", max){
      font-size: 12px;
    }
  }

  .MainPrizeName{
    color: white;
  }

  .SubPrizeName{
    color: #75808C;
  }

  .PrizeImage{
    width: 52px;
    margin-right: 16px;
    align-self: center;

    @include breakpoint("700px", max){
      width: 34px;
      margin-right: 12px;
    }
  }

  .Participant{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #72638C;

    @include breakpoint("700px", max){
      font-size: 10px;
      max-width: 100px;
    }

    span{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    img{
      width: 28px;
      height: 28px;
      border-radius: 14px;
      margin-right: 10px;

      @include breakpoint("700px", max){
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }

  .Points{
    font-size: 14px;
    line-height: 17px;
    color: #72638C;
    text-align: right;
    padding-right: 12px;
    font-weight: bold;
    font-family: $font-family-bold;

    @include breakpoint("700px", max){
      font-size: 14px;
    }
  }
}
