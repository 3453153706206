.LoaderModal{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1824;
  border-radius: 6px;
  border: 1px solid #382F47;
  max-width: 980px;
  width: 120px;
  height: 120px;

  &__footer{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 120px;
    width: 320px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title{
    font-size: 13px;
    font-family: $font-family-bold;
    color: $color-green;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 22px;
    text-align: center;
  }

  &__subtitle{
    font-size: 15px;
    font-weight: 500;
    color: #72638C;
    line-height: 18px;
    margin-top: 8px;
    text-align: center;
    white-space: pre;
  }

  &__cancel{
    margin-top: 20px;
  }
}