.LotteryLeaderboard {
  color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  background-color: #1C1824;
  max-width: 505px;

  &__title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: $font-family-bold;
    color: #72638C;
    letter-spacing: 2.4px;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.LotteryLeaderboardItem {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #382F47;
  transition: background-color 0.3s;

  &:hover {
    background-color: lighten(#1C1824, 5%);
  }

  &--top-three {

  }

  &:last-child {
    border-bottom: none;
  }

  &__avatar,
  &__address,
  &__points {
    margin-right: 10px;
  }

  &__rank {
    width: 2em;
    text-align: left;
    font-size: 14px;
    font-family: $font-family-bold;
    color: $color-green;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-left: 5px;

    div{
      height: inherit;
      width: inherit;
    }
  }

  &__address {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    color: #72638C;
  }

  &__points {
    min-width: 80px; // Adjust as needed for the width of the points area
    text-align: right;
    font-size: 14px;
    font-family: $font-family-bold;
    text-transform: uppercase;
    color: #72638C;
  }
}
