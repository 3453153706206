.CTAPillLink{
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
  cursor: pointer;
  background: transparentize($color-green, 0.85);
  color: #4C9B53;

  @include breakpoint("mobile", max) {
    font-size: 13px;
  }

  *{
    cursor: inherit;
  }
}