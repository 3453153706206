.UseInventoryModal{

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }
}