.MobileWalletsModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;

  @include breakpoint("500px", max){
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }

  &__title{
    font-size: 46px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: white;
    line-height: 46px;
    text-transform: uppercase;
    letter-spacing: 2.3px;
    text-align: center;
    margin-bottom: 20px;

    @include breakpoint("500px", max){
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__subtitle{
    font-size: 18px;
    line-height: 24px;
    color: $color-text-grey;
    font-weight: 500;
    margin-bottom: 60px;

    @include breakpoint("500px", max){
      margin-bottom: 30px;
    }
  }

  &__apps{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  &__app{
    display: flex;
    flex-direction: column;

    img{
      border-radius: 9px;
      width: 60px;
      height: 60px;
    }

    span{
      font-size: 12px;
      color: white;
      margin-top: 6px;
    }
  }

  &__cancel{
    font-size: 16px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: #75808C;
    line-height: 23px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 12px;
  }
}
