.NFTStakingPage{
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DataSectionHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;

  &__title{
    font-size: 19px;
    font-family: $font-family-bold;
    font-weight: bold;
    letter-spacing: 2.85px;
    color: #72638C;
    text-transform: uppercase;

    @include breakpoint("tablet", max){
      font-size: 16px;
    }
  }

  &__actions{
    margin-left: auto;
  }
}

.StakePlots{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-top: 20px;
}

.StakePlot{
  max-width: 217px;
  border: 3px dashed #382F47;
  padding: 12px;
  border-radius: 12px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 16px;
  position: relative;

  &__rate{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #76FF80;
    font-weight: 500;
    font-size: 12px;
    text-align: center;

    img{
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  &__editing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 16px;
    font-family: $font-family-bold;
font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 19px;
    text-transform: uppercase;
    color: $color-red;
    text-align: center;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
    width: calc(100% + 6px);
    right: 0;
    bottom: 0;
    left: -3px;
    top: -3px;
    height: calc(100% + 6px);

    img{
      width: 34px;
      height: 34px;
      margin-bottom: 12px;
    }

    div{
      width: 76px;
    }

    img{
      transition: all 0.2s ease-in-out;
    }

    &:hover{
      cursor: pointer;

      img{
        transform: scale(1.05);
      }
    }

    &:active{
      img{
        transform: scale(0.95);
      }
    }

    *{
      cursor: inherit;
    }
  }
}

.StakePlotPowerUps{
  background-color: #2E273B;
  height: 77px;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;

  &__title{
    font-size: 11px;
    font-family: $font-family-bold;
font-weight: bold;
    line-height: 14px;
    color: #72638C;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
  }

  &__slots{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.PowerUpSlot{
  height: 33px;
  width: 33px;
  position: relative;

  .CircularProgressbar{
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    height: 36px;
    width: 36px;
    z-index: 0;
  }

  &--perma-active{
    height: 40px;
    width: 40px;
    margin-top: -3.5px;

    img{
      height: 100%;
      width: 100%;
    }
  }

  img{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  *{
    cursor: pointer;
  }
}

.ActivePowerUp{
  height: 33px;
  width: 33px;
  position: relative;

  .CircularProgressbar{
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    height: 36px;
    width: 36px;
    z-index: 0;
  }

  img{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  *{
    cursor: pointer;
  }

  &--Permanent{
    height: 40px;
    width: 40px;

    img{
      height: 100%;
      width: 100%;
    }
  }

  &--large{
    height: 150px;
    width: 150px;

    .CircularProgressbar{
      top: -5px;
      left: -5px;
      height: 160px;
      width: 160px;
    }
  }
}

