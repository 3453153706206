.CreateDreadfulValentineEventPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;


  &__input{
    width: 100%;
    max-width: 350px;
    height: 90px;
    background-color: #202326;
    color: white;
    border: none;
    font-size: 16px;
  }

  textarea::-webkit-input-placeholder {
    color: rgba(255,255,255,0.7);
  }

  textarea:-moz-placeholder { /* Firefox 18- */
    color: rgba(255,255,255,0.7);
  }

  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: rgba(255,255,255,0.7);
  }

  textarea:-ms-input-placeholder {
    color: rgba(255,255,255,0.7);
  }

  textarea::placeholder {
    color: rgba(255,255,255,0.7);
  }
}

.DreadfulValentineCard{
  position: relative;
  width: 786px;
  height: 1048px;
  opacity: 0;

  &__text-wrapper{
    position: absolute;
    top: 220px;
    left: 60px;
    right: 60px;
    height: 250px;
    width: 666px;
  }

  &__text{
    font-size: 96px;
    font-weight: 800;
    //background: url(../assets/events/dreadful-valentine/gold-mask.png) 0 0 / cover no-repeat;
    //background-clip: text;
    //-webkit-background-clip: text;
    //color: transparent;
    color: #F5EB8D;
    height: 250px;
    word-break: break-word;
    hyphens: auto;
    text-align: center;
    width: 666px;
    font-family: 'RoasterOriginal-Regular';
    text-transform: lowercase;
    //white-space: pre-wrap;
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: 3px;
  }
}
