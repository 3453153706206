.CraftingModal{
  max-height: 630px;

  .RoundedModalHeader--expanded{
    height: 90px;

    .RoundedModalHeader__title{
      margin-top: 20px;
    }
  }

  &--expanded{

    .RoundedModal__content{
      height: calc(100% - 90px);
    }
  }


  .RoundedModalHeader{
    position: relative;
    flex-direction: column;

    &__logo{
      height: 100%;
      position: absolute;
      z-index: -1;
    }

    .TextTabs{
      margin-top: auto !important;
    }

    .TextTabList{
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &--crafted{
    // Darken a bit because Mike can't export a file properly
    background-color: #191521;
  }

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }

  .EmptyState{
    height: calc(100% - 90px);
  }

  .RoundedModalHeader{
    position: relative;
    flex-direction: column;
  }
}

.CraftedContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__result{
    height: 100%;
    width: 100%;

    img, video{
      height: 350px;
      width: 100%;
      object-fit: contain;
      margin-bottom: 15px;

      @include breakpoint("tablet", max) {
        height: 250px;
      }
    }
  }

  &__title{
    font-size: 30px;
    font-weight: bold;
    font-family: $font-family-bold;
    line-height: 38px;
    letter-spacing: 1.58px;
    color: white;
    margin-bottom: 9px;
    text-align: center;
    text-transform: uppercase;
  }

  &__description{
    font-size: 13px;
    font-weight: bold;
    font-family: $font-family-bold;
    line-height: 15px;
    color: $color-green;
    text-align: center;
    text-transform: uppercase;
  }

  &__done-button{
    margin-top: 20px;
    width: 132px;
  }
}

.CraftingContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__back-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    font-family: $font-family-bold;
    color: #72638C;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
    height: 30px;

    *{
      cursor: inherit;
    }

    img{
      margin-right: 8px;
      width: 7px;
    }

    &:hover{
      opacity: 0.9;
    }
  }

  &__title{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: white;
    margin-bottom: 5px;
    text-align: center;
  }

  &__description{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #72638C;
    height: 42px;
    text-align: center;
    margin-bottom: 15px;
  }

  &__inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;

    @include breakpoint("tablet", max) {
      overflow-x: scroll;
      justify-content: flex-start;
    }
  }

  &__craft-button{
    width: 282px;
    margin-bottom: 18px;
  }

  &__price-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__price-prefix{
    font-family: $font-family-bold;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    color: #72638C;
    margin-right: 8px;
  }

  &__price{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #76FF80;
    line-height: 17px;
    margin-bottom: auto;
    font-family: $font-family-bold;

    &::before{
      content: "";
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=');
      background-size: 18px;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  }

  .RequiredApprovals{
    margin-top: 20px;
  }
}


.CraftableItemOrdersContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__title{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: white;
    margin-bottom: 5px;
    text-align: center;
  }

  &__description{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #72638C;
    height: 42px;
    text-align: center;
    margin-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__orders{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 22px;

    @include breakpoint("tablet", max) {
      margin-top: -20px;
    }
  }

  &__button{
    margin-bottom: 40px;
  }
}

.LockedTokensContent{
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;

  &__title{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: white;
    margin-bottom: 5px;
    text-align: center;
  }

  &__description{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #72638C;
    height: 42px;
    text-align: center;
    margin-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &__locked-tokens{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 22px;

    @include breakpoint("tablet", max) {
      margin-top: -20px;
    }
  }

  &__button{
    margin-bottom: 40px;
  }
}