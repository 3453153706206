.PassawayWithMeta{
  position: relative;
  width: 187px;
  height: 187px;

  &__image{
    width: 100%;
    height: 100%;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  &__edition{
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 10px;
    background-color: transparentize(#100E14, 0.4);
    border-radius: 2px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    padding-left: 4px;
    padding-right: 4px;
    height: 14px;

  }

  .StakingTierBadge{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
  }

  &__999-badge{
    //position: absolute;
    //z-index: 1;
    //left: 8px;
    //bottom: 8px;
    width: 24px;
    height: 24px;
  }

  &__transphorm-badge{
    //position: absolute;
    //z-index: 1;
    //left: 8px;
    //bottom: 8px;
    width: 24px;
    height: 24px;
  }

  &__icons{
    height: 80px;
    width: 24px;
    position: absolute;
    left: 8px;
    bottom: 8px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;

    img:last-child{
      margin-top: 6px;
    }
  }

}

