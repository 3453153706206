.PassawaySelectorModal{

  &__passaways{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }

  &__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px; // Cards have 30px already
    padding-bottom: 10px;
    //border-bottom: 1px solid #382F47;
  }

  &__footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px; // Cards have 30px already
    padding-bottom: 30px;
    border-top: 1px solid #382F47;
  }

  &__disclaimer{
    font-size: 12px;
    color: #72638C;
    text-align: center;
    margin-top: 20px;
  }

  .EmptyState{
    height: calc(100% - 68px);
  }
}

.PassawayCard{
  background-color: #2E273B;
  border-radius: 14px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 30px;

  &__footer{
    padding: 10px;
  }

  &__transphormation-in{
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #72638C;
    text-align: center;
    margin-bottom: 5px;
  }

  .Button{
    width: 100%;
  }
}