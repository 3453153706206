.OperationManhuntEventPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__title{
    font-size: 58px;
    font-family: $font-family-bold;
font-weight: bold;
    color: $color-red;
    line-height: 69px;
    text-align: center;

    @include breakpoint("700px", max){
      font-size: 40px;
      line-height: 50px;
    }
  }

  &__passaway{
    width: 550px;
    margin-bottom: 26px;
    margin-top: 15px;

    @include breakpoint("phablet", max){
      width: 100%;
    }
  }

  &__badges{
    width: 300px;
    margin-bottom: 47px;
  }

  &__welcome{
    font-size: 20px;
    color: white;
    line-height: 24px;
    font-weight: 500;
    text-align: center;

    @include breakpoint("700px", max){
      font-size: 18px;
    }
  }

  &__welcome-subtitle{
    font-size: 16px;
    color: #75808C;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  &__section{
    font-size: 20px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 24px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 45px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
    }
  }

  &__section-title{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
    font-family: $font-family-bold;
font-weight: bold;
    color: white;
  }
}
