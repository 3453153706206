body.ReactModal__Body--open {
  overflow: hidden;
}

.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  cursor: auto;
  z-index: 1000000;

  &:focus {
    outline: none;
  }
}

.ModalOverlay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparentize($color-background, 0.15);
  cursor: pointer;
  z-index: 1000000;
}

.RoundedModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1824;
  border-radius: 20px;
  border: 1px solid #382F47;
  max-width: 980px;
  width: 100%;
  height: 100vh;
  max-height: 600px;

  &__content{
    width: 100%;
    height: calc(100% - 72px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.RoundedModalHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 72px;
  border-bottom: 1px solid #382F47;
  position: relative;

  &__title{
    font-size: 19px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: #72638C;
    letter-spacing: 2.85px;
    text-transform: uppercase;
    line-height: 25px;

    @include breakpoint("tablet", max){
      font-size: 15px;
    }
  }

  &__subtitle{
    font-size: 12px;
    font-weight: 500;
    color: #72638C;
    line-height: 16px;
    margin-top: 0;
  }

  &__available-in, &__available-for{
    font-size: 12px;
    font-weight: 500;
    color: #76FF80;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: -10px;
  }

  &__available-in{
    color: $color-green;
  }

  &__available-for{
    color: $color-red;
  }

  &__close-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img{
      width: 15.5px;
      height: 15.5px;
      cursor: pointer;
    }
  }
}