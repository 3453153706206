.StakingTierBadge{
  display: flex;
  align-items: center;
  background-color: #72638C;
  border-radius: 2px;
  color: #100E14;
  font-family: $font-family-bold;
  font-weight: bold;
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
  text-transform: uppercase;
  height: 14px;

  &--base{
    background-color: #8977A8;
  }

  &--common{
    background-color: #76FF80;
  }

  &--uncommon{
    background-color: #F5D638;
  }

  &--rare{
    background-color: #BD08FF;
  }

  &--epic{
    background-color: #1CA8FF;
  }

  &--legendary{
    background-color: #CF8200;
  }

  &--custom{
    background-color: #FF2965;
  }
}