.TextTabs{
  &--no-underline{
    .TextTabList{
      .react-tabs__tab{
        &::after{
          content: none;
        }
      }
    }
  }
}

.TextTabList{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;
  padding-inline-start: 0;

  .react-tabs__tab{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
    color: #72638C;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;

    @include breakpoint("700px", max){
      margin-right: 10px;
      margin-left: 10px;
    }

    *{
      cursor: pointer;
    }

    span{
      font-size: 13px;
      font-family: $font-family-bold;
      font-weight: bold;
      line-height: 18px;
      //letter-spacing: 1.5px;
      text-transform: uppercase;
      text-align: center;
      color: inherit;

      @include breakpoint("700px", max){
        font-size: 12px;
      }
    }

    &--selected{
      color: $color-green;

      &::after{
        content: "";
        height: 2px;
        width: 17px;
        background-color: $color-green;
        bottom: -2px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
