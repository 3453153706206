.QtySelectorWrapper{
  margin-top: 20px;
  margin-bottom: 20px;
}

.QtySelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex: 1;

}

.QtySelectorAmount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #FFFFFF;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 17.5px;
  height: 35px;
  border: 1px solid #382F47;
  flex: 1;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.QtySelectorButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #382F47;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    transition: background-color ease-in-out 0.2s;
  }

  &:hover {
    border: 1px solid $color-green;

    .QtySelectorButton__icon {
      color: $color-green;
    }
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;

    .QtySelectorButton__icon {
      color: #382F47;
    }
  }
}

.QtySelectorAmountShortcuts{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
}

.QtySelectorAmountShortcut{
  width: 56px;
  height: 30px;
  border: 1px solid #382F47;
  font-size: 16px;
  font-weight: 500;
  color: #72638C;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover{
      background-color: #382F47;
      color: #FFFFFF;
  }

  &--active{
    color: #FFFFFF;
  }
}