.ThetaConBadgeSwapPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__logo{
    height: 300px;
    margin-top: 0;

    @include breakpoint("700px", max){
      height: 200px;
    }
  }

  &__description{
    font-size: 16px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 22px;
    text-align: center;
    max-width: 520px;
    margin-top: 20px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
      margin-bottom: 20px;
    }
  }

  &__all-set{
    font-size: 18px;
    font-weight: 500;
    color: $color-green;
    line-height: 22px;
    text-align: center;
    max-width: 350px;
    margin-top: 30px;
    margin-bottom: 20px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
      margin-bottom: 40px;
    }
  }

  &__token-id-input{
    background-color: lighten($color-background, 10);
    border-radius: 0px;
    border: 0;
    outline: none;
    color: white;
    font-size: 18px;
    text-align: center;
    height: 44px;
    margin-top: 12px;
  }
}
