$color-background: #100E14; //#16171A;
$color-blue: #1FB6FF;

$color-text-grey: #72638C;

$color-orange: #FF5436;
$color-green: #76FF80;
$color-red: #FF2965;

$color-tfuel: #FF9F13;
$color-tdrop: #00D5F9;
$color-plasm: $color-green;

$font-family-normal: "futura-pt", -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Verdana', 'Arial',
sans-serif;

// Stupid Adobe put this as a separate font.  Fucking idiots.
$font-family-bold: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Verdana', 'Arial',
sans-serif;

//@font-face {
//  font-family: 'RoasterOriginal-Regular';
//  src: url('../fonts/roaster-original.eot');
//  src: url('../fonts/roaster-original.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/roaster-original.woff2') format('woff2'),
//  url('../fonts/roaster-original.woff') format('woff'),
//  url('../fonts/roaster-original.ttf') format('truetype'),
//  url('../fonts/roaster-original.svg#youworkforthem') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

.color--red{
  color: $color-red;
}

.color--green{
  color: $color-green;
}

.color--white{
  color: white;
}

body{
  font-family: $font-family-normal;
}

@keyframes pulseSmall {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulseSmall {
  animation-name: pulseSmall;
  animation-timing-function: ease-in-out;
}