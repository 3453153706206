.WelcomeModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;

  @include breakpoint("500px", max){
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }


  &__logo{
    height: 120px;
    margin-bottom: 50px;

    @include breakpoint("500px", max){
      height: 90px;
    }

    img{
      height: 100%;
    }
  }

  &__welcome{
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: $color-green;
    font-family: $font-family-bold;
font-weight: bold;
    margin-bottom: 6px;
    text-transform: uppercase;

  }

  &__misadventure-title{
    font-size: 46px;
    font-family: $font-family-bold;
font-weight: bold;
    color: white;
    line-height: 46px;
    text-transform: uppercase;
    letter-spacing: 2.3px;
    text-align: center;
    margin-bottom: 30px;

    @include breakpoint("500px", max){
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__message{
    font-size: 18px;
    line-height: 24px;
    color: $color-text-grey;
    font-weight: 500;
    margin-bottom: 60px;

    @include breakpoint("500px", max){
      margin-bottom: 30px;
    }
  }

  &__continue{
    font-size: 16px;
    font-family: $font-family-bold;
    font-weight: bold;
    color: $color-orange;
    line-height: 23px;
    cursor: pointer;
    text-transform: uppercase;
  }
}
