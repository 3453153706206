.NumberBadge{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #FF2965;
  color: white;
  border-radius: 10px;
  min-width: 34px;
  height: 20px;
  font-weight: 500;
}

.BetaBadge{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #5d5177;
  color: #2d2739;
  border-radius: 10px;
  min-width: 34px;
  height: 20px;
  font-weight: 600;
}