.PlasmStakingPage{
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title{
    font-family: $font-family-bold;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    color: $color-green;
    opacity: 1;
    margin-bottom: 10px;

    @include breakpoint("tablet", max) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  .CTAPillLink{
    margin-bottom: 30px;
  }

  &__description{
    font-size: 18px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 22px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 12px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
    }
  }

  .LoginButton{
    max-width: 330px;
  }

  .PlasmActionContainer{
    flex: unset;
    margin-bottom: 0 !important;
  }

  &__arrow{
    margin-top: 30px;
    margin-bottom: 30px;
    height: 26px;
  }

  &__unstake-button{
    color: $color-text-grey;
    font-size: 12px;
    text-decoration: underline;
    margin-top: -22px;
    cursor: pointer;

    &:hover{
      color: darken($color-text-grey, 8);
    }
  }

  &__7734-guardian-program{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: $color-text-grey;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-transform: uppercase;

    @include breakpoint("tablet", max) {
      margin-top: 40px;
    }

    img{
      height: 150px;
      margin-bottom: 15px;

      @include breakpoint("tablet", max) {
        height: 120px;
      }
    }

    .Button{
      margin-top: 0px;
    }
  }
}