.PlasmCTA{
  display: flex;
  flex-direction: column;

  &__top-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: $color-green;
    line-height: 17px;
    justify-content: center;

    img{
      width: 19px;
      height: 19px;
      display: inline-block;
      margin-left: 8px;
      margin-right: 4px;
    }
  }

  .Button{

  }
}