.ProductModal{
  max-width: 600px;
  height: unset;
  max-height: unset;
  padding-bottom: 35px;

  &__content{
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    padding-left: 40px;

    @include breakpoint("tablet", max){
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image{
    height: 150px;
    width: 150px;
    margin-right: 48px;

    @include breakpoint("tablet", max){
      margin-right: 0;
      margin-bottom: 20px;
      height: 120px;
      width: 120px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 264px;
    text-align: left;

    @include breakpoint("tablet", max) {
      width: 100%;
    }
  }

  &__name{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    color: white;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-bottom: 8px;

    .BetaBadge{
      width: 40px;
      margin-left: 6px;
    }
  }

  &__description{
    font-weight: 500;
    color: #72638C;
    font-size: 14px;
    line-height: 17px;
    min-height: 43px;
    margin-bottom: 12px;
  }

  &__line-items{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ProductModalLineItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #382F47;
    height: 32px;

    &__left{
      font-weight: 500;
      color: #72638C;
      font-size: 13px;
      line-height: 16px;
    }

    &__right{
      font-weight: 500;
      color: white;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__footer{
    margin-top: 30px;
  }

  &__buy-button{
    width: 100%;

    @include breakpoint("tablet", max) {
      width: 100%;
    }
  }

  &__sold-out-button{
    pointer-events: none;
    margin-top: 15px;
  }
}