.VIPPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

&__disclaimers{
  white-space: pre-line;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #72638C;
    margin-top: 18px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

  &__title {
    font-size: 19px;
    line-height: 22px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2.85px;
    color: #72638C;
    margin-bottom: 12px;

    @include breakpoint('phone-wide', max) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    text-align: center;
    color: #72638C;
    max-width: 354px;
    margin-bottom: 30px;

    @include breakpoint('phone-wide', max) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .VIPTierColumns {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
    margin-right: -5px;
  }

  .VIPTierColumn {
    background: #17151D;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    max-width: 254px;

    @include breakpoint('tablet', max) {
      max-width: 400px;
      padding-top: 30px;
    }


    &__icon {
      width: 140px;
      height: 140px;
      margin-bottom: 10px;

      @include breakpoint('tablet', max) {
        display: none;
      }
    }

    &__button {
      height: 26px;
      width: 100%;
      max-width: 164px;
      color: #100E14;
      font-size: 12px;
      letter-spacing: 0.6px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 29px;
    }

    &__required-title {
      font-size: 13px;
      font-weight: bold;
      color: #72638C;
      text-transform: uppercase;
      line-height: 16px;
      margin-bottom: 10px;
    }

    &__required-amount {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 26px;
      color: white;

      &::before {
        content: "";
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=');
        background-size: 18px;
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }

    &__divider {
      height: 1px;
      background: #382F47;
      width: 100%;
      max-width: 214px;
    }

    &--bronze {
      .VIPTierColumn__button {
        background: transparent linear-gradient(0deg, #733A2B 0%, #814635 17%, #A86652 50%, #E79A7F 94%, #F0A286 100%) 0% 0% no-repeat padding-box;
      }
    }

    &--silver {
      .VIPTierColumn__button {
        background: transparent linear-gradient(0deg, #415F73 0%, #4E6C81 17%, #728FA8 50%, #ABC7E7 94%, #B4D0F0 100%) 0% 0% no-repeat padding-box;
      }
    }

    &--gold {
      .VIPTierColumn__button {
        background: transparent linear-gradient(180deg, #F0BA17 0%, #B28612 47%, #8B6510 82%, #7D590F 100%) 0% 0% no-repeat padding-box;
      }
    }

    &--essu {
      .VIPTierColumn__button {
        background: transparent linear-gradient(180deg, #F400EF 0%, #6B0A91 100%) 0% 0% no-repeat padding-box;
      }
    }
  }

  .VIPTierBenefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #382F47;

    &:last-of-type {
      border-bottom: none;
    }

    &__coming-soon {
      font-size: 11px;
      font-weight: bold;
      color: $color-green;
      text-transform: uppercase;
      height: 11px;
    }

    &__icon {
      height: 30px;
      width: 30px;
      margin-bottom: 24px;
    }

    &__title {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.65px;
      color: white;
      line-height: 16px;
      margin-bottom: 5px;
      text-transform: uppercase;
      text-align: center;
    }

    &__description {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #72638C;
      text-align: center;
      height: 60px;
    }
  }
}

.VIPTabs {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 74px;
  background: #100E14;
  margin-bottom: 10px;
  z-index: 2;
  width: 100vw;
  justify-content: center;
}

.VIPTab {
  font-size: 14px;
  font-weight: bold;
  color: #72638C;
  line-height: 17px;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  background: #100E14;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  *{
    cursor: pointer;
  }

  img {
    height: 70px;
    width: 70px;
    margin-bottom: 7px;
    opacity: 0.35;

    @include breakpoint('phone-wide', max) {
      height: 60px;
      width: 60px;
    }
  }

  &__name {
    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 15px;
    letter-spacing: 0.6px;
    opacity: 0.35;
    text-transform: uppercase;
  }

  &.active {
    color: white;
    border-bottom: 2px solid #18C99D;
  }

  &--selected {
    img {
      opacity: 1.0;
    }

    .VIPTab__name {
      opacity: 1.0;
    }
  }
}

.VIPUserTier{
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;

  &__title{
    font-size: 13px;
    font-weight: bold;
    color: #72638C;
    line-height: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
  }

  &__divider{
    height: 29px;
    width: 1px;
    background: #382F47;
    margin-left: 15px;
    margin-right: 15px;
  }

  &__word-and-amount{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 13px;
    align-items: center;
  }

  &__word{
    height: 13px;
  }

  &__amount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: white;

    &::before {
      content: "";
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=');
      background-size: 18px;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
}

.VIPWithdrawals{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__title{
    font-size: 13px;
    font-weight: bold;
    color: #72638C;
    line-height: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
  }

  &__amount{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 8px;
    color: white;

    &::before {
      content: "";
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=');
      background-size: 18px;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }

  &__available-in{
    font-size: 15px;
    color: #72638C;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 10px;
    text-align: center;
  }

  .Button{
    margin-top: 6px;
    margin-bottom: 10px;
  }
}




















