.LandingPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;

  .PassawaysSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__hero-image{
      width: 100%;
      max-width: 1015px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 22px;
    }

    &__title{
      font-family: $font-family-bold;
      font-weight: bold;
      font-size: 100px;
      line-height: 100px;
      text-align: center;
      letter-spacing: 0;
      text-transform: uppercase;
      color: $color-green;
      opacity: 1;
      margin-bottom: 30px;

      @include breakpoint("tablet", max){
        font-size: 38px;
        line-height: 38px;
      }
    }

    .TitleSeparatorImage{
      margin-bottom: 25px;
    }

    &__description{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      max-width: 690px;
      width: 100%;
      text-align: center;
      margin-bottom: 70px;

      @include breakpoint("tablet", max){
        font-size: 14px;
        line-height: 17px;
      }
    }

    .FeatureCards{
      margin-bottom: 10px;
    }

    &__stake-button{
      margin-bottom: 15px;
    }

    .AndEarnPLASMTokens{
      margin-bottom: 60px;
    }
  }

  .PlasmSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__hero-image {
      width: 100%;
      max-width: 1015px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 22px;
    }

    &__title{
      font-family: $font-family-bold;
      font-weight: bold;
      font-size: 100px;
      line-height: 100px;
      text-align: center;
      letter-spacing: 0;
      text-transform: uppercase;
      color: $color-green;
      opacity: 1;
      margin-bottom: 10px;

      @include breakpoint("tablet", max) {
        font-size: 42px;
        line-height: 42px;
      }
    }

    .CTAPillLink{
      margin-bottom: 30px;
    }

    &__description{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      max-width: 530px;
      width: 100%;
      text-align: center;
      margin-bottom: 70px;

      @include breakpoint("tablet", max){
        font-size: 14px;
        line-height: 17px;
      }
    }

    &__darkpaper-and-audit-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &__darkpaper-button{
      margin-bottom: 40px;

      @include breakpoint("tablet", max) {
        padding-left: 26px;
        padding-right: 26px;
        font-size: 16px;
      }
    }

    .PlasmAudit{
      margin-bottom: 16px;
    }

    &__read-audit-button{
      cursor: pointer;
      text-transform: uppercase;
      color: #00BFFF;
      font-size: 13px;
      font-family: $font-family-bold;
      letter-spacing: 0.65px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    .PlasmPurposes{
      margin-bottom: 80px;

      &__title{
        color: #72638C;
        font-size: 14px;
        font-weight: bold;
        font-family: $font-family-bold;
        letter-spacing: 2.1px;
        margin-bottom: 50px;
        text-align: center;
      }
    }

    .PlasmPurposeCards{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -20px;
      margin-right: -20px;

      @include breakpoint("tablet", max){
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .PlasmPurposeCard{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      max-width: 255px;
      margin-left: 20px;
      margin-right: 20px;

      @include breakpoint("tablet", max) {
        margin-bottom: 40px;
      }

      img{
        height: 50px;
        object-fit: cover;
        margin-bottom: 20px;
      }

      &__title{
        font-size: 16px;
        font-weight: bold;
        font-family: $font-family-bold;
        letter-spacing: 2.4px;
        color: $color-green;
        margin-bottom: 20px;
        text-align: center;
      }

      &__description{
        font-size: 13px;
        font-weight: normal;
        line-height: 19px;
        color: white;
        text-align: center;
      }
    }

    &__stake-button{
      margin-bottom: 15px;
    }

    .AndEarnPLASMTokens{
      margin-bottom: 60px;
    }
  }

  .TPMCSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    &__hero-image{
      width: 100%;
      max-width: 454px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;

      @include breakpoint("tablet", max) {
        max-width: 300px;
      }
    }

    &__title{
      font-family: $font-family-bold;
      font-weight: bold;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 3.26px;
      text-transform: uppercase;
      color: $color-red;
      margin-bottom: 20px;

      @include breakpoint("tablet", max) {
        letter-spacing: 2.7px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__description{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      max-width: 690px;
      width: 100%;
      text-align: center;
      margin-bottom: 50px;

      @include breakpoint("tablet", max){
        font-size: 14px;
        line-height: 17px;
      }
    }

    .FeatureCards{
      margin-bottom: 60px;
    }

    &__prove-membership-button{
      margin-bottom: 60px;
    }
  }

  .RoadmapSection{
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title{
      font-family: $font-family-bold;
      font-weight: bold;
      font-size: 80px;
      line-height: 80px;
      text-align: center;
      letter-spacing: 0;
      text-transform: uppercase;
      color: $color-green;
      opacity: 1;
      margin-bottom: 70px;
      white-space: pre-wrap;

      @include breakpoint("tablet", max) {
        font-size: 42px;
        line-height: 42px;
      }
    }

    &__phases{
      display: flex;
      flex-direction: column;

      .RoadmapPhase{
        &:last-of-type{
          margin-bottom: 0 !important;
        }
      }
    }

    .RoadmapPhase{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 60px;

      &:last-of-type{
        margin-bottom: 0 !important;
      }

      &__title{
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        font-family: $font-family-bold;
        letter-spacing: 2.4px;
        color: $color-green;
        margin-bottom: 30px;

        @include breakpoint("tablet", max) {
          text-align: left;
        }
      }
    }

    .timeline-container {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0;
      width: 100%;
    }

    .timeline-container::after {
      // middle line
      background-color: #383043;
      content: '';
      position: absolute;
      left: calc(50% - 0.5px);
      width: 1px;
      margin-top: 25px;
      height: calc(100% - 50px);

      @include breakpoint("tablet", max) {
        left: 20px;
      }
    }

    .timeline-item {
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
      position: relative;
      margin-top: 0;
      margin-bottom: 10px;
      width: 50%;

      @include breakpoint("tablet", max) {
        width: 100%;
        justify-content: flex-start;
        padding-left: 50px !important;
      }
    }

    .timeline-item:nth-child(odd) {
      align-self: flex-end;
      justify-content: flex-start;
      padding-left: 30px;
      padding-right: 0;

      @include breakpoint("tablet", max) {
        align-self: unset;
      }
    }

    .timeline-item-content {
      border-radius: 10px;
      background-color: #1F1B26;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 15px;
      position: relative;
      text-align: right;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      color: #72638C;

      @include breakpoint("tablet", max) {
        font-size: 14px;
        line-height: 17px;
        text-align: left;
      }
    }

    .timeline-item:nth-child(odd) .timeline-item-content {
      text-align: left;
      align-items: flex-start;
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
      right: auto;
      left: -7.5px;

      @include breakpoint("tablet", max) {
        right: unset;
        left: unset;
      }
    }

    .timeline-item-content .icon {
      position: absolute;
      top: calc(50% - 10px);
      right: -40px;
      width: 20px;
      height: 20px;
      z-index: 100;

      @include breakpoint("tablet", max) {
        right: unset;
        left: -40px !important;
      }
    }

    .timeline-item:nth-child(odd) .timeline-item-content .icon {
      right: auto;
      left: -40px;
    }
  }

  .TeamSection{
    display: flex;
    flex-direction: column;

    &__title{
      font-size: 14px;
      font-family: $font-family-bold;
      font-weight: bold;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      color: $color-green;
      margin-bottom: 40px;
      text-align: center;
    }

    &__body{
      font-size: 38px;
      font-weight: bold;
      line-height: 45px;
      font-family: $font-family-bold;
      text-align: center;
      max-width: 888px;
      width: 100%;

      @include breakpoint("tablet", max) {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }

  .FooterSection{
    img{
      max-width: 875px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .AndEarnPLASMTokens{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-family: $font-family-bold;
    font-weight: bold;
    letter-spacing: 0.67px;
    color: $color-green;
    text-transform: uppercase;

    img{
      width: 67px;
      height: 67px;
      margin-left: 1px;
      margin-right: 1px;
    }
  }

  .TitleSeparatorImage{
    display: flex;
    flex-direction: row;
    align-items: center;

    &__title{
      font-size: 16px;
      font-family: $font-family-bold;
      font-weight: bold;
      letter-spacing: 2.42px;
      color: $color-green;

      @include breakpoint("tablet", max) {
        font-size: 13px;
        letter-spacing: 1.9px;
      }
    }

    &__separator{
      background-color: #383043;
      width: 1px;
      height: 32px;
      margin-left: 18px;
      margin-right: 18px;
    }

    &__image{
      height: 18px;

      @include breakpoint("tablet", max) {
        height: 14px;
      }
    }
  }

  .FeatureCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -30px;
    margin-right: -30px;

    @include breakpoint("tablet", max) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .FeatureImageCard {
    display: flex;
    flex-direction: column;
    background: #1F1B26;
    border-radius: 30px;
    position: relative;
    max-width: 520px;
    //min-height: 700px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
    flex: 1;
    width: 100%;

    @include breakpoint("tablet", max) {
      min-height: unset;
      margin-bottom: 25px;
      margin-left: 0;
      margin-right: 0;
      width: 60%;
      flex: unset;
    }

    @include breakpoint("phone", max) {
      width: 70%;
    }

    img{
      width: 100%;
      height: 100%;
    }
  }

  .FeatureCard{
    display: flex;
    flex-direction: column;
    background: #1F1B26;
    border-radius: 30px;
    position: relative;
    max-width: 520px;
    min-height: 700px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;

    @include breakpoint("tablet", max) {
      min-height: unset;
      margin-bottom: 25px;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    &__coming-soon{
      position: absolute;
    }

    &__image{
      width: 100%;
      height: 378px;
      object-fit: contain;
      margin-bottom: 25px;

      @include breakpoint("tablet", max) {
        height: 238px;
      }
    }

    &__title{
      font-size: 48px;
      font-family: $font-family-bold;
      font-weight: bold;
      line-height: 48px;
      letter-spacing: 0;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      margin-bottom: 15px;

      @include breakpoint("tablet", max) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    &__description{
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      padding-left: 65px;
      padding-right: 65px;
      text-align: center;

      @include breakpoint("tablet", max) {
        font-size: 14px;
        line-height: 17px;
        padding-left: 26px;
        padding-right: 26px;
        margin-bottom: 80px;
      }
    }

    &__links{
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 40px;
      width: 100%;
      justify-content: center;

      @include breakpoint("tablet", max) {
        bottom: 20px;
      }

      a{
        color: white;
      }
    }

    &__link{
      letter-spacing: 0.65px;
      font-size: 13px;
      font-family: $font-family-bold;
      font-weight: bold;
      margin-left: 20px;
      margin-right: 20px;

      @include breakpoint("tablet", max) {
        font-size: 11px;
      }
    }

    &--green{
      .FeatureCard__title{
        color: $color-green;
      }

      a{
        color: $color-green;
      }
    }

    &--pink{
      .FeatureCard__title{
        color: #F400EF;
      }

      a{
        color: #F400EF;
      }
    }

    &--red{
      .FeatureCard__title{
        color: #FF2965;
      }

      a{
        color: #FF2965;
      }
    }
  }

  .PurchaseNFTsAt{
    display: flex;
    flex-direction: column;

    &__title{
      font-size: 14px;
      font-family: $font-family-bold;
      font-weight: bold;
      letter-spacing: 2.1px;
      margin-bottom: 26px;
      text-transform: uppercase;
      text-align: center;
    }

    &__cards{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include breakpoint("tablet", max) {
        justify-content: center;
        flex-direction: column;
      }
    }

    .PurchaseNFTsAtCard{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1F1B26;
      border-radius: 10px;
      height: 76px;
      margin-left: 13px;
      margin-right: 13px;
      width: 298px;
      transition: opacity 0.2s ease-in-out;

      img{
        width: 100%;
        height: 26px;
        object-fit: scale-down;
      }

      @include breakpoint("tablet", max) {
        margin-bottom: 20px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &:hover{
        opacity: 0.85;
      }
    }
}

.SectionSeparator{
display: flex;
flex-direction: row;
width: 100%;
align-items: center;
margin-top: 68px;
margin-bottom: 50px;

&::before{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #383043;
}

&::after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #383043;
}

img{
  height: 37px;
  margin-left: 24px;
  margin-right: 24px;
}
}

.PlasmAudit{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

&__title{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  margin-right: 20px;
  text-transform: uppercase;
}

img{
  height: 22px;
  margin-left: 6px;
}
}
}