@charset "UTF-8";
.color--red {
  color: #FF2965;
}

.color--green {
  color: #76FF80;
}

.color--white {
  color: white;
}

body {
  font-family: "futura-pt", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}

@keyframes pulseSmall {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.01, 1.01, 1.01);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
.animate__pulseSmall {
  animation-name: pulseSmall;
  animation-timing-function: ease-in-out;
}

body {
  margin: 0;
  font-family: "futura-pt", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #100E14;
  color: #72638C;
}

html {
  background-color: #100E14;
}

a, button, .TextTabs {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
a *, button *, .TextTabs * {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

@media only screen and (max-width: 799px) {
  .HideOnMobile {
    display: none !important;
  }
}

@media only screen and (min-width: 800px) {
  .HideOnNonMobile {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  .HideOnNonMobilePhones {
    display: none !important;
  }
}

@keyframes idle {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes idleShadow {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.Page {
  max-width: 1185px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  padding-top: 0;
  min-height: calc(100vh - 74px);
}

.NavBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1185px;
  background-color: #100E14;
  z-index: 1000;
  padding-left: 20px;
  padding-right: 20px;
}
.NavBar .NavBarBackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.NavBar .NavBarBackButton img {
  height: 12px;
}
.NavBar__right {
  margin-left: auto;
}
.NavBar__right .NavBarItem {
  margin-right: 0;
}
.NavBar__left {
  display: flex;
  align-items: center;
}
.NavBar__middle {
  display: flex;
  align-items: start;
  justify-content: center;
  flex: 2;
}
.NavBar__right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
@media only screen and (max-width: 799px) {
  .NavBar {
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.NavBar--staking {
  height: 74px;
  border-bottom: 1px solid #382F47;
  border-bottom: 1px solid transparent;
  max-width: 1185px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (max-width: 799px) {
  .NavBar--staking {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.NavBar--staking .NavBarItem {
  color: #72638C;
}
.NavBar--staking .PlasmBalances {
  margin-right: 16px;
  margin-left: auto;
}
.NavBar--staking .NavBarUser {
  margin-left: unset;
}

.NavBarItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  letter-spacing: 0px;
  color: #72638C;
  line-height: 16px;
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
}
@media only screen and (max-width: 949px) {
  .NavBarItem {
    font-size: 12px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
.NavBarItem:hover {
  color: #76FF80;
}
.NavBarItem--Members {
  color: #FF5436;
}
.NavBarItem--Members:hover {
  color: #ff310d;
}

.NavBarSocialIcon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}
.NavBarSocialIcon img {
  width: 100%;
}
.NavBarSocialIcon:hover {
  opacity: 0.75;
}

.NavBarHamburgerButton {
  position: absolute;
  left: 20px;
}

.NavBarMenuHeader {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}
.NavBarMenuHeader__social-icons {
  display: flex;
  flex-direction: row;
}
.NavBarMenuHeader .NavBarSocialIcon {
  width: 28px;
  height: 28px;
}

.NavBarLogo {
  margin-right: 12px;
  width: 46px;
}
@media only screen and (max-width: 639px) {
  .NavBarLogo {
    height: 36px;
  }
}

.NavBarUser {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
.NavBarUser__avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}
.NavBarUser__avatar:hover {
  transform: scale(1.05);
}
.NavBarUser__avatar div {
  height: inherit;
  width: inherit;
}
.NavBarUser__name {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 12px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100% !important;
  width: 300px !important;
  top: 0 !important;
  right: initial;
  left: 0 !important;
}
.bm-menu-wrap [aria-hidden=true] {
  left: -300 !important;
}

/* General sidebar styles */
.bm-menu {
  background: #100E14 !important;
  padding: 0 !important;
  font-size: inherit !important;
  top: 0 !important;
}
.bm-menu .NavBarItem {
  margin-left: 20px;
  margin-right: 20px;
  height: 18px;
  margin-bottom: 30px;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0 !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.NavBarUserDropdown {
  width: 180px;
  background-color: #1C1824;
  border-radius: 8px;
  border: 1px solid #382F47;
}
.NavBarUserDropdown__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #382F47;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #72638C;
}
.NavBarUserDropdown__row:first-of-type {
  color: white;
  font-weight: 500;
}
.NavBarUserDropdown__row:last-of-type {
  border-bottom: none;
}
.NavBarUserDropdown__row--danger {
  color: #FF2965;
}

.NavBarStakeButtonWrapper {
  position: relative;
  display: flex;
}

.NavBarStakeDropdown {
  width: 180px;
  background-color: #1C1824;
  border-radius: 8px;
  border: 1px solid #382F47;
}
.NavBarStakeDropdown__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #382F47;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #72638C;
}
.NavBarStakeDropdown__row:last-of-type {
  border-bottom: none;
}

.NavBarUserDropdownVIPTier {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  border-bottom: 1px solid #382F47;
}
.NavBarUserDropdownVIPTier__title {
  font-size: 11px;
  font-weight: bold;
  color: #72638C;
  line-height: 14px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.NavBarUserDropdownVIPTier__word {
  height: 9px;
  align-self: flex-start;
}

.Logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 395px;
}
@media only screen and (max-width: 479px) {
  .Logo {
    max-width: 280px;
  }
}
.Logo .LogoWords {
  margin-bottom: 3px;
}

.LogoCharacters {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}
.LogoCharacters__left {
  margin-right: 13px;
  animation: idle ease-in-out 4s infinite;
}
.LogoCharacters__right {
  margin-left: 13px;
  animation: idle ease-in-out 4s infinite;
  animation-delay: 467ms;
}

.Loader {
  position: relative;
  border-top: 1.1em solid #382F47;
  border-right: 1.1em solid #382F47;
  border-bottom: 1.1em solid #382F47;
  border-left: 1.1em solid #76FF80;
  transform: translateZ(0);
  animation: tpmcLoaderSpin 1.1s infinite linear;
  border-radius: 50%;
}
.Loader:after {
  border-radius: 50%;
}
.Loader--14px {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-width: 2px;
}
.Loader--xsmall {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-width: 2px;
}
.Loader--small {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-width: 3px;
}
.Loader--medium {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-width: 3px;
}
.Loader--large {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-width: 3px;
}
.Loader--xlarge {
  width: 56px;
  height: 56px;
  border-width: 3.75px;
}
.Loader--center {
  display: block;
  margin: auto;
}

@keyframes tpmcLoaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.accordion {
  border: 0;
}
.accordion .accordion-item .title {
  color: #72638C;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  background-color: transparent;
  border-bottom: 0.5px solid rgba(117, 128, 140, 0.5);
  height: 65px;
}
@media only screen and (max-width: 799px) {
  .accordion .accordion-item .title {
    font-size: 16px;
    line-height: 20px;
  }
}
.accordion .accordion-item .title:after {
  content: "➕";
  /* Unicode character for "plus" sign (+) */
  font-size: 18px;
  color: #76FF80 !important;
  float: right;
  margin-left: 5px;
}
.accordion .accordion-item .panel {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.accordion .accordion-item.active .title {
  color: white;
  border-color: transparent;
}
.accordion .accordion-item.active .title:after {
  content: "➖";
  /* Unicode character for "minus" sign (-) */
  color: #FF5436;
}
.accordion .accordion-item.active .panel {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.Button {
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0 !important;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  transition: 0.1s ease-in-out opacity;
  text-decoration: none !important;
  background-color: #FF5436;
  font-family: "futura-pt", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #100E14;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}
.Button *, .Button div {
  cursor: inherit !important;
}
.Button:hover {
  opacity: 0.9;
}
.Button:active {
  opacity: 0.8;
}
.Button--is-disabled {
  cursor: not-allowed;
  opacity: 0.8 !important;
}
.Button--is-small {
  height: 28px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  border-radius: 4px;
  letter-spacing: 0.6px;
}
.Button--is-medium {
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  border-radius: 9px;
  letter-spacing: 0.6px;
}
.Button--is-large {
  height: 40px;
  line-height: 40px;
  padding-left: 26px;
  padding-right: 26px;
  font-size: 14px;
  border-radius: 9px;
}
.Button--is-xlarge {
  height: 56px;
  line-height: 62px;
  font-size: 18px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 0.91px;
  border-radius: 10px;
  padding-left: 36px;
  padding-right: 36px;
}
.Button--is-xlarge svg {
  font-size: 17px;
}
.Button--is-rounded {
  border-radius: 4px !important;
  box-shadow: 0px 0px 6px transparent;
  transition: all 0.2s ease-in-out;
}
.Button--is-rounded.LoginButton {
  border-radius: 10px !important;
}
.Button--is-rounded.Button--is-xlarge {
  border-radius: 6px !important;
}
.Button--is-rounded:hover.Button--green {
  box-shadow: 0px 0px 6px #76FF80CC;
}
.Button--is-rounded:hover.Button--red {
  box-shadow: 0px 0px 6px #FF2965CC;
}
.Button--is-rounded:hover.Button--plasm {
  box-shadow: 0px 0px 6px #76FF80CC;
}
.Button--is-rounded:hover.Button--tdrop {
  box-shadow: 0px 0px 6px #00D5F9CC;
}
.Button--is-rounded:hover.Button--tfuel {
  box-shadow: 0px 0px 6px #FF9F13CC;
}
.Button--is-rounded.Button--is-disabled {
  background: #72638C;
  opacity: 1 !important;
  box-shadow: none !important;
}
.Button--is-pill {
  border-radius: 13px !important;
  box-shadow: 0px 0px 6px transparent;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0px;
  font-size: 13px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.Button--is-pill:hover.Button--green {
  box-shadow: 0px 0px 6px #76FF80CC;
}
.Button--is-pill:hover.Button--red {
  box-shadow: 0px 0px 6px #FF2965CC;
}
.Button--is-pill.Button--red {
  background: rgba(64, 19, 36, 0.8) !important;
  color: #FF2965 !important;
}
.Button--is-outline {
  border-radius: 4px !important;
}
.Button--orange {
  background-color: #FF5436;
  color: #100E14;
}
.Button--red {
  background-color: #FF2965;
  color: #100E14;
}
.Button--green {
  background-color: #76FF80;
  color: #100E14;
}
.Button--black-green {
  background-color: #000000;
  color: #76FF80;
  box-shadow: 0px 0px 20px #76FF8033;
}
.Button--plasm {
  background-color: #76FF80;
  color: #100E14;
}
.Button--tdrop {
  background-color: #00D5F9;
  color: #100E14;
}
.Button--tfuel {
  background-color: #FF9F13;
  color: #100E14;
}
.Button--green-outline {
  border: 2px solid #76FF80;
  color: #76FF80;
  background-color: transparent;
}
.Button--grey {
  color: #2E273B;
  background-color: #72638C;
  box-shadow: none !important;
}
.Button--grey-outline {
  border: 2px solid #72638C;
  color: #72638C;
  background-color: transparent;
  box-shadow: none !important;
}
.Button--grey-outline:hover {
  border-color: #76FF80;
  color: #76FF80;
}
.Button--red-outline {
  border: 2px solid #FF2965;
  color: #FF2965;
  background-color: transparent;
  box-shadow: none !important;
}
.Button--red-outline:hover {
  border-color: #FF2965;
  color: #FF2965;
}
.Button--black-orange {
  background-color: #FF5436;
}
.Button--thetacon-pink {
  background-color: #f24d84;
  color: #100E14;
}
.Button--thetacon-green {
  background-color: #3f9870;
  color: #100E14;
}
.Button--black {
  background-color: #1F1B26;
  color: white;
}
.Button--black:hover {
  background-color: #302a3b;
}
.Button--black-with-grey {
  background-color: #1F1B26;
  color: #72638C;
}
.Button--black-with-grey:hover {
  background-color: #302a3b;
}
.Button--text-only-green {
  border-color: transparent;
  background-color: transparent;
  color: #76FF80;
}
.Button--vip-tier-bronze {
  background: transparent linear-gradient(0deg, #733A2B 0%, #814635 17%, #A86652 50%, #E79A7F 94%, #F0A286 100%) 0% 0% no-repeat padding-box;
}
.Button--vip-tier-silver {
  background: transparent linear-gradient(0deg, #415F73 0%, #4E6C81 17%, #728FA8 50%, #ABC7E7 94%, #B4D0F0 100%) 0% 0% no-repeat padding-box;
}
.Button--vip-tier-gold {
  background: transparent linear-gradient(180deg, #F0BA17 0%, #B28612 47%, #8B6510 82%, #7D590F 100%) 0% 0% no-repeat padding-box;
}
.Button--vip-tier-essu {
  background: transparent linear-gradient(180deg, #F400EF 0%, #6B0A91 100%) 0% 0% no-repeat padding-box;
}

.GetMorePlasmButton {
  background: rgba(118, 255, 128, 0.12);
  color: #76FF80;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  width: 280px;
  border-radius: 4px;
  height: 32px;
}
.GetMorePlasmButton__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.GetMorePlasmButton img {
  width: 15px;
  height: 15px;
  margin-left: 6px;
  margin-right: 6px;
}

.TPMCWarning {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  background: rgba(255, 41, 101, 0.12);
  color: #FF2965;
  border-radius: 4px;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  cursor: auto;
  z-index: 1000000;
}
.Modal:focus {
  outline: none;
}

.ModalOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(16, 14, 20, 0.85);
  cursor: pointer;
  z-index: 1000000;
}

.RoundedModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1824;
  border-radius: 20px;
  border: 1px solid #382F47;
  max-width: 980px;
  width: 100%;
  height: 100vh;
  max-height: 600px;
}
.RoundedModal__content {
  width: 100%;
  height: calc(100% - 72px);
  overflow-y: auto;
  overflow-x: hidden;
}

.RoundedModalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 72px;
  border-bottom: 1px solid #382F47;
  position: relative;
}
.RoundedModalHeader__title {
  font-size: 19px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #72638C;
  letter-spacing: 2.85px;
  text-transform: uppercase;
  line-height: 25px;
}
@media only screen and (max-width: 799px) {
  .RoundedModalHeader__title {
    font-size: 15px;
  }
}
.RoundedModalHeader__subtitle {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 16px;
  margin-top: 0;
}
.RoundedModalHeader__available-in, .RoundedModalHeader__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: -10px;
}
.RoundedModalHeader__available-in {
  color: #76FF80;
}
.RoundedModalHeader__available-for {
  color: #FF2965;
}
.RoundedModalHeader__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.RoundedModalHeader__close-button img {
  width: 15.5px;
  height: 15.5px;
  cursor: pointer;
}

.TextTabs--no-underline .TextTabList .react-tabs__tab::after {
  content: none;
}

.TextTabList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;
  padding-inline-start: 0;
}
.TextTabList .react-tabs__tab {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  color: #72638C;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
}
@media only screen and (max-width: 699px) {
  .TextTabList .react-tabs__tab {
    margin-right: 10px;
    margin-left: 10px;
  }
}
.TextTabList .react-tabs__tab * {
  cursor: pointer;
}
.TextTabList .react-tabs__tab span {
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  color: inherit;
}
@media only screen and (max-width: 699px) {
  .TextTabList .react-tabs__tab span {
    font-size: 12px;
  }
}
.TextTabList .react-tabs__tab--selected {
  color: #76FF80;
}
.TextTabList .react-tabs__tab--selected::after {
  content: "";
  height: 2px;
  width: 17px;
  background-color: #76FF80;
  bottom: -2px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.NumberBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #FF2965;
  color: white;
  border-radius: 10px;
  min-width: 34px;
  height: 20px;
  font-weight: 500;
}

.BetaBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #5d5177;
  color: #2d2739;
  border-radius: 10px;
  min-width: 34px;
  height: 20px;
  font-weight: 600;
}

.PassawayWithMeta {
  position: relative;
  width: 187px;
  height: 187px;
}
.PassawayWithMeta__image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.PassawayWithMeta__edition {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
  background-color: rgba(16, 14, 20, 0.6);
  border-radius: 2px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  padding-left: 4px;
  padding-right: 4px;
  height: 14px;
}
.PassawayWithMeta .StakingTierBadge {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
}
.PassawayWithMeta__999-badge {
  width: 24px;
  height: 24px;
}
.PassawayWithMeta__transphorm-badge {
  width: 24px;
  height: 24px;
}
.PassawayWithMeta__icons {
  height: 80px;
  width: 24px;
  position: absolute;
  left: 8px;
  bottom: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.PassawayWithMeta__icons img:last-child {
  margin-top: 6px;
}

.StakingTierBadge {
  display: flex;
  align-items: center;
  background-color: #72638C;
  border-radius: 2px;
  color: #100E14;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
  text-transform: uppercase;
  height: 14px;
}
.StakingTierBadge--base {
  background-color: #8977A8;
}
.StakingTierBadge--common {
  background-color: #76FF80;
}
.StakingTierBadge--uncommon {
  background-color: #F5D638;
}
.StakingTierBadge--rare {
  background-color: #BD08FF;
}
.StakingTierBadge--epic {
  background-color: #1CA8FF;
}
.StakingTierBadge--legendary {
  background-color: #CF8200;
}
.StakingTierBadge--custom {
  background-color: #FF2965;
}

.InventoryItemCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 294px;
  border-radius: 14px;
  padding: 10px;
  padding-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
}
.InventoryItemCard__image {
  width: 176px;
  height: 176px;
  margin-bottom: 10px;
}
.InventoryItemCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.InventoryItemCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 16px;
}
.InventoryItemCard__footer {
  margin-top: auto;
}
.InventoryItemCard__footer .Button {
  width: 100%;
}
.InventoryItemCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
}
.InventoryItemCard--PowerUp {
  padding-top: 27px;
}
.InventoryItemCard--PowerUp .InventoryItemCard__image {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 26px;
}
.InventoryItemCard--no-button {
  height: 264px;
}

.ProductCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 330px;
  border-radius: 14px;
  padding: 10px;
  padding-top: 20px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  cursor: pointer;
  transition: background-color ease-in-out 0.2s;
}
.ProductCard * {
  cursor: inherit;
}
.ProductCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  object-fit: contain;
}
.ProductCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.ProductCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ProductCard__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-bottom: auto;
}
.ProductCard__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.ProductCard__available-in, .ProductCard__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ProductCard__available-in {
  color: #76FF80;
}
.ProductCard__available-for {
  color: #FF2965;
}
.ProductCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
}
.ProductCard__footer .Button {
  flex: 1;
}
.ProductCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}
.ProductCard .BetaBadge {
  position: absolute;
  top: 10px;
  left: 10px;
  padding-left: 6px;
  padding-right: 6px;
}
.ProductCard:hover {
  background-color: #342c43;
}

.PlasmBalances {
  display: flex;
  flex-direction: row;
  background-color: #2E273B;
  border-radius: 20px;
  height: 40px;
  cursor: pointer;
}
.PlasmBalances:hover {
  background-color: rgba(46, 39, 59, 0.8);
}
.PlasmBalances__logo {
  width: 28px;
  height: 28px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 16px;
}
@media only screen and (max-width: 399px) {
  .PlasmBalances__logo {
    margin-right: 10px;
  }
}
.PlasmBalances__columns {
  display: flex;
  flex-direction: row;
}
.PlasmBalances__caret {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  border-left: 1px solid #3C334D;
}
@media only screen and (max-width: 399px) {
  .PlasmBalances__caret {
    display: none;
  }
}
.PlasmBalances__caret img {
  width: 12px;
}
.PlasmBalances * {
  cursor: inherit;
}

.PlasmBalanceColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 24px;
}
.PlasmBalanceColumn:last-of-type {
  margin-right: 0;
}
.PlasmBalanceColumn--staked {
  margin-right: 14px;
}
.PlasmBalanceColumn__title {
  font-size: 11px;
  line-height: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #76FF80;
  text-transform: uppercase;
}
@media only screen and (max-width: 799px) {
  .PlasmBalanceColumn__title {
    font-size: 9px;
  }
}
.PlasmBalanceColumn__balance {
  font-size: 12px;
  line-height: 17px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
}
@media only screen and (max-width: 899px) {
  .PlasmBalanceColumn__balance {
    font-size: 12px;
  }
}
@media only screen and (max-width: 899px) {
  .PlasmBalanceColumn--pending {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .PlasmBalanceColumn--staked {
    display: none;
  }
}

.CraftableItemCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  min-height: 294px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  padding-top: 20px;
}
.CraftableItemCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 150px;
  margin-bottom: 9px;
  object-fit: contain;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.CraftableItemCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.CraftableItemCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 15px;
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CraftableItemCard__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-bottom: auto;
}
.CraftableItemCard__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.CraftableItemCard__available-in, .CraftableItemCard__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.CraftableItemCard__available-in {
  color: #76FF80;
}
.CraftableItemCard__available-for {
  color: #FF2965;
}
.CraftableItemCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 10px;
  padding-right: 10px;
}
.CraftableItemCard__footer .Button {
  margin-left: auto;
  margin-right: auto;
  width: 84px;
}
.CraftableItemCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.CraftableItemInputCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 294px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  flex-shrink: 0;
}
.CraftableItemInputCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 194px;
  margin-bottom: 9px;
  object-fit: contain;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: black;
  padding: 15px;
}
.CraftableItemInputCard .PassawayWithMeta {
  height: 194px;
  width: 100%;
  margin-bottom: 9px;
}
.CraftableItemInputCard--missing .CraftableItemInputCard__image {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.CraftableItemInputCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.CraftableItemInputCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 15px;
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CraftableItemInputCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 10px;
  padding-right: 10px;
}
.CraftableItemInputCard__footer .Button {
  margin-left: auto;
  margin-right: auto;
  width: 84px;
}

.CraftableItemOrderCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  min-height: 294px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  padding-top: 20px;
}
.CraftableItemOrderCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 150px;
  margin-bottom: 9px;
  object-fit: contain;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.CraftableItemOrderCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.CraftableItemOrderCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 15px;
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CraftableItemOrderCard__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-bottom: auto;
}
.CraftableItemOrderCard__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.CraftableItemOrderCard__available-in, .CraftableItemOrderCard__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.CraftableItemOrderCard__available-in {
  color: #76FF80;
}
.CraftableItemOrderCard__available-for {
  color: #FF2965;
}
.CraftableItemOrderCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 10px;
  padding-right: 10px;
}
.CraftableItemOrderCard__footer .Button {
  margin-left: auto;
  margin-right: auto;
  width: 84px;
}
.CraftableItemOrderCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.LockedTokenCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  min-height: 294px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  padding-top: 20px;
}
.LockedTokenCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 150px;
  margin-bottom: 9px;
  object-fit: contain;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.LockedTokenCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.LockedTokenCard__description {
  font-size: 12px;
  font-weight: 500;
  color: #72638C;
  line-height: 15px;
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LockedTokenCard__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-bottom: auto;
}
.LockedTokenCard__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.LockedTokenCard__available-in, .LockedTokenCard__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 12px;
}
.LockedTokenCard__available-in {
  color: #76FF80;
}
.LockedTokenCard__available-for {
  color: #FF2965;
}
.LockedTokenCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 10px;
  padding-right: 10px;
}
.LockedTokenCard__footer .Button {
  margin-left: auto;
  margin-right: auto;
  width: 84px;
}
.LockedTokenCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.RedeemableCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 344px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  padding-top: 20px;
}
.RedeemableCard__image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 150px;
  margin-bottom: 9px;
  object-fit: contain;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.RedeemableCard__name {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 18px;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;
}
.RedeemableCard__description {
  font-size: 12px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  line-height: 15px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.RedeemableCard__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
.RedeemableCard__inputs img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.RedeemableCard__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-bottom: auto;
}
.RedeemableCard__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.RedeemableCard__available-in, .RedeemableCard__available-for {
  font-size: 12px;
  font-weight: 500;
  color: #76FF80;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 12px;
}
.RedeemableCard__available-in {
  color: #76FF80;
}
.RedeemableCard__available-for {
  color: #FF2965;
}
.RedeemableCard__footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 10px;
  padding-right: 10px;
}
.RedeemableCard__footer .Button {
  margin-left: auto;
  margin-right: auto;
  width: 84px;
}
.RedeemableCard .NumberBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.PlasmPrice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #76FF80;
  line-height: 17px;
  margin-bottom: auto;
}
.PlasmPrice::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.PlasmPrice--large {
  font-size: 25px;
  line-height: 30px;
}
.PlasmPrice--large::before {
  background-size: 22px;
  width: 22px;
  height: 22px;
}

.CTABanner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 799px) {
  .CTABanner {
    align-items: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.CTABanner__icon {
  height: 50px;
  width: 50px;
  margin-right: 40px;
}
@media only screen and (max-width: 799px) {
  .CTABanner__icon {
    margin-right: 14px;
  }
}
.CTABanner__titles {
  display: flex;
  flex-direction: column;
}
.CTABanner__titles-and-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 799px) {
  .CTABanner__titles-and-button {
    flex-direction: column;
    align-items: unset;
  }
}
.CTABanner__title {
  font-size: 17px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 0.85px;
  line-height: 25px;
  margin-bottom: 2px;
  text-transform: uppercase;
}
@media only screen and (max-width: 799px) {
  .CTABanner__title {
    font-size: 13px;
    letter-spacing: 0.7px;
    line-height: 17px;
  }
}
.CTABanner__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
@media only screen and (max-width: 799px) {
  .CTABanner__subtitle {
    font-size: 12px;
    line-height: 16px;
  }
}
.CTABanner__button {
  min-width: 130px;
  margin-left: auto;
}
@media only screen and (max-width: 799px) {
  .CTABanner__button {
    margin-left: unset;
    margin-top: 15px;
  }
}
.CTABanner--purple {
  background-color: #2C1338;
}
.CTABanner--purple .CTABanner__title {
  color: #E213E8;
}
.CTABanner--purple .CTABanner__subtitle {
  color: #72638C;
}
.CTABanner--green {
  background-color: #192622;
}
.CTABanner--green .CTABanner__title {
  color: #76FF80;
}
.CTABanner--green .CTABanner__subtitle {
  color: #668C68;
}

.QtySelectorWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.QtySelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.QtySelectorAmount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #FFFFFF;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 17.5px;
  height: 35px;
  border: 1px solid #382F47;
  flex: 1;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.QtySelectorButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #382F47;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}
.QtySelectorButton__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  transition: background-color ease-in-out 0.2s;
}
.QtySelectorButton:hover {
  border: 1px solid #76FF80;
}
.QtySelectorButton:hover .QtySelectorButton__icon {
  color: #76FF80;
}
.QtySelectorButton--disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.QtySelectorButton--disabled .QtySelectorButton__icon {
  color: #382F47;
}

.QtySelectorAmountShortcuts {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
}

.QtySelectorAmountShortcut {
  width: 56px;
  height: 30px;
  border: 1px solid #382F47;
  font-size: 16px;
  font-weight: 500;
  color: #72638C;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.QtySelectorAmountShortcut:hover {
  background-color: #382F47;
  color: #FFFFFF;
}
.QtySelectorAmountShortcut--active {
  color: #FFFFFF;
}

.EmptyState {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 502px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.EmptyState__title {
  color: #382F47;
  text-align: center;
  letter-spacing: 5.85px;
  color: #382F47;
  font-size: 39px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  text-transform: uppercase;
  white-space: pre-line;
}
@media only screen and (max-width: 699px) {
  .EmptyState__title {
    font-size: 22px;
    letter-spacing: 2.35px;
  }
}
.EmptyState .Button {
  margin-top: 30px;
}

.NFTDrop {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 639px) {
  .NFTDrop {
    flex-direction: column;
  }
}
.NFTDrop .PlasmCTA {
  margin-top: 30px;
}
.NFTDrop .RequiredApprovals {
  margin-top: 18px;
}
.NFTDrop__asset {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  margin-right: 42px;
  cursor: pointer;
}
.NFTDrop__asset img {
  width: 100%;
  height: 100%;
  cursor: inherit;
}
@media only screen and (max-width: 639px) {
  .NFTDrop__asset {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }
}
.NFTDrop__asset--expanded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.NFTDrop__asset--expanded img {
  width: 100%;
  object-fit: scale-down;
  height: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.NFTDrop__info {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  width: 100%;
  max-width: 300px;
}
.NFTDrop__nft-name {
  font-size: 16px;
  line-height: 19px;
  color: white;
  font-weight: 500;
  margin-bottom: 7px;
  text-align: left;
}
@media only screen and (max-width: 639px) {
  .NFTDrop__nft-name {
    text-align: center;
  }
}
.NFTDrop__nft-description {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #72638C;
  text-align: left;
  margin-bottom: 7px;
}
@media only screen and (max-width: 639px) {
  .NFTDrop__nft-description {
    text-align: center;
  }
}
.NFTDrop .NFTDropSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}
.NFTDrop .NFTDropSection__title {
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #72638C;
  text-align: center;
  margin-bottom: 7px;
}
@media only screen and (max-width: 479px) {
  .NFTDrop .NFTDropSection__title {
    display: none;
  }
}
.NFTDrop .NFTDropSection__rows {
  display: flex;
  flex-direction: column;
}
.NFTDrop .NFTDropSectionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #382F47;
  border-top: 0;
  border-bottom: 1px solid #382F47;
  height: 32px;
}
.NFTDrop .NFTDropSectionRow--no-top-border {
  border-top: none;
}
.NFTDrop .NFTDropSectionRow__left {
  font-weight: 500;
  color: #72638C;
  font-size: 13px;
  line-height: 16px;
}
.NFTDrop .NFTDropSectionRow__right {
  font-weight: 500;
  color: white;
  font-size: 13px;
  line-height: 16px;
}
.NFTDrop .NFTDropPurchaseTotal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.NFTDrop .NFTDropPurchaseTotal.NFTDropPurchaseTotal--tfuel {
  color: #FF9F13;
}
.NFTDrop .NFTDropPurchaseTotal.NFTDropPurchaseTotal--tdrop {
  color: #00D5F9;
}
.NFTDrop .NFTDropPurchaseTotal.NFTDropPurchaseTotal--plasm {
  color: #76FF80;
}
.NFTDrop .NFTDropPurchaseTotal__icon {
  margin-left: 9px;
  width: 20px;
  height: 20px;
}
.NFTDrop .NFTDropPurchaseTotal__amount {
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
  color: inherit;
}
@media only screen and (max-width: 799px) {
  .NFTDrop .NFTDropPurchaseTotal__amount {
    font-size: 20px;
    line-height: 28px;
  }
}
.NFTDrop .NFTDropPaymentOptions {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 479px) {
  .NFTDrop .NFTDropPaymentOptions {
    flex-direction: row;
    margin-left: -6px;
    margin-right: -6px;
  }
  .NFTDrop .NFTDropPaymentOptions .NFTDropPaymentOption {
    margin-right: 6px;
    margin-left: 6px;
    flex: 1;
    margin-bottom: 0;
  }
}
.NFTDrop .NFTDropPaymentOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #382F47;
  height: 37px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}
.NFTDrop .NFTDropPaymentOption__token-icon {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}
.NFTDrop .NFTDropPaymentOption * {
  cursor: pointer;
}
.NFTDrop .NFTDropPaymentOption__left {
  font-weight: 500;
  color: #72638C;
  font-size: 13px;
  line-height: 16px;
}
.NFTDrop .NFTDropPaymentOption__right {
  font-weight: 500;
  color: white;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tfuel {
  border-color: #FF9F13;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tfuel .NFTDropPaymentOption__left, .NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tfuel .NFTDropPaymentOption__right {
  color: #FF9F13;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tdrop {
  border-color: #00D5F9;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tdrop .NFTDropPaymentOption__left, .NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--tdrop .NFTDropPaymentOption__right {
  color: #00D5F9;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--plasm {
  border-color: #76FF80;
}
.NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--plasm .NFTDropPaymentOption__left, .NFTDrop .NFTDropPaymentOption--selected.NFTDropPaymentOption--plasm .NFTDropPaymentOption__right {
  color: #76FF80;
}
@media only screen and (max-width: 479px) {
  .NFTDrop .QtySelectorWrapper {
    margin-top: 0px;
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 479px) {
  .NFTDrop .QtySelector {
    margin-top: 14px;
    margin-bottom: 7px;
  }
}
.NFTDrop__buy-button, .NFTDrop__sold-out-button {
  margin-top: 20px;
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .NFTDrop__buy-button, .NFTDrop__sold-out-button {
    margin-top: 12px;
  }
}

.RequiredApprovals {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.RequiredApprovals__title {
  font-size: 14px;
  color: #72638C;
  flex-shrink: 0;
}
.RequiredApprovals__approvals {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: transparent;
}
.RequiredApprovals__approvals::-webkit-scrollbar {
  width: 0;
}
.RequiredApprovals__approvals::-webkit-scrollbar-track {
  background-color: transparent;
}
.RequiredApprovals__approvals::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}
.RequiredApprovals .Loader {
  margin-left: 12px;
}

.RequiredApproval {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  flex-shrink: 0;
  cursor: pointer;
}
.RequiredApproval:hover {
  opacity: 0.85;
}
.RequiredApproval__name {
  font-size: 14px;
  color: #72638C;
  text-decoration: underline;
}
.RequiredApproval img {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.Tooltip {
  font-size: 12px !important;
  font-weight: 500 !important;
  pointer-events: auto !important;
  background-color: #2E273B !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  padding: 4px 12px !important;
}

.Tooltip::before,
.Tooltip::after {
  display: none !important;
}

.LotteryLeaderboard {
  color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  background-color: #1C1824;
  max-width: 505px;
}
.LotteryLeaderboard__title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  letter-spacing: 2.4px;
  font-size: 16px;
  text-transform: uppercase;
}
.LotteryLeaderboard__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.LotteryLeaderboardItem {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #382F47;
  transition: background-color 0.3s;
}
.LotteryLeaderboardItem:hover {
  background-color: #282233;
}
.LotteryLeaderboardItem:last-child {
  border-bottom: none;
}
.LotteryLeaderboardItem__avatar, .LotteryLeaderboardItem__address, .LotteryLeaderboardItem__points {
  margin-right: 10px;
}
.LotteryLeaderboardItem__rank {
  width: 2em;
  text-align: left;
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #76FF80;
}
.LotteryLeaderboardItem__avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-left: 5px;
}
.LotteryLeaderboardItem__avatar div {
  height: inherit;
  width: inherit;
}
.LotteryLeaderboardItem__address {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  color: #72638C;
}
.LotteryLeaderboardItem__points {
  min-width: 80px;
  text-align: right;
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  text-transform: uppercase;
  color: #72638C;
}

.PlasmCTA {
  display: flex;
  flex-direction: column;
}
.PlasmCTA__top-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #76FF80;
  line-height: 17px;
  justify-content: center;
}
.PlasmCTA__top-title img {
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 4px;
}
.CTAPillLink {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
  cursor: pointer;
  background: rgba(118, 255, 128, 0.15);
  color: #4C9B53;
}
.CTAPillLink * {
  cursor: inherit;
}

.LandingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
}
.LandingPage .PassawaysSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.LandingPage .PassawaysSection__hero-image {
  width: 100%;
  max-width: 1015px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 22px;
}
.LandingPage .PassawaysSection__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #76FF80;
  opacity: 1;
  margin-bottom: 30px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PassawaysSection__title {
    font-size: 38px;
    line-height: 38px;
  }
}
.LandingPage .PassawaysSection .TitleSeparatorImage {
  margin-bottom: 25px;
}
.LandingPage .PassawaysSection__description {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  max-width: 690px;
  width: 100%;
  text-align: center;
  margin-bottom: 70px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PassawaysSection__description {
    font-size: 14px;
    line-height: 17px;
  }
}
.LandingPage .PassawaysSection .FeatureCards {
  margin-bottom: 10px;
}
.LandingPage .PassawaysSection__stake-button {
  margin-bottom: 15px;
}
.LandingPage .PassawaysSection .AndEarnPLASMTokens {
  margin-bottom: 60px;
}
.LandingPage .PlasmSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.LandingPage .PlasmSection__hero-image {
  width: 100%;
  max-width: 1015px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 22px;
}
.LandingPage .PlasmSection__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 100px;
  line-height: 100px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #76FF80;
  opacity: 1;
  margin-bottom: 10px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PlasmSection__title {
    font-size: 42px;
    line-height: 42px;
  }
}
.LandingPage .PlasmSection .CTAPillLink {
  margin-bottom: 30px;
}
.LandingPage .PlasmSection__description {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  max-width: 530px;
  width: 100%;
  text-align: center;
  margin-bottom: 70px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PlasmSection__description {
    font-size: 14px;
    line-height: 17px;
  }
}
.LandingPage .PlasmSection__darkpaper-and-audit-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LandingPage .PlasmSection__darkpaper-button {
  margin-bottom: 40px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PlasmSection__darkpaper-button {
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px;
  }
}
.LandingPage .PlasmSection .PlasmAudit {
  margin-bottom: 16px;
}
.LandingPage .PlasmSection__read-audit-button {
  cursor: pointer;
  text-transform: uppercase;
  color: #00BFFF;
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 0.65px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.LandingPage .PlasmSection .PlasmPurposes {
  margin-bottom: 80px;
}
.LandingPage .PlasmSection .PlasmPurposes__title {
  color: #72638C;
  font-size: 14px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 2.1px;
  margin-bottom: 50px;
  text-align: center;
}
.LandingPage .PlasmSection .PlasmPurposeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PlasmSection .PlasmPurposeCards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.LandingPage .PlasmSection .PlasmPurposeCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 255px;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PlasmSection .PlasmPurposeCard {
    margin-bottom: 40px;
  }
}
.LandingPage .PlasmSection .PlasmPurposeCard img {
  height: 50px;
  object-fit: cover;
  margin-bottom: 20px;
}
.LandingPage .PlasmSection .PlasmPurposeCard__title {
  font-size: 16px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 2.4px;
  color: #76FF80;
  margin-bottom: 20px;
  text-align: center;
}
.LandingPage .PlasmSection .PlasmPurposeCard__description {
  font-size: 13px;
  font-weight: normal;
  line-height: 19px;
  color: white;
  text-align: center;
}
.LandingPage .PlasmSection__stake-button {
  margin-bottom: 15px;
}
.LandingPage .PlasmSection .AndEarnPLASMTokens {
  margin-bottom: 60px;
}
.LandingPage .TPMCSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
.LandingPage .TPMCSection__hero-image {
  width: 100%;
  max-width: 454px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TPMCSection__hero-image {
    max-width: 300px;
  }
}
.LandingPage .TPMCSection__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 3.26px;
  text-transform: uppercase;
  color: #FF2965;
  margin-bottom: 20px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TPMCSection__title {
    letter-spacing: 2.7px;
    font-size: 18px;
    line-height: 22px;
  }
}
.LandingPage .TPMCSection__description {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  max-width: 690px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TPMCSection__description {
    font-size: 14px;
    line-height: 17px;
  }
}
.LandingPage .TPMCSection .FeatureCards {
  margin-bottom: 60px;
}
.LandingPage .TPMCSection__prove-membership-button {
  margin-bottom: 60px;
}
.LandingPage .RoadmapSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.LandingPage .RoadmapSection__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #76FF80;
  opacity: 1;
  margin-bottom: 70px;
  white-space: pre-wrap;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection__title {
    font-size: 42px;
    line-height: 42px;
  }
}
.LandingPage .RoadmapSection__phases {
  display: flex;
  flex-direction: column;
}
.LandingPage .RoadmapSection__phases .RoadmapPhase:last-of-type {
  margin-bottom: 0 !important;
}
.LandingPage .RoadmapSection .RoadmapPhase {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 60px;
}
.LandingPage .RoadmapSection .RoadmapPhase:last-of-type {
  margin-bottom: 0 !important;
}
.LandingPage .RoadmapSection .RoadmapPhase__title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 2.4px;
  color: #76FF80;
  margin-bottom: 30px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .RoadmapPhase__title {
    text-align: left;
  }
}
.LandingPage .RoadmapSection .timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  width: 100%;
}
.LandingPage .RoadmapSection .timeline-container::after {
  background-color: #383043;
  content: "";
  position: absolute;
  left: calc(50% - 0.5px);
  width: 1px;
  margin-top: 25px;
  height: calc(100% - 50px);
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-container::after {
    left: 20px;
  }
}
.LandingPage .RoadmapSection .timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
  width: 50%;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-item {
    width: 100%;
    justify-content: flex-start;
    padding-left: 50px !important;
  }
}
.LandingPage .RoadmapSection .timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-item:nth-child(odd) {
    align-self: unset;
  }
}
.LandingPage .RoadmapSection .timeline-item-content {
  border-radius: 10px;
  background-color: #1F1B26;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  color: #72638C;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-item-content {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
}
.LandingPage .RoadmapSection .timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}
.LandingPage .RoadmapSection .timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-item:nth-child(odd) .timeline-item-content::after {
    right: unset;
    left: unset;
  }
}
.LandingPage .RoadmapSection .timeline-item-content .icon {
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}
@media only screen and (max-width: 799px) {
  .LandingPage .RoadmapSection .timeline-item-content .icon {
    right: unset;
    left: -40px !important;
  }
}
.LandingPage .RoadmapSection .timeline-item:nth-child(odd) .timeline-item-content .icon {
  right: auto;
  left: -40px;
}
.LandingPage .TeamSection {
  display: flex;
  flex-direction: column;
}
.LandingPage .TeamSection__title {
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  color: #76FF80;
  margin-bottom: 40px;
  text-align: center;
}
.LandingPage .TeamSection__body {
  font-size: 38px;
  font-weight: bold;
  line-height: 45px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  text-align: center;
  max-width: 888px;
  width: 100%;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TeamSection__body {
    font-size: 15px;
    line-height: 19px;
  }
}
.LandingPage .FooterSection img {
  max-width: 875px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.LandingPage .AndEarnPLASMTokens {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 0.67px;
  color: #76FF80;
  text-transform: uppercase;
}
.LandingPage .AndEarnPLASMTokens img {
  width: 67px;
  height: 67px;
  margin-left: 1px;
  margin-right: 1px;
}
.LandingPage .TitleSeparatorImage {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.LandingPage .TitleSeparatorImage__title {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 2.42px;
  color: #76FF80;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TitleSeparatorImage__title {
    font-size: 13px;
    letter-spacing: 1.9px;
  }
}
.LandingPage .TitleSeparatorImage__separator {
  background-color: #383043;
  width: 1px;
  height: 32px;
  margin-left: 18px;
  margin-right: 18px;
}
.LandingPage .TitleSeparatorImage__image {
  height: 18px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .TitleSeparatorImage__image {
    height: 14px;
  }
}
.LandingPage .FeatureCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCards {
    margin-left: 0;
    margin-right: 0;
  }
}
.LandingPage .FeatureImageCard {
  display: flex;
  flex-direction: column;
  background: #1F1B26;
  border-radius: 30px;
  position: relative;
  max-width: 520px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 60px;
  flex: 1;
  width: 100%;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureImageCard {
    min-height: unset;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;
    width: 60%;
    flex: unset;
  }
}
@media only screen and (max-width: 399px) {
  .LandingPage .FeatureImageCard {
    width: 70%;
  }
}
.LandingPage .FeatureImageCard img {
  width: 100%;
  height: 100%;
}
.LandingPage .FeatureCard {
  display: flex;
  flex-direction: column;
  background: #1F1B26;
  border-radius: 30px;
  position: relative;
  max-width: 520px;
  min-height: 700px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard {
    min-height: unset;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.LandingPage .FeatureCard__coming-soon {
  position: absolute;
}
.LandingPage .FeatureCard__image {
  width: 100%;
  height: 378px;
  object-fit: contain;
  margin-bottom: 25px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard__image {
    height: 238px;
  }
}
.LandingPage .FeatureCard__title {
  font-size: 48px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 0;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  margin-bottom: 15px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard__title {
    font-size: 32px;
    line-height: 32px;
  }
}
.LandingPage .FeatureCard__description {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding-left: 65px;
  padding-right: 65px;
  text-align: center;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard__description {
    font-size: 14px;
    line-height: 17px;
    padding-left: 26px;
    padding-right: 26px;
    margin-bottom: 80px;
  }
}
.LandingPage .FeatureCard__links {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 40px;
  width: 100%;
  justify-content: center;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard__links {
    bottom: 20px;
  }
}
.LandingPage .FeatureCard__links a {
  color: white;
}
.LandingPage .FeatureCard__link {
  letter-spacing: 0.65px;
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 799px) {
  .LandingPage .FeatureCard__link {
    font-size: 11px;
  }
}
.LandingPage .FeatureCard--green .FeatureCard__title {
  color: #76FF80;
}
.LandingPage .FeatureCard--green a {
  color: #76FF80;
}
.LandingPage .FeatureCard--pink .FeatureCard__title {
  color: #F400EF;
}
.LandingPage .FeatureCard--pink a {
  color: #F400EF;
}
.LandingPage .FeatureCard--red .FeatureCard__title {
  color: #FF2965;
}
.LandingPage .FeatureCard--red a {
  color: #FF2965;
}
.LandingPage .PurchaseNFTsAt {
  display: flex;
  flex-direction: column;
}
.LandingPage .PurchaseNFTsAt__title {
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 2.1px;
  margin-bottom: 26px;
  text-transform: uppercase;
  text-align: center;
}
.LandingPage .PurchaseNFTsAt__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PurchaseNFTsAt__cards {
    justify-content: center;
    flex-direction: column;
  }
}
.LandingPage .PurchaseNFTsAt .PurchaseNFTsAtCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1F1B26;
  border-radius: 10px;
  height: 76px;
  margin-left: 13px;
  margin-right: 13px;
  width: 298px;
  transition: opacity 0.2s ease-in-out;
}
.LandingPage .PurchaseNFTsAt .PurchaseNFTsAtCard img {
  width: 100%;
  height: 26px;
  object-fit: scale-down;
}
@media only screen and (max-width: 799px) {
  .LandingPage .PurchaseNFTsAt .PurchaseNFTsAtCard {
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.LandingPage .PurchaseNFTsAt .PurchaseNFTsAtCard:hover {
  opacity: 0.85;
}
.LandingPage .SectionSeparator {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 68px;
  margin-bottom: 50px;
}
.LandingPage .SectionSeparator::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #383043;
}
.LandingPage .SectionSeparator::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #383043;
}
.LandingPage .SectionSeparator img {
  height: 37px;
  margin-left: 24px;
  margin-right: 24px;
}
.LandingPage .PlasmAudit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.LandingPage .PlasmAudit__title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.1px;
  margin-right: 20px;
  text-transform: uppercase;
}
.LandingPage .PlasmAudit img {
  height: 22px;
  margin-left: 6px;
}

.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.LoginPage__logo {
  height: 148px;
  margin-top: 50px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 699px) {
  .LoginPage__logo {
    height: 94px;
  }
}
.LoginPage__show-other-wallets {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
.LoginPage__clear-cache {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  background-color: #100E14;
  position: fixed;
  bottom: 10px;
}
.LoginPage__other-wallets {
  margin-top: 50px;
  max-width: 330px;
}
.LoginPage__other-wallets div {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 13px;
}
.LoginPage__other-wallets p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
.LoginPage .LoginButton {
  max-width: 330px;
}
.LoginPage__members-only {
  font-size: 21px;
  line-height: 23px;
  color: #FF2965;
  letter-spacing: 3px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.LoginPage__description {
  font-size: 18px;
  font-weight: 500;
  color: #72638C;
  line-height: 22px;
  text-align: center;
  max-width: 700px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 699px) {
  .LoginPage__description {
    max-width: 224px;
  }
}
.LoginPage__buy-link {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #FF5436;
  line-height: 23px;
  margin-top: 30px;
  cursor: pointer;
}
.LoginPage__theta-pass {
  margin-top: 100px;
  height: 27px;
}

.AccessDeniedPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.AccessDeniedPage__logo {
  height: 128px;
  margin-bottom: 15px;
  margin-top: 50px;
}
.AccessDeniedPage__title {
  font-size: 86px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 106px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media only screen and (max-width: 699px) {
  .AccessDeniedPage__title {
    font-size: 40px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 349px) {
  .AccessDeniedPage__title {
    font-size: 36px;
    line-height: 42px;
  }
}
.AccessDeniedPage__description {
  font-size: 18px;
  font-weight: 500;
  color: #72638C;
  line-height: 22px;
  text-align: center;
  max-width: 700px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 699px) {
  .AccessDeniedPage__description {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
    margin-bottom: 80px;
  }
}
.AccessDeniedPage__prove-ownership-link {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #FF5436;
  line-height: 23px;
  margin-top: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.CollectionPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.CollectionPage .Logo {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.NFTCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.NFTCard {
  margin: 10px;
}
.NFTCard img {
  width: 250px;
  height: 250px;
  background-color: #202326;
  transition: all ease-in-out 0.2s;
}
.NFTCard__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: white;
  margin-top: 26px;
  text-align: center;
}
.NFTCard:hover img {
  opacity: 0.85;
}

.BadgeCard {
  margin: 10px;
}
.BadgeCard img {
  width: 100px;
  height: 100px;
  transition: all ease-in-out 0.2s;
  object-fit: contain;
}
.BadgeCard__name {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: white;
  margin-top: 8px;
  text-align: center;
}
.BadgeCard:hover img {
  opacity: 0.85;
}

.NFTPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.NFTPage__panels {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
@media only screen and (max-width: 899px) {
  .NFTPage__panels {
    flex-direction: column;
  }
}

.NFTAssetsPanel {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}
@media only screen and (max-width: 899px) {
  .NFTAssetsPanel {
    max-width: unset;
    margin-bottom: 20px;
  }
}

.NFTInfoPanel {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  flex: 1;
}
@media only screen and (max-width: 1059px) {
  .NFTInfoPanel {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 899px) {
  .NFTInfoPanel {
    align-items: center;
    padding-left: 0;
  }
}
.NFTInfoPanel__name-and-tags {
  display: flex;
  flex-direction: row;
}
.NFTInfoPanel__name {
  font-size: 36px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  color: white;
}
@media only screen and (max-width: 899px) {
  .NFTInfoPanel__name {
    letter-spacing: 3px;
    font-size: 24px;
  }
}
.NFTInfoPanel__owner {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}
.NFTInfoPanel__actions {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 899px) {
  .NFTInfoPanel__actions {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.NFTInfoPanel__section-title {
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
.NFTInfoPanel__attributes {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 10px;
}
.NFTInfoPanel__tva-link {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  /*keep text on one line */
  overflow: hidden;
  /*prevent text from being shown outside the border */
  text-overflow: ellipsis;
  /*cut off text with an ellipsis*/
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100vw - 60px);
}
.NFTInfoPanel__tva-link:hover {
  opacity: 0.85;
}

.NFTAssetContainer {
  width: 550px;
  height: 550px;
}
@media only screen and (max-width: 1059px) {
  .NFTAssetContainer {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 479px) {
  .NFTAssetContainer {
    width: 100%;
    height: calc(100vw - 40px);
  }
}
.NFTAssetContainer img, .NFTAssetContainer iframe {
  width: 100%;
  height: 100%;
}
.NFTAssetContainer .NFTPFPContainer {
  width: 100%;
  height: 100%;
  background-color: #202326;
  position: relative;
}
.NFTAssetContainer .NFTPFPContainer__background-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.NFTAssetContainer .NFTPFPContainer__background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NFTAssetContainer .NFTPFPContainer__title {
  font-size: 28px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  margin-bottom: 6px;
}
@media only screen and (max-width: 479px) {
  .NFTAssetContainer .NFTPFPContainer__title {
    font-size: 22px;
    line-height: 26px;
  }
}
.NFTAssetContainer .NFTPFPContainer__subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .NFTAssetContainer .NFTPFPContainer__subtitle {
    font-size: 12px;
  }
}
.NFTAssetContainer .NFTPFPContainer__avatar-img {
  max-width: 200px;
  max-height: 200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 479px) {
  .NFTAssetContainer .NFTPFPContainer__avatar-img {
    max-width: 140px;
    max-height: 140px;
  }
}
.NFTAssetContainer .NFTPFPContainer__download-link {
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #76FF80;
  line-height: 23px;
  cursor: pointer;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.NFTAttributeCard {
  background-color: #202326;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px;
  width: calc(50% - 10px);
}
.NFTAttributeCard__name {
  font-size: 12px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 6px;
  text-align: center;
}
.NFTAttributeCard__value {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: white;
  text-align: center;
}

.NFTAssetTabList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;
  padding-inline-start: 0;
}
.NFTAssetTabList .react-tabs__tab {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  cursor: pointer;
}
.NFTAssetTabList .react-tabs__tab:last-of-type {
  margin-right: 0;
}
.NFTAssetTabList .react-tabs__tab * {
  cursor: inherit;
}
.NFTAssetTabList .react-tabs__tab img {
  height: 48px;
  width: 48px;
}
.NFTAssetTabList .react-tabs__tab span {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
}
.NFTAssetTabList .react-tabs__tab--selected:after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #76FF80;
}

.Airdrop999ersPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.Airdrop999ersPage__logo {
  height: 450px;
  margin-top: 0;
  margin-bottom: 30px;
}
@media only screen and (max-width: 699px) {
  .Airdrop999ersPage__logo {
    height: 280px;
  }
}
.Airdrop999ersPage__description {
  font-size: 16px;
  font-weight: 500;
  color: #72638C;
  line-height: 22px;
  text-align: center;
  max-width: 520px;
  margin-top: 30px;
}
@media only screen and (max-width: 699px) {
  .Airdrop999ersPage__description {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
    margin-bottom: 80px;
  }
}
.Airdrop999ersPage__all-set {
  font-size: 18px;
  font-weight: 500;
  color: #76FF80;
  line-height: 22px;
  text-align: center;
  max-width: 350px;
  margin-top: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 699px) {
  .Airdrop999ersPage__all-set {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
    margin-bottom: 40px;
  }
}

.DiscordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #1C1824;
}
.DiscordPage__logo {
  width: 540px;
  margin-top: 30px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 699px) {
  .DiscordPage__logo {
    width: 300px;
    margin-bottom: 30px;
  }
}
.DiscordPage__perks {
  font-size: 16px;
  font-weight: 500;
  color: #72638C;
  line-height: 19px;
  text-align: center;
  max-width: 520px;
  margin-bottom: 45px;
  width: 100%;
}
@media only screen and (max-width: 699px) {
  .DiscordPage__perks {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
  }
}
.DiscordPage__perks a.Link {
  color: #FF5436;
}
.DiscordPage__perks-title {
  font-size: 18px;
  line-height: 17px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #72638C;
  margin-bottom: 10px;
}
.DiscordPage .CodeInput {
  display: flex;
  align-items: center;
  background-color: #100E14;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #382F47;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  /* Chrome, Safari, Edge, Opera */
}
.DiscordPage .CodeInput--focused {
  border-color: #76FF80;
}
.DiscordPage .CodeInput input::placeholder {
  opacity: 0.75;
  color: #72638C;
}
.DiscordPage .CodeInput input {
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  color: white;
  margin-left: 2px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  text-align: center;
}
.DiscordPage .CodeInput input::-webkit-outer-spin-button,
.DiscordPage .CodeInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ThetaConBadgeSwapPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.ThetaConBadgeSwapPage__logo {
  height: 300px;
  margin-top: 0;
}
@media only screen and (max-width: 699px) {
  .ThetaConBadgeSwapPage__logo {
    height: 200px;
  }
}
.ThetaConBadgeSwapPage__description {
  font-size: 16px;
  font-weight: 500;
  color: #72638C;
  line-height: 22px;
  text-align: center;
  max-width: 520px;
  margin-top: 20px;
}
@media only screen and (max-width: 699px) {
  .ThetaConBadgeSwapPage__description {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
    margin-bottom: 20px;
  }
}
.ThetaConBadgeSwapPage__all-set {
  font-size: 18px;
  font-weight: 500;
  color: #76FF80;
  line-height: 22px;
  text-align: center;
  max-width: 350px;
  margin-top: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 699px) {
  .ThetaConBadgeSwapPage__all-set {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
    margin-bottom: 40px;
  }
}
.ThetaConBadgeSwapPage__token-id-input {
  background-color: #282332;
  border-radius: 0px;
  border: 0;
  outline: none;
  color: white;
  font-size: 18px;
  text-align: center;
  height: 44px;
  margin-top: 12px;
}

.FloorRaiserEventPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.FloorRaiserEventPage__title {
  font-size: 58px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #76FF80;
  line-height: 69px;
}
@media only screen and (max-width: 699px) {
  .FloorRaiserEventPage__title {
    font-size: 40px;
    line-height: 50px;
  }
}
.FloorRaiserEventPage__passaway {
  width: 310px;
  margin-bottom: 26px;
  margin-top: 15px;
}
.FloorRaiserEventPage__badges {
  width: 650px;
  margin-bottom: 47px;
}
@media only screen and (max-width: 699px) {
  .FloorRaiserEventPage__badges {
    width: 100%;
  }
}
.FloorRaiserEventPage__welcome {
  font-size: 20px;
  color: white;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 699px) {
  .FloorRaiserEventPage__welcome {
    font-size: 18px;
  }
}
.FloorRaiserEventPage__welcome-subtitle {
  font-size: 16px;
  color: #75808C;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.FloorRaiserEventPage__section {
  font-size: 20px;
  font-weight: 500;
  color: #72638C;
  line-height: 24px;
  text-align: center;
  max-width: 700px;
  margin-bottom: 45px;
}
@media only screen and (max-width: 699px) {
  .FloorRaiserEventPage__section {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
  }
}
.FloorRaiserEventPage__section-title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.8px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
}

.LeaderboardTabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 100%;
}

.ParticipantsCounter {
  font-size: 14px;
  letter-spacing: 2.1px;
  font-weight: 500;
  color: #72638C;
  text-transform: uppercase;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
}
.ParticipantsCounter__count {
  color: white;
  margin-right: 6px;
}

.PrizeCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #202326;
  padding: 15px;
  max-width: 440px;
  margin-top: 17px;
  margin-bottom: 20px;
}
.PrizeCard img {
  margin-right: 20px;
  width: 61px;
}
.PrizeCard div {
  font-size: 14px;
  font-weight: 500;
  color: white;
  line-height: 17px;
}

.LeaderboardTable {
  width: calc(100vw - 12px);
  max-width: 700px;
  margin-top: 20px;
  max-width: 462px;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable {
    max-width: 270px;
  }
}
.LeaderboardTable th {
  font-size: 14px;
  line-height: 24px;
  color: #75808C;
  text-align: left;
  padding-bottom: 8px;
  font-weight: 500;
  font-weight: 600;
}
.LeaderboardTable td {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #54476B;
  vertical-align: middle;
}
.LeaderboardTable .PlacePrize {
  display: flex;
  align-items: center;
}
.LeaderboardTable .Place {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #75808C;
  width: 36px;
  padding-left: 6px;
}
.LeaderboardTable .Prize {
  display: flex;
  flex-direction: row;
}
.LeaderboardTable .PrizeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable .PrizeInfo {
    font-size: 12px;
  }
}
.LeaderboardTable .MainPrizeName {
  color: white;
}
.LeaderboardTable .SubPrizeName {
  color: #75808C;
}
.LeaderboardTable .PrizeImage {
  width: 52px;
  margin-right: 16px;
  align-self: center;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable .PrizeImage {
    width: 34px;
    margin-right: 12px;
  }
}
.LeaderboardTable .Participant {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #72638C;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable .Participant {
    font-size: 10px;
    max-width: 100px;
  }
}
.LeaderboardTable .Participant span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.LeaderboardTable .Participant img {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-right: 10px;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable .Participant img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}
.LeaderboardTable .Points {
  font-size: 14px;
  line-height: 17px;
  color: #72638C;
  text-align: right;
  padding-right: 12px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
@media only screen and (max-width: 699px) {
  .LeaderboardTable .Points {
    font-size: 14px;
  }
}

.DreadfulValentineEventPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.DreadfulValentineEventPage__title {
  font-size: 58px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #FF5436;
  line-height: 69px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: center;
}
@media only screen and (max-width: 699px) {
  .DreadfulValentineEventPage__title {
    font-size: 40px;
    line-height: 50px;
  }
}
.DreadfulValentineEventPage__card {
  width: 100%;
  max-width: 338px;
  margin-bottom: 26px;
  margin-top: 15px;
}
.DreadfulValentineEventPage__welcome {
  font-size: 16px;
  color: white;
  line-height: 19px;
  letter-spacing: 1.6px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  text-align: center;
  max-width: 630px;
  text-transform: uppercase;
}
.DreadfulValentineEventPage__welcome-subtitle {
  font-size: 16px;
  color: #75808C;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.DreadfulValentineEventPage__cta-button {
  margin-bottom: 60px;
}
.DreadfulValentineEventPage__section-title {
  font-size: 16px;
  color: white;
  line-height: 19px;
  letter-spacing: 1.6px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  text-align: center;
  max-width: 630px;
  text-transform: uppercase;
}

.DreadfulValentineEntries {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: calc(100vw - 20px);
  max-width: 1080px;
  margin-top: 30px;
}

.DreadfulValentineEntry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 338px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
}
.DreadfulValentineEntry__card {
  width: 100%;
  margin-bottom: 20px;
}
.DreadfulValentineEntry__avatar-wrapper {
  position: relative;
}
.DreadfulValentineEntry__avatar {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.DreadfulValentineEntry__username {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  line-height: 24px;
}
.DreadfulValentineEntry__date {
  font-size: 14px;
  font-weight: 500;
  color: #72638C;
  line-height: 24px;
}
.DreadfulValentineEntry__votes {
  font-size: 20px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 1px;
  color: #FF5436;
}
.DreadfulValentineEntry__vote-button {
  margin-top: 20px;
  width: 270px;
}

.CreateDreadfulValentineEventPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.CreateDreadfulValentineEventPage__input {
  width: 100%;
  max-width: 350px;
  height: 90px;
  background-color: #202326;
  color: white;
  border: none;
  font-size: 16px;
}
.CreateDreadfulValentineEventPage textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.CreateDreadfulValentineEventPage textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.7);
}
.CreateDreadfulValentineEventPage textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.7);
}
.CreateDreadfulValentineEventPage textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.CreateDreadfulValentineEventPage textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.DreadfulValentineCard {
  position: relative;
  width: 786px;
  height: 1048px;
  opacity: 0;
}
.DreadfulValentineCard__text-wrapper {
  position: absolute;
  top: 220px;
  left: 60px;
  right: 60px;
  height: 250px;
  width: 666px;
}
.DreadfulValentineCard__text {
  font-size: 96px;
  font-weight: 800;
  color: #F5EB8D;
  height: 250px;
  word-break: break-word;
  hyphens: auto;
  text-align: center;
  width: 666px;
  font-family: "RoasterOriginal-Regular";
  text-transform: lowercase;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 3px;
}

.OperationManhuntEventPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.OperationManhuntEventPage__title {
  font-size: 58px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #FF2965;
  line-height: 69px;
  text-align: center;
}
@media only screen and (max-width: 699px) {
  .OperationManhuntEventPage__title {
    font-size: 40px;
    line-height: 50px;
  }
}
.OperationManhuntEventPage__passaway {
  width: 550px;
  margin-bottom: 26px;
  margin-top: 15px;
}
@media only screen and (max-width: 559px) {
  .OperationManhuntEventPage__passaway {
    width: 100%;
  }
}
.OperationManhuntEventPage__badges {
  width: 300px;
  margin-bottom: 47px;
}
.OperationManhuntEventPage__welcome {
  font-size: 20px;
  color: white;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 699px) {
  .OperationManhuntEventPage__welcome {
    font-size: 18px;
  }
}
.OperationManhuntEventPage__welcome-subtitle {
  font-size: 16px;
  color: #75808C;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.OperationManhuntEventPage__section {
  font-size: 20px;
  font-weight: 500;
  color: #72638C;
  line-height: 24px;
  text-align: center;
  max-width: 700px;
  margin-bottom: 45px;
}
@media only screen and (max-width: 699px) {
  .OperationManhuntEventPage__section {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
  }
}
.OperationManhuntEventPage__section-title {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.8px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
}

.NFTStakingPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DataSectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
}
.DataSectionHeader__title {
  font-size: 19px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 2.85px;
  color: #72638C;
  text-transform: uppercase;
}
@media only screen and (max-width: 799px) {
  .DataSectionHeader__title {
    font-size: 16px;
  }
}
.DataSectionHeader__actions {
  margin-left: auto;
}

.StakePlots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-top: 20px;
}

.StakePlot {
  max-width: 217px;
  border: 3px dashed #382F47;
  padding: 12px;
  border-radius: 12px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 16px;
  position: relative;
}
.StakePlot__rate {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #76FF80;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
.StakePlot__rate img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.StakePlot__editing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FF2965;
  text-align: center;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
  width: calc(100% + 6px);
  right: 0;
  bottom: 0;
  left: -3px;
  top: -3px;
  height: calc(100% + 6px);
}
.StakePlot__editing img {
  width: 34px;
  height: 34px;
  margin-bottom: 12px;
}
.StakePlot__editing div {
  width: 76px;
}
.StakePlot__editing img {
  transition: all 0.2s ease-in-out;
}
.StakePlot__editing:hover {
  cursor: pointer;
}
.StakePlot__editing:hover img {
  transform: scale(1.05);
}
.StakePlot__editing:active img {
  transform: scale(0.95);
}
.StakePlot__editing * {
  cursor: inherit;
}

.StakePlotPowerUps {
  background-color: #2E273B;
  height: 77px;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
}
.StakePlotPowerUps__title {
  font-size: 11px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 14px;
  color: #72638C;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: center;
}
.StakePlotPowerUps__slots {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PowerUpSlot {
  height: 33px;
  width: 33px;
  position: relative;
}
.PowerUpSlot .CircularProgressbar {
  position: absolute;
  top: -1.5px;
  left: -1.5px;
  height: 36px;
  width: 36px;
  z-index: 0;
}
.PowerUpSlot--perma-active {
  height: 40px;
  width: 40px;
  margin-top: -3.5px;
}
.PowerUpSlot--perma-active img {
  height: 100%;
  width: 100%;
}
.PowerUpSlot img {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.PowerUpSlot * {
  cursor: pointer;
}

.ActivePowerUp {
  height: 33px;
  width: 33px;
  position: relative;
}
.ActivePowerUp .CircularProgressbar {
  position: absolute;
  top: -1.5px;
  left: -1.5px;
  height: 36px;
  width: 36px;
  z-index: 0;
}
.ActivePowerUp img {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.ActivePowerUp * {
  cursor: pointer;
}
.ActivePowerUp--Permanent {
  height: 40px;
  width: 40px;
}
.ActivePowerUp--Permanent img {
  height: 100%;
  width: 100%;
}
.ActivePowerUp--large {
  height: 150px;
  width: 150px;
}
.ActivePowerUp--large .CircularProgressbar {
  top: -5px;
  left: -5px;
  height: 160px;
  width: 160px;
}

.PlasmStakingPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PlasmStakingPage__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #76FF80;
  opacity: 1;
  margin-bottom: 10px;
}
@media only screen and (max-width: 799px) {
  .PlasmStakingPage__title {
    font-size: 42px;
    line-height: 42px;
  }
}
.PlasmStakingPage .CTAPillLink {
  margin-bottom: 30px;
}
.PlasmStakingPage__description {
  font-size: 18px;
  font-weight: 500;
  color: #72638C;
  line-height: 22px;
  text-align: center;
  max-width: 700px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 699px) {
  .PlasmStakingPage__description {
    font-size: 14px;
    line-height: 18px;
    max-width: 334px;
  }
}
.PlasmStakingPage .LoginButton {
  max-width: 330px;
}
.PlasmStakingPage .PlasmActionContainer {
  flex: unset;
  margin-bottom: 0 !important;
}
.PlasmStakingPage__arrow {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 26px;
}
.PlasmStakingPage__unstake-button {
  color: #72638C;
  font-size: 12px;
  text-decoration: underline;
  margin-top: -22px;
  cursor: pointer;
}
.PlasmStakingPage__unstake-button:hover {
  color: #5f5274;
}
.PlasmStakingPage__7734-guardian-program {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 40px;
  color: #72638C;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
@media only screen and (max-width: 799px) {
  .PlasmStakingPage__7734-guardian-program {
    margin-top: 40px;
  }
}
.PlasmStakingPage__7734-guardian-program img {
  height: 150px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 799px) {
  .PlasmStakingPage__7734-guardian-program img {
    height: 120px;
  }
}
.PlasmStakingPage__7734-guardian-program .Button {
  margin-top: 0px;
}

.TPMCHomePage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TPMCHomePage__cta-cards {
  display: flex;
  flex-direction: row;
  margin-left: -22.5px;
  margin-right: -22.5px;
}
@media only screen and (max-width: 799px) {
  .TPMCHomePage__cta-cards {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}
.TPMCHomePage__cta-card {
  flex: 1;
  margin-left: 22.5px;
  margin-right: 22.5px;
  cursor: pointer;
}
@media only screen and (max-width: 799px) {
  .TPMCHomePage__cta-card {
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0;
  }
}
.TPMCHomePage__cta-card img {
  width: 100%;
  border-radius: 20px;
  cursor: inherit;
}
.TPMCHomePage__buy-on-title {
  margin-top: 80px;
  font-size: 14px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  letter-spacing: 2.1px;
  line-height: 17px;
  text-transform: uppercase;
  color: #72638C;
  margin-bottom: 30px;
}
.TPMCHomePage__buy-on-logos {
  display: flex;
  flex-direction: row;
  margin-left: -35px;
  margin-right: -35px;
}
@media only screen and (max-width: 799px) {
  .TPMCHomePage__buy-on-logos {
    flex-direction: column;
  }
}
.TPMCHomePage__buy-on-logo {
  flex: 1;
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 30px;
}
.TPMCHomePage__buy-on-logo img {
  width: 100%;
  height: 48px;
}

.TPMCHeroBanners {
  width: 100%;
  margin-bottom: 70px;
}
.TPMCHeroBanners .carousel {
  padding-top: 24px;
  cursor: pointer;
}
.TPMCHeroBanners .control-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 !important;
  margin-top: 20px !important;
}
.TPMCHeroBanners .carousel-slider {
  padding-bottom: 30px;
}

.TPMCHeroBanner {
  width: 100%;
  cursor: pointer;
}
.TPMCHeroBanner img {
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.CarouselNavButton {
  width: 30px;
  height: 72px;
  display: block;
  position: absolute;
  top: calc(50% - 36px);
  z-index: 200;
}
@media only screen and (max-width: 479px) {
  .CarouselNavButton {
    display: none;
  }
}
.CarouselNavButton--prev {
  left: 0;
}
.CarouselNavButton--next {
  left: auto;
  right: 0;
}
.CarouselNavButton img {
  width: 100%;
  height: 100%;
}

.CarouselIndicator {
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 8px;
}
.CarouselIndicator__inner {
  width: 8px;
  height: 8px;
  background-color: #72638C;
  cursor: inherit;
  border-radius: 8px;
}
.CarouselIndicator--selected .CarouselIndicator__inner {
  background-color: #76FF80;
}

.ApprovalsPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
.ApprovalsPage__cards {
  display: flex;
  flex-direction: row;
  margin-left: -22.5px;
  margin-right: -22.5px;
  width: 100%;
}
@media only screen and (max-width: 799px) {
  .ApprovalsPage__cards {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}
.ApprovalsPage__title {
  font-size: 20px;
  color: white;
  line-height: 24px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.78px;
}
@media only screen and (max-width: 699px) {
  .ApprovalsPage__title {
    font-size: 18px;
  }
}
.ApprovalsPage__subtitle {
  font-size: 16px;
  color: #72638C;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.ApprovalsPage__cards {
  display: flex;
  flex-wrap: wrap;
}

.ApprovalCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 14px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #2E273B;
  width: calc(50% - 16px);
  margin-bottom: 16px;
}
@media only screen and (max-width: 699px) {
  .ApprovalCard {
    width: 100%;
  }
}
.ApprovalCard__description {
  font-weight: 500;
  font-size: 16px;
  color: #72638C;
  margin-right: 10px;
  line-height: 19px;
  color: white;
  color: #72638C;
}
.ApprovalCard__contract {
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-size: 15px;
}
.ApprovalCard .Button {
  margin-left: auto;
}

.NFTDropPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 479px) {
  .NFTDropPage {
    padding-bottom: 300px;
    padding-top: 10px;
    margin-top: 15px;
    margin-top: 0;
    padding-top: 0;
  }
}
.NFTDropPage__title {
  font-size: 29px;
  line-height: 35px;
  color: white;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .NFTDropPage__title {
    font-size: 22px;
    line-height: 26px;
  }
}
.NFTDropPage__subtitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .NFTDropPage__subtitle {
    font-size: 12px;
    line-height: 15px;
  }
}
.NFTDropPage .PlasmCTA {
  margin-left: 192px;
  margin-top: 10px;
  z-index: 0;
}
@media only screen and (max-width: 639px) {
  .NFTDropPage .PlasmCTA {
    margin-left: 0;
    margin-top: 0;
  }
}
.NFTDropPage .NFTDrop {
  margin-top: 35px;
}
@media only screen and (max-width: 479px) {
  .NFTDropPage .NFTDrop {
    margin-top: 20px;
  }
  .NFTDropPage .NFTDrop .NFTDropSection--buy {
    position: fixed;
    bottom: 0;
    max-width: 330px;
    width: 100%;
    background-color: #100E14;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
}

.LotteryPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
@media only screen and (max-width: 479px) {
  .LotteryPage {
    padding-top: 10px;
    padding-bottom: 300px;
    margin-top: 15px;
  }
}
.LotteryPage__title {
  font-size: 29px;
  line-height: 35px;
  color: white;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .LotteryPage__title {
    font-size: 22px;
    line-height: 26px;
  }
}
.LotteryPage__subtitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  white-space: pre;
}
@media only screen and (max-width: 479px) {
  .LotteryPage__subtitle {
    font-size: 12px;
    line-height: 15px;
  }
}
.LotteryPage .NFTDrop {
  margin-top: 35px;
}
@media only screen and (max-width: 479px) {
  .LotteryPage .NFTDrop {
    margin-top: 20px;
  }
  .LotteryPage .NFTDrop .LotterySection--buy {
    position: fixed;
    bottom: 0;
    max-width: 330px;
    width: 100%;
    background-color: #100E14;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.ProofOfTransphormPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;
}
.ProofOfTransphormPage__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  letter-spacing: 2.85px;
  font-size: 19px;
  line-height: 22px;
  text-transform: uppercase;
}
.ProofOfTransphormPage__edition {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: white;
  letter-spacing: 2.2px;
  font-size: 44px;
  line-height: 58px;
  margin-bottom: 10px;
}
.ProofOfTransphormPage__asset {
  width: 100%;
  max-width: 350px;
  height: 350px;
  margin-bottom: 20px;
}
.ProofOfTransphormPage__asset img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProofOfTransphormPage__asset iframe {
  width: 100%;
  height: 100%;
}
.ProofOfTransphormPage__asset video {
  width: 100%;
  height: 100%;
}
.ProofOfTransphormPage .NFTAssetsList {
  margin-bottom: 20px;
}
.ProofOfTransphormPage__share-button {
  margin-bottom: 30px;
}
.ProofOfTransphormPage__share-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ProofOfTransphormPage__share-icons .react-share__ShareButton {
  margin-right: 6px;
}
.ProofOfTransphormPage__token-ids {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 290px;
}
.ProofOfTransphormPage__token-ids .KeyValue {
  flex: 1;
}
.ProofOfTransphormPage .KeyValue {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}
.ProofOfTransphormPage .KeyValue__label {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 2px;
  line-height: 16px;
}
.ProofOfTransphormPage .KeyValue__value {
  font-weight: 500;
  font-size: 13px;
  color: white;
}
.ProofOfTransphormPage__transphorm-yours {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 41px;
}
.ProofOfTransphormPage__transphorm-yours__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 14px;
  line-height: 16px;
}
.ProofOfTransphormPage__7734-initiative-logo {
  height: 38px;
}
.ProofOfTransphormPage__7734-initiative-logo img {
  height: 100%;
  width: auto;
}
.ProofOfTransphormPage__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  max-width: 480px;
}
.ProofOfTransphormPage__info__title {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 3px;
  line-height: 16px;
}
.ProofOfTransphormPage__info__text {
  font-size: 14px;
  font-weight: 500;
  color: #72638C;
  text-align: center;
}

.NFTAssetsList {
  display: flex;
  flex-direction: row;
  height: 52px;
  width: 100%;
  max-width: 350px;
  justify-content: space-between;
}

.NFTAssetsListItem {
  width: 52px;
  height: 52px;
  border: 1px solid #382F47;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.NFTAssetsListItem--selected {
  border: 1px solid #76FF80;
}
.NFTAssetsListItem--type-video::after, .NFTAssetsListItem--type-iframe::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  /* Upper part of the triangle */
  border-bottom: 6px solid transparent;
  /* Lower part of the triangle */
  border-left: 12px solid white;
  /* The color and width of the triangle */
  transform: translate(-50%, -50%);
  /* Center the triangle */
  cursor: pointer;
  /* Optional, for a pointer cursor on hover */
}
.NFTAssetsListItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  cursor: inherit;
}

.VIPPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.VIPPage__disclaimers {
  white-space: pre-line;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #72638C;
  margin-top: 18px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.VIPPage__title {
  font-size: 19px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 2.85px;
  color: #72638C;
  margin-bottom: 12px;
}
@media only screen and (max-width: 479px) {
  .VIPPage__title {
    font-size: 22px;
    line-height: 26px;
  }
}
.VIPPage__subtitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  color: #72638C;
  max-width: 354px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .VIPPage__subtitle {
    font-size: 12px;
    line-height: 15px;
  }
}
.VIPPage .VIPTierColumns {
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;
}
.VIPPage .VIPTierColumn {
  background: #17151D;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  max-width: 254px;
}
@media only screen and (max-width: 799px) {
  .VIPPage .VIPTierColumn {
    max-width: 400px;
    padding-top: 30px;
  }
}
.VIPPage .VIPTierColumn__icon {
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 799px) {
  .VIPPage .VIPTierColumn__icon {
    display: none;
  }
}
.VIPPage .VIPTierColumn__button {
  height: 26px;
  width: 100%;
  max-width: 164px;
  color: #100E14;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 29px;
}
.VIPPage .VIPTierColumn__required-title {
  font-size: 13px;
  font-weight: bold;
  color: #72638C;
  text-transform: uppercase;
  line-height: 16px;
  margin-bottom: 10px;
}
.VIPPage .VIPTierColumn__required-amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 26px;
  color: white;
}
.VIPPage .VIPTierColumn__required-amount::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.VIPPage .VIPTierColumn__divider {
  height: 1px;
  background: #382F47;
  width: 100%;
  max-width: 214px;
}
.VIPPage .VIPTierColumn--bronze .VIPTierColumn__button {
  background: transparent linear-gradient(0deg, #733A2B 0%, #814635 17%, #A86652 50%, #E79A7F 94%, #F0A286 100%) 0% 0% no-repeat padding-box;
}
.VIPPage .VIPTierColumn--silver .VIPTierColumn__button {
  background: transparent linear-gradient(0deg, #415F73 0%, #4E6C81 17%, #728FA8 50%, #ABC7E7 94%, #B4D0F0 100%) 0% 0% no-repeat padding-box;
}
.VIPPage .VIPTierColumn--gold .VIPTierColumn__button {
  background: transparent linear-gradient(180deg, #F0BA17 0%, #B28612 47%, #8B6510 82%, #7D590F 100%) 0% 0% no-repeat padding-box;
}
.VIPPage .VIPTierColumn--essu .VIPTierColumn__button {
  background: transparent linear-gradient(180deg, #F400EF 0%, #6B0A91 100%) 0% 0% no-repeat padding-box;
}
.VIPPage .VIPTierBenefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #382F47;
}
.VIPPage .VIPTierBenefit:last-of-type {
  border-bottom: none;
}
.VIPPage .VIPTierBenefit__coming-soon {
  font-size: 11px;
  font-weight: bold;
  color: #76FF80;
  text-transform: uppercase;
  height: 11px;
}
.VIPPage .VIPTierBenefit__icon {
  height: 30px;
  width: 30px;
  margin-bottom: 24px;
}
.VIPPage .VIPTierBenefit__title {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.65px;
  color: white;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
}
.VIPPage .VIPTierBenefit__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  text-align: center;
  height: 60px;
}

.VIPTabs {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 74px;
  background: #100E14;
  margin-bottom: 10px;
  z-index: 2;
  width: 100vw;
  justify-content: center;
}

.VIPTab {
  font-size: 14px;
  font-weight: bold;
  color: #72638C;
  line-height: 17px;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  background: #100E14;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.VIPTab * {
  cursor: pointer;
}
.VIPTab img {
  height: 70px;
  width: 70px;
  margin-bottom: 7px;
  opacity: 0.35;
}
@media only screen and (max-width: 479px) {
  .VIPTab img {
    height: 60px;
    width: 60px;
  }
}
.VIPTab__name {
  font-size: 12px;
  font-weight: bold;
  color: white;
  line-height: 15px;
  letter-spacing: 0.6px;
  opacity: 0.35;
  text-transform: uppercase;
}
.VIPTab.active {
  color: white;
  border-bottom: 2px solid #18C99D;
}
.VIPTab--selected img {
  opacity: 1;
}
.VIPTab--selected .VIPTab__name {
  opacity: 1;
}

.VIPUserTier {
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
}
.VIPUserTier__title {
  font-size: 13px;
  font-weight: bold;
  color: #72638C;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}
.VIPUserTier__divider {
  height: 29px;
  width: 1px;
  background: #382F47;
  margin-left: 15px;
  margin-right: 15px;
}
.VIPUserTier__word-and-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
  align-items: center;
}
.VIPUserTier__word {
  height: 13px;
}
.VIPUserTier__amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: white;
}
.VIPUserTier__amount::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.VIPWithdrawals {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.VIPWithdrawals__title {
  font-size: 13px;
  font-weight: bold;
  color: #72638C;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}
.VIPWithdrawals__amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
  color: white;
}
.VIPWithdrawals__amount::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.VIPWithdrawals__available-in {
  font-size: 15px;
  color: #72638C;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
}
.VIPWithdrawals .Button {
  margin-top: 6px;
  margin-bottom: 10px;
}

.LoaderModal {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C1824;
  border-radius: 6px;
  border: 1px solid #382F47;
  max-width: 980px;
  width: 120px;
  height: 120px;
}
.LoaderModal__footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 120px;
  width: 320px;
  left: 50%;
  transform: translateX(-50%);
}
.LoaderModal__title {
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #76FF80;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 22px;
  text-align: center;
}
.LoaderModal__subtitle {
  font-size: 15px;
  font-weight: 500;
  color: #72638C;
  line-height: 18px;
  margin-top: 8px;
  text-align: center;
  white-space: pre;
}
.LoaderModal__cancel {
  margin-top: 20px;
}

.WelcomeModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;
}
@media only screen and (max-width: 499px) {
  .WelcomeModal {
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }
}
.WelcomeModal__logo {
  height: 120px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 499px) {
  .WelcomeModal__logo {
    height: 90px;
  }
}
.WelcomeModal__logo img {
  height: 100%;
}
.WelcomeModal__welcome {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  color: #76FF80;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.WelcomeModal__misadventure-title {
  font-size: 46px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 46px;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 499px) {
  .WelcomeModal__misadventure-title {
    font-size: 36px;
    line-height: 36px;
  }
}
.WelcomeModal__message {
  font-size: 18px;
  line-height: 24px;
  color: #72638C;
  font-weight: 500;
  margin-bottom: 60px;
}
@media only screen and (max-width: 499px) {
  .WelcomeModal__message {
    margin-bottom: 30px;
  }
}
.WelcomeModal__continue {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #FF5436;
  line-height: 23px;
  cursor: pointer;
  text-transform: uppercase;
}

.ShopModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;
}
@media only screen and (max-width: 499px) {
  .ShopModal {
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }
}
.ShopModal__header {
  width: 100%;
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.ShopModal__header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 499px) {
  .ShopModal__header {
    height: 100px;
  }
}
.ShopModal__exclusive {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  color: #FF2965;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  margin-bottom: 6px;
  text-transform: uppercase;
  margin-top: 160px;
}
@media only screen and (max-width: 499px) {
  .ShopModal__exclusive {
    margin-top: calc((100vh - 400px - 100px) / 3);
    margin-top: 100px;
  }
}
.ShopModal__shop-title {
  font-size: 46px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 46px;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 499px) {
  .ShopModal__shop-title {
    font-size: 36px;
    line-height: 36px;
  }
}
.ShopModal__message {
  font-size: 18px;
  line-height: 24px;
  color: #72638C;
  font-weight: 500;
  margin-bottom: 60px;
}
@media only screen and (max-width: 499px) {
  .ShopModal__message {
    margin-bottom: 30px;
  }
}
.ShopModal__continue {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 23px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 50px;
  color: white;
}

.ProveOwnershipModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;
}
@media only screen and (max-width: 499px) {
  .ProveOwnershipModal {
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }
}
.ProveOwnershipModal__title {
  font-size: 46px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 46px;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 499px) {
  .ProveOwnershipModal__title {
    font-size: 36px;
    line-height: 36px;
  }
}
.ProveOwnershipModal__subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #72638C;
  font-weight: 500;
  margin-bottom: 60px;
}
@media only screen and (max-width: 499px) {
  .ProveOwnershipModal__subtitle {
    margin-bottom: 30px;
  }
}
.ProveOwnershipModal__cancel {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #75808C;
  line-height: 23px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 12px;
}

.PassawaySelectorModal__passaways {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.PassawaySelectorModal__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
}
.PassawaySelectorModal__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #382F47;
}
.PassawaySelectorModal__disclaimer {
  font-size: 12px;
  color: #72638C;
  text-align: center;
  margin-top: 20px;
}
.PassawaySelectorModal .EmptyState {
  height: calc(100% - 68px);
}

.PassawayCard {
  background-color: #2E273B;
  border-radius: 14px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 30px;
}
.PassawayCard__footer {
  padding: 10px;
}
.PassawayCard__transphormation-in {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #72638C;
  text-align: center;
  margin-bottom: 5px;
}
.PassawayCard .Button {
  width: 100%;
}

.UseInventoryModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}

.SupplyStoreModal .RoundedModalHeader {
  height: 168px;
  position: relative;
}
.SupplyStoreModal .RoundedModalHeader__logo {
  height: 100%;
  position: absolute;
  z-index: -1;
}
.SupplyStoreModal .RoundedModalHeader .TextTabs {
  margin-top: auto !important;
}
.SupplyStoreModal .RoundedModalHeader .TextTabList {
  margin-bottom: 0;
  margin-top: 0;
}
.SupplyStoreModal .RoundedModal__content {
  height: calc(100% - 168px);
}
.SupplyStoreModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.SupplyStoreModal .EmptyState {
  height: calc(100% - 168px);
}

@media only screen and (max-width: 799px) {
  .PlasmModal {
    max-height: 90vh;
  }
}
.PlasmModal__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
}
@media only screen and (max-width: 799px) {
  .PlasmModal__content {
    flex-direction: column;
    align-items: center;
  }
}

.PlasmActionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-width: 218px;
}
@media only screen and (max-width: 799px) {
  .PlasmActionContainer {
    margin-bottom: 60px;
  }
}
.PlasmActionContainer__title {
  font-size: 14px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 17px;
  color: #76FF80;
  text-transform: uppercase;
}
.PlasmActionContainer__subtitle {
  font-size: 12px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: #72638C;
  text-transform: uppercase;
}
.PlasmActionContainer__balance {
  font-size: 28px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 34px;
  color: white;
  margin-top: 4px;
  margin-bottom: 25px;
}
.PlasmActionContainer .Button {
  width: 94px;
}
.PlasmActionContainer--center {
  align-items: center;
}
.PlasmActionContainer--center .PlasmActionInputField {
  align-items: center;
}

.PlasmActionInputField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.PlasmActionInputField__title {
  font-size: 13px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 15px;
  color: #72638C;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.PlasmActionInputField .PlasmInput {
  max-width: 218px;
  margin-bottom: 12px;
}
.PlasmActionInputField .PercentageInput {
  max-width: 78px;
  width: 100%;
  margin-bottom: 12px;
}
.PlasmActionInputField .PercentageInput input {
  width: 100%;
}

.PlasmInput, .PercentageInput {
  display: flex;
  align-items: center;
  background-color: #100E14;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #382F47;
  position: relative;
  transition: all 0.2s ease-in-out;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.PlasmInput--focused, .PercentageInput--focused {
  border-color: #76FF80;
}
.PlasmInput input::placeholder, .PercentageInput input::placeholder {
  opacity: 0.75;
  color: #72638C;
}
.PlasmInput img, .PercentageInput img {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 8px;
  pointer-events: none;
}
.PlasmInput input, .PercentageInput input {
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  color: white;
  margin-left: 2px;
  font-weight: 500;
  font-size: 16px;
  text-indent: 36px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.PlasmInput--xlarge, .PercentageInput--xlarge {
  height: 60px;
}
.PlasmInput--xlarge img, .PercentageInput--xlarge img {
  width: 36px;
  height: 36px;
  left: 12px;
}
.PlasmInput--xlarge input, .PercentageInput--xlarge input {
  text-indent: 60px;
  font-size: 29px;
}
.PlasmInput__all-button, .PercentageInput__all-button {
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  margin-left: 8px;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  color: #72638C;
  position: absolute;
  right: 4px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.PlasmInput__all-button:hover, .PercentageInput__all-button:hover {
  opacity: 0.9;
}
.PlasmInput__percentage, .PercentageInput__percentage {
  font-size: 16px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  padding-right: 6px;
  padding-left: 6px;
  color: #72638C;
}
.PlasmInput input::-webkit-outer-spin-button,
.PlasmInput input::-webkit-inner-spin-button, .PercentageInput input::-webkit-outer-spin-button,
.PercentageInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.PlasmInput input[type=number], .PercentageInput input[type=number] {
  -moz-appearance: textfield;
}

.PercentageInput input {
  text-indent: 6px;
}

.ProductModal {
  max-width: 600px;
  height: unset;
  max-height: unset;
  padding-bottom: 35px;
}
.ProductModal__content {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  padding-left: 40px;
}
@media only screen and (max-width: 799px) {
  .ProductModal__content {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}
.ProductModal__image {
  height: 150px;
  width: 150px;
  margin-right: 48px;
}
@media only screen and (max-width: 799px) {
  .ProductModal__image {
    margin-right: 0;
    margin-bottom: 20px;
    height: 120px;
    width: 120px;
  }
}
.ProductModal__details {
  display: flex;
  flex-direction: column;
  width: 264px;
  text-align: left;
}
@media only screen and (max-width: 799px) {
  .ProductModal__details {
    width: 100%;
  }
}
.ProductModal__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  color: white;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 8px;
}
.ProductModal__name .BetaBadge {
  width: 40px;
  margin-left: 6px;
}
.ProductModal__description {
  font-weight: 500;
  color: #72638C;
  font-size: 14px;
  line-height: 17px;
  min-height: 43px;
  margin-bottom: 12px;
}
.ProductModal__line-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ProductModal .ProductModalLineItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #382F47;
  height: 32px;
}
.ProductModal .ProductModalLineItem__left {
  font-weight: 500;
  color: #72638C;
  font-size: 13px;
  line-height: 16px;
}
.ProductModal .ProductModalLineItem__right {
  font-weight: 500;
  color: white;
  font-size: 13px;
  line-height: 16px;
}
.ProductModal__footer {
  margin-top: 30px;
}
.ProductModal__buy-button {
  width: 100%;
}
@media only screen and (max-width: 799px) {
  .ProductModal__buy-button {
    width: 100%;
  }
}
.ProductModal__sold-out-button {
  pointer-events: none;
  margin-top: 15px;
}

.AlertModal {
  max-height: 394px;
  max-width: 600px;
  height: auto;
  min-height: unset;
}
.AlertModal__icon {
  height: 38px;
  margin-bottom: 24px;
  margin-top: 10px;
}
.AlertModal__body {
  font-size: 18px;
  font-weight: 500;
  color: white;
  line-height: 22px;
  text-align: center;
  padding-left: 70px;
  padding-right: 70px;
  white-space: pre-line;
}
@media only screen and (max-width: 799px) {
  .AlertModal__body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.AlertModal__footer {
  margin-top: 40px;
  padding-bottom: 40px;
}
.AlertModal__footer .Button {
  margin-left: 6px;
  margin-right: 6px;
}
.AlertModal .RoundedModalHeader {
  border-bottom: none;
}

.CraftingModal {
  max-height: 630px;
}
.CraftingModal .RoundedModalHeader--expanded {
  height: 90px;
}
.CraftingModal .RoundedModalHeader--expanded .RoundedModalHeader__title {
  margin-top: 20px;
}
.CraftingModal--expanded .RoundedModal__content {
  height: calc(100% - 90px);
}
.CraftingModal .RoundedModalHeader {
  position: relative;
  flex-direction: column;
}
.CraftingModal .RoundedModalHeader__logo {
  height: 100%;
  position: absolute;
  z-index: -1;
}
.CraftingModal .RoundedModalHeader .TextTabs {
  margin-top: auto !important;
}
.CraftingModal .RoundedModalHeader .TextTabList {
  margin-bottom: 0;
  margin-top: 0;
}
.CraftingModal--crafted {
  background-color: #191521;
}
.CraftingModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.CraftingModal .EmptyState {
  height: calc(100% - 90px);
}
.CraftingModal .RoundedModalHeader {
  position: relative;
  flex-direction: column;
}

.CraftedContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.CraftedContent__result {
  height: 100%;
  width: 100%;
}
.CraftedContent__result img, .CraftedContent__result video {
  height: 350px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}
@media only screen and (max-width: 799px) {
  .CraftedContent__result img, .CraftedContent__result video {
    height: 250px;
  }
}
.CraftedContent__title {
  font-size: 30px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 38px;
  letter-spacing: 1.58px;
  color: white;
  margin-bottom: 9px;
  text-align: center;
  text-transform: uppercase;
}
.CraftedContent__description {
  font-size: 13px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 15px;
  color: #76FF80;
  text-align: center;
  text-transform: uppercase;
}
.CraftedContent__done-button {
  margin-top: 20px;
  width: 132px;
}

.CraftingContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.CraftingContent__back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
  height: 30px;
}
.CraftingContent__back-button * {
  cursor: inherit;
}
.CraftingContent__back-button img {
  margin-right: 8px;
  width: 7px;
}
.CraftingContent__back-button:hover {
  opacity: 0.9;
}
.CraftingContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.CraftingContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  height: 42px;
  text-align: center;
  margin-bottom: 15px;
}
.CraftingContent__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
}
@media only screen and (max-width: 799px) {
  .CraftingContent__inputs {
    overflow-x: scroll;
    justify-content: flex-start;
  }
}
.CraftingContent__craft-button {
  width: 282px;
  margin-bottom: 18px;
}
.CraftingContent__price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.CraftingContent__price-prefix {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #72638C;
  margin-right: 8px;
}
.CraftingContent__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #76FF80;
  line-height: 17px;
  margin-bottom: auto;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.CraftingContent__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}
.CraftingContent .RequiredApprovals {
  margin-top: 20px;
}

.CraftableItemOrdersContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.CraftableItemOrdersContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.CraftableItemOrdersContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  height: 42px;
  text-align: center;
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}
.CraftableItemOrdersContent__orders {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 799px) {
  .CraftableItemOrdersContent__orders {
    margin-top: -20px;
  }
}
.CraftableItemOrdersContent__button {
  margin-bottom: 40px;
}

.LockedTokensContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.LockedTokensContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.LockedTokensContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  height: 42px;
  text-align: center;
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}
.LockedTokensContent__locked-tokens {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 799px) {
  .LockedTokensContent__locked-tokens {
    margin-top: -20px;
  }
}
.LockedTokensContent__button {
  margin-bottom: 40px;
}

.RedeemingModal {
  max-height: 630px;
}
.RedeemingModal .RoundedModalHeader--expanded {
  height: 90px;
}
.RedeemingModal .RoundedModalHeader--expanded .RoundedModalHeader__title {
  margin-top: 20px;
}
.RedeemingModal .RoundedModalHeader {
  position: relative;
  flex-direction: column;
}
.RedeemingModal .RoundedModalHeader__logo {
  height: 100%;
  position: absolute;
  z-index: -1;
}
.RedeemingModal .RoundedModalHeader .TextTabs {
  margin-top: auto !important;
}
.RedeemingModal .RoundedModalHeader .TextTabList {
  margin-bottom: 0;
  margin-top: 0;
}
.RedeemingModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.RedeemingModal .RequiredApprovals {
  margin-top: 20px;
}

.RedeemedContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.RedeemedContent__result {
  height: 100%;
  width: 100%;
}
.RedeemedContent__result img, .RedeemedContent__result video {
  height: 350px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}
@media only screen and (max-width: 799px) {
  .RedeemedContent__result img, .RedeemedContent__result video {
    height: 250px;
  }
}
.RedeemedContent__title {
  font-size: 30px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 38px;
  letter-spacing: 1.58px;
  color: white;
  margin-bottom: 9px;
  text-align: center;
  text-transform: uppercase;
}
.RedeemedContent__description {
  font-size: 13px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 15px;
  color: #76FF80;
  text-align: center;
  text-transform: uppercase;
}
.RedeemedContent__done-button {
  margin-top: 20px;
  width: 132px;
}

.RedeemingContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.RedeemingContent__back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  color: #72638C;
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
  height: 30px;
}
.RedeemingContent__back-button * {
  cursor: inherit;
}
.RedeemingContent__back-button img {
  margin-right: 8px;
  width: 7px;
}
.RedeemingContent__back-button:hover {
  opacity: 0.9;
}
.RedeemingContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.RedeemingContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  height: 42px;
  text-align: center;
  margin-bottom: 15px;
}
.RedeemingContent__warning {
  font-size: 14px;
  font-weight: 500;
  color: #FF2965;
}
.RedeemingContent__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media only screen and (max-width: 799px) {
  .RedeemingContent__inputs {
    overflow-x: scroll;
    justify-content: flex-start;
  }
}
.RedeemingContent__inputs--1 {
  justify-content: center !important;
}
.RedeemingContent__redeem-button {
  width: 282px;
  margin-bottom: 24px;
}

.RedemptionsContent {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 24px;
}

.RedemptionCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 14px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #2E273B;
  width: calc(33.333% - 16px);
  margin-bottom: 16px;
}
@media only screen and (max-width: 699px) {
  .RedemptionCard {
    width: 100%;
  }
}
.RedemptionCard__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.RedemptionCard__locked {
  font-size: 16px;
  font-weight: 500;
  color: white;
  line-height: 19px;
}
.RedemptionCard__unlocks {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #72638C;
}
.RedemptionCard__button {
  margin-left: auto;
}

.TransphormModal {
  max-height: 630px;
}
.TransphormModal--crafted {
  background-color: #191521;
}
.TransphormModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.TransphormModal .RoundedModalHeader {
  height: 105px;
  position: relative;
  flex-direction: column;
}
.TransphormModal .RoundedModalHeader__logo {
  margin-top: 10px;
  height: 32px;
  margin-bottom: -10px;
}
.TransphormModal .RoundedModal__content {
  height: calc(100% - 105px);
}

.Hide {
  opacity: 0;
}

.TransphormedContent {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  position: relative;
}
.TransphormedContent__result {
  height: 350px;
  width: 350px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
@media only screen and (max-width: 799px) {
  .TransphormedContent__result {
    height: 250px;
    width: 250px;
  }
}
.TransphormedContent__result img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
  animation-duration: 3s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
@media only screen and (max-width: 799px) {
  .TransphormedContent__result img {
    height: 250px;
    width: 250px;
  }
}
.TransphormedContent__result__before {
  z-index: 2;
}
.TransphormedContent__result__after {
  z-index: 1;
}
.TransphormedContent__result canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation-duration: 6s;
  animation-delay: 3s;
  z-index: 3;
}
.TransphormedContent__title {
  font-size: 30px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 38px;
  letter-spacing: 1.58px;
  color: white;
  margin-bottom: 9px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TransphormedContent__title__before {
  position: absolute;
  z-index: 1;
}
.TransphormedContent__title__after {
  position: absolute;
  z-index: 2;
  animation-delay: 1.5s;
  opacity: 0;
}
.TransphormedContent__description {
  font-size: 13px;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  line-height: 15px;
  color: #76FF80;
  text-align: center;
  text-transform: uppercase;
}
.TransphormedContent__done-button {
  margin-top: 15px;
  width: 132px;
}

.TransphormContent {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  position: relative;
}
.TransphormContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.TransphormContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  text-align: center;
  margin-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
}
.TransphormContent__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 799px) {
  .TransphormContent__inputs {
    margin-top: -20px;
  }
}
@media only screen and (max-width: 699px) {
  .TransphormContent__inputs .CraftableItemInputCard {
    transform: scale(0.65);
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 0;
  }
}
.TransphormContent .RequiredApprovals {
  margin-top: 20px;
}
.TransphormContent__plus-icon {
  align-self: center;
  width: 30px;
  height: 30px;
  margin-left: 40px;
  margin-right: 40px;
}
@media only screen and (max-width: 699px) {
  .TransphormContent__plus-icon {
    width: 16px;
    height: 16px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.TransphormContent__transphorm-button {
  width: 282px;
  margin-bottom: 24px;
}
.TransphormContent__price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.TransphormContent__price-prefix {
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #72638C;
  margin-right: 8px;
}
.TransphormContent__price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #76FF80;
  line-height: 17px;
  margin-bottom: auto;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.TransphormContent__price::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 18px;
  display: block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.TransphormOrdersContent {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  position: relative;
}
.TransphormOrdersContent__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: white;
  margin-bottom: 5px;
  text-align: center;
}
.TransphormOrdersContent__description {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #72638C;
  height: 42px;
  text-align: center;
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}
.TransphormOrdersContent__orders {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
@media only screen and (max-width: 799px) {
  .TransphormOrdersContent__orders {
    margin-top: -20px;
  }
}
@media only screen and (max-width: 699px) {
  .TransphormOrdersContent__orders .CraftableItemInputCard {
    transform: scale(0.65);
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 0;
  }
}
.TransphormOrdersContent__transphorm-button {
  margin-bottom: 40px;
}

.MarketplacesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;
}
@media only screen and (max-width: 499px) {
  .MarketplacesModal {
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }
}
.MarketplacesModal__title {
  font-size: 46px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 46px;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  margin-bottom: 15px;
}
@media only screen and (max-width: 499px) {
  .MarketplacesModal__title {
    font-size: 30px;
    line-height: 36px;
  }
}
.MarketplacesModal__subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #72638C;
  font-weight: 500;
  margin-bottom: 30px;
}
@media only screen and (max-width: 499px) {
  .MarketplacesModal__subtitle {
    margin-bottom: 15px;
    font-size: 16px;
  }
}
.MarketplacesModal__disclaimer {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  color: #72638C;
  margin-top: -10px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 499px) {
  .MarketplacesModal__disclaimer {
    margin-bottom: 15px;
  }
}
.MarketplacesModal__cancel {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #fff;
  line-height: 23px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 12px;
}

.InventoryModal .RoundedModalHeader--expanded {
  height: 90px;
}
.InventoryModal .RoundedModalHeader--expanded .RoundedModalHeader__title {
  margin-top: 20px;
}
.InventoryModal .RoundedModalHeader {
  position: relative;
  flex-direction: column;
}
.InventoryModal .RoundedModalHeader .TextTabs {
  margin-top: auto !important;
  max-width: 100%;
}
.InventoryModal .RoundedModalHeader .TextTabList {
  margin-bottom: 0;
  margin-top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: left;
}
.InventoryModal .RoundedModalHeader .TextTabList .react-tabs__tab {
  flex-shrink: 0;
}
.InventoryModal .RoundedModal__content {
  height: calc(100% - 90px);
}
.InventoryModal .RoundedModal__content .TextTabs {
  margin-bottom: -20px;
}
.InventoryModal__items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
  padding-top: 30px;
}
.InventoryModal .EmptyState {
  height: calc(100% - 90px);
}

.MobileWalletsModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 486px;
  background-color: #000000;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 59px;
  padding-right: 59px;
}
@media only screen and (max-width: 499px) {
  .MobileWalletsModal {
    width: 100vw;
    padding-left: 22px;
    padding-right: 22px;
    height: 100vh;
    justify-content: center;
  }
}
.MobileWalletsModal__title {
  font-size: 46px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: white;
  line-height: 46px;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 499px) {
  .MobileWalletsModal__title {
    font-size: 36px;
    line-height: 36px;
  }
}
.MobileWalletsModal__subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #72638C;
  font-weight: 500;
  margin-bottom: 60px;
}
@media only screen and (max-width: 499px) {
  .MobileWalletsModal__subtitle {
    margin-bottom: 30px;
  }
}
.MobileWalletsModal__apps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.MobileWalletsModal__app {
  display: flex;
  flex-direction: column;
}
.MobileWalletsModal__app img {
  border-radius: 9px;
  width: 60px;
  height: 60px;
}
.MobileWalletsModal__app span {
  font-size: 12px;
  color: white;
  margin-top: 6px;
}
.MobileWalletsModal__cancel {
  font-size: 16px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  color: #75808C;
  line-height: 23px;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 12px;
}

.UnstakePlasmModal {
  max-width: 320px;
  max-height: 360px;
}
.UnstakePlasmModal__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
}
@media only screen and (max-width: 799px) {
  .UnstakePlasmModal__content {
    flex-direction: column;
  }
}

.GetPlasmModal {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: auto;
  min-height: 300px;
}
.GetPlasmModal .PlasmInput {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
}
.GetPlasmModal .PlasmInput input {
  text-indent: 66px;
  text-align: left;
}
.GetPlasmModal .PlasmInput--empty input {
  font-size: 20px;
  font-weight: 500;
  font-family: "futura-pt", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.GetPlasmModal .PlasmInput--empty:after {
  display: none !important;
}
@media only screen and (max-width: 799px) {
  .GetPlasmModal {
    max-height: 90vh;
  }
}
.GetPlasmModal .RoundedModal__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.GetPlasmModal__content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.GetPlasmModal__cost-title {
  font-size: 12px;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: #72638C;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.GetPlasmModal__tfuel-amount {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  color: white;
  font-weight: bold;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
  width: 100%;
  justify-content: center;
  height: 26px;
  align-items: center;
  margin-bottom: 35px;
}
.GetPlasmModal__tfuel-amount img {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}
.GetPlasmModal .Button {
  margin-top: auto;
}
.GetPlasmModal input {
  text-align: center;
  color: #76FF80;
  font-weight: bold;
  width: 100%;
  font-family: "futura-pt-bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Verdana", "Arial", sans-serif;
}
.GetPlasmModal .PlasmInput::after {
  display: block;
  content: "PLASM";
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
  margin-left: 12px;
  pointer-events: none;
}

.VIPDepositModal {
  max-width: 350px;
  max-height: 500px;
}
.VIPDepositModal .RoundedModal__content {
  height: calc(100% - 142px);
}
.VIPDepositModal .RoundedModalHeader {
  height: 142px;
}
.VIPDepositModal .TPMCWarning {
  margin-left: auto;
  margin-right: auto;
}
.VIPDepositModal__required-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: #76FF80;
  text-transform: uppercase;
  line-height: 16px;
  margin-bottom: 0px;
}
.VIPDepositModal__required-title::before {
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAt1BMVEUAAACC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/omC/okWFxobHx4eJCEiKSQmLygYGhwrOSwoNSpMilE/bkRu1HUjMyd04XtZp19TmVkxUDUtPy998oN243xOjFMtRTF78IJ88YNoxm4xRzNx2XdlvWphtmdFfEpBakMzTDUqQi9rynBVnFpQkVVMhU9FfEtfsGRapF5KgU5DdEc7Y0BTllg2UjhszXJhuGc6XD04WDtCswHjAAAADHRSTlMAwCBgQDDw4NCgkICSa+IYAAAEc0lEQVRIx6VXh3bbMAxsHLdNUhIkQEp2vLcdO6tZTcf/f1cPlOR4tn0N8iLpEToBPCz6wyGpnZ6fmFJOzk9rH/5JahefzY58vvg7tg5Th+Sk/kfYxwQ7Av14HPeleumyddVpWkizc9W6rFa/HNvcWYnKgFEhKu7NrMSeHTRa/1TAciu2EOfKB7F5Af10YKenSTPK8B7vAgP+s5FROT2MazQToPTUORK7Xmg2DiHrabFVWvD4g2Ein1C+XG4ZlfpWGNL+skI/bEzj4BrwBLzO4rQxLDRZ2ucmQ2cJJ3Dsq83H40WjS7DodJOu21j0xjkUViQhz3bi17ISpD3JTcPcOkuJHFiMQrdYyidtqG1rK54fEy9K+9L0TZ49B5KYLKb9ks2zHIqlMpUYqpw90TgkPhs9bNQpLU5sVIsiQmydvze9RuJ2pNlXMromJm8M5uLJ4l1itcjIHvYCuzIfNPI1QfW1wUur0p92fRDS92LwMAeTDLSwBD9c9a3KZWWyZiDpY53Meg8KWIlh1nCwd6AkUBAPY53kloFofV5UBgVkatiD48j4gvfe+eh9ZIA14d2tVCYvAPycQhiclQErHDjynoNzy1e4CCcISGgoPulF2toTSk+bur4CCyxq0zJsvfaMmfvgydnkPEfxU1JiC19P19SIdeCQbfSOQ/R3qo8hRmYfoYKIVPQg18+r5IYRmOXVCluLIYTvCnxhPPru8yJn7NJFVyX7eQrGVVkUzPJsHlAbsBPHCmzjCzyE04/kIpVVdpUCoupOyqV+bkGQuWH4x+GnUZkHBPTB9L4tWWxz0bEZkr2jmg8VN2Y8mqTEuAEdnuMgAWfBB/fdjKfeKmARjQE7a6BV4MNs4i23zY2yz/QIP6FSZvB4g9jGzPSe0stvQFQebj9wuzMTDl04ODPmGqWwnHJXnxtN1RmsONoAkgJbzRLY5cBTUwDvuvjOcIYH6PoGgmLbdvUR0eIIVz126F9MIQ8UowtujGU7n8zMwKCpbJEzysu20hsiXKtvJXCmya6JvbC23c4n1wAmctbhABgiCuh1PM9NKaEk+EVsJSkcmwlA4BMuwmYIfXAVlNYl86sx/ScSjmTfEmAj5SIpVCzKKSg3v5gBvw9aLFoy0Li3lNtIchKCOCQ580+ELoZuy4yfAqMkhd1Okm+XVWSoYNlRfh+oy/wcwLEltqm0/FTWZVUUclsY/t+zrheFbKMNoetc9EUhqyYOrHVlIe+1DvgDJF5n75hJcGcEEja1ddh169htVtZLoGCtIh2J4qFgCeLdVrPaaY+roQ8Ch5lYG544H1nUD0GVDLfb435DhtOeiYqGzMQEoDbk2WZD3h8B9wgIJ+a9AoXSCKBsfwTsD53cwlyaVlGDQ3Fv6Bwbc22SmEa54GqrMUfrMXdssPZ0sCagxxWDdTzaGqzHR/mqma1H+XAQdkZ57S+HB9IpefzwcPy4ImmPSWjruHL8gBR3D0hx/4D0/iOZMnT0ENiuDoG1dxw733HQfcfR+l2H+f/++fAblpuk6GpXMDUAAAAASUVORK5CYII=");
  background-size: 16px;
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.VIPDepositModal__required-amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 26px;
  color: white;
}
.VIPDepositModal .VIPTabs, .VIPDepositModal .VIPTab {
  background: transparent;
}
.VIPDepositModal .VIPTabs {
  width: 100%;
  position: relative;
  top: unset;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0;
}
.VIPDepositModal .VIPTab img {
  width: 50px;
  height: 50px;
}
.VIPDepositModal__content {
  display: flex;
  padding-top: 40px;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: unset;
}
@media only screen and (max-width: 799px) {
  .VIPDepositModal__content {
    flex-direction: column;
    align-items: center;
  }
}
.VIPDepositModal__content .TPMCWarning {
  margin-top: 20px;
}
.VIPDepositModal__content .GetMorePlasmButton {
  bottom: 20px;
  position: absolute;
}
.VIPDepositModal__cooldown-messsage {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: #72638C;
  margin-top: 18px;
  white-space: pre;
}

