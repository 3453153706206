.CraftableItemInputCard{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #2E273B;
  width: 194px;
  height: 294px;
  border-radius: 14px;
  padding: 0;
  padding-bottom: 10px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 28px;
  flex-shrink: 0;

  &__image{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 194px;
    margin-bottom: 9px;
    object-fit: contain;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    background-color: black;
    padding: 15px;
  }

  .PassawayWithMeta{
    height: 194px;
    width: 100%;
    margin-bottom: 9px;
  }

  &--missing{
    .CraftableItemInputCard__image{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  &__name{
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 18px;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: auto;
  }

  &__description{
    font-size: 12px;
    font-weight: 500;
    color: #72638C;
    line-height: 15px;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__footer{
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-left: -3px;
    margin-right: -3px;
    padding-left: 10px;
    padding-right: 10px;

    .Button{
      margin-left: auto;
      margin-right: auto;
      width: 84px;
    }
  }
}