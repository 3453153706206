.CTABanner{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 20px;

  @include breakpoint("tablet", max) {
    align-items: unset;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__icon{
    height: 50px;
    width: 50px;
    margin-right: 40px;

    @include breakpoint("tablet", max) {
      margin-right: 14px;
    }
  }

  &__titles{
    display: flex;
    flex-direction: column;
  }

  &__titles-and-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @include breakpoint("tablet", max) {
      flex-direction: column;
      align-items: unset;
    }
  }

  &__title{
    font-size: 17px;
    font-weight: bold;
    font-family: $font-family-bold;
    letter-spacing: 0.85px;
    line-height: 25px;
    margin-bottom: 2px;
    text-transform: uppercase;

    @include breakpoint("tablet", max) {
      font-size: 13px;
      letter-spacing: 0.7px;
      line-height: 17px;
    }
  }

  &__subtitle{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    @include breakpoint("tablet", max) {
      //display: none;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__button{
    min-width: 130px;
    margin-left: auto;

    @include breakpoint("tablet", max) {
      margin-left: unset;
      margin-top: 15px;
    }
  }

  &--purple{
    background-color: #2C1338;

    .CTABanner__title{
      color: #E213E8;
    }

    .CTABanner__subtitle{
      color: #72638C;
    }
  }

  &--green{
    background-color: #192622;

    .CTABanner__title{
      color: #76FF80;
    }

    .CTABanner__subtitle{
      color: #668C68;
    }
  }
}