.Airdrop999ersPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__logo{
    height: 450px;
    margin-top: 0;
    margin-bottom: 30px;

    @include breakpoint("700px", max){
      height: 280px;
    }
  }

  &__description{
    font-size: 16px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 22px;
    text-align: center;
    max-width: 520px;
    margin-top: 30px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
      margin-bottom: 80px;
    }
  }

  &__all-set{
    font-size: 18px;
    font-weight: 500;
    color: $color-green;
    line-height: 22px;
    text-align: center;
    max-width: 350px;
    margin-top: 30px;
    margin-bottom: 20px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
      margin-bottom: 40px;
    }
  }
}
