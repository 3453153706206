.AlertModal{
  max-height: 394px;
  max-width: 600px;
  height: auto;
  min-height: unset;

  &__icon{
    height: 38px;
    margin-bottom: 24px;
    margin-top: 10px;
  }

  &__body{
    font-size: 18px;
    font-weight: 500;
    color: white;
    line-height: 22px;
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;
    white-space: pre-line;

    @include breakpoint("tablet", max){
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__footer{
    margin-top: 40px;
    padding-bottom: 40px;

    .Button{
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .RoundedModalHeader{
    border-bottom: none;
  }
}