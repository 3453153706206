.InventoryModal{

  .RoundedModalHeader--expanded{
    height: 90px;

    .RoundedModalHeader__title{
      margin-top: 20px;
    }
  }

  .RoundedModalHeader{
    position: relative;
    flex-direction: column;

    .TextTabs{
      margin-top: auto !important;
      max-width: 100%;
    }

    .TextTabList{
      margin-bottom: 0;
      margin-top: 0;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: left;

      .react-tabs__tab{
        flex-shrink: 0;
      }
    }
  }

  .RoundedModal__content{
    height: calc(100% - 90px);

    .TextTabs{
      // SHift up for the power-up tab
      margin-bottom: -20px;
    }
  }

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }

  .EmptyState{
    height: calc(100% - 90px);
  }
}