.ProofOfTransphormPage{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 20px;

  &__title{
    font-family: $font-family-bold;
    color: #72638C;
    letter-spacing: 2.85px;
    font-size: 19px;
    line-height: 22px;
    text-transform: uppercase;
  }

  &__edition{
    font-family: $font-family-bold;
    color: white;
    letter-spacing: 2.2px;
    font-size: 44px;
    line-height: 58px;
    margin-bottom: 10px;
  }

  &__asset{
    width: 100%;
    max-width: 350px;
    height: 350px;
    margin-bottom: 20px;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    iframe{
      width: 100%;
      height: 100%;
    }

    video{
      width: 100%;
      height: 100%;
    }
  }

  .NFTAssetsList{
    margin-bottom: 20px;
  }

  &__share-button{
    margin-bottom: 30px;
  }

  &__share-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //margin-bottom: 30px;
    margin-top: 10px;

    .react-share__ShareButton{
      margin-right: 6px;
    }
  }

  &__token-ids{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 290px;

    .KeyValue{
      flex: 1;
    }
  }

  .KeyValue{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    &__label{
      font-family: $font-family-bold;
      color: #72638C;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 2px;
      line-height: 16px;
    }

    &__value{
      font-weight: 500;
      font-size: 13px;
      color: white;
    }
  }

  &__transphorm-yours{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 41px;

    &__title{
      font-family: $font-family-bold;
      color: white;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 14px;
      line-height: 16px;
    }
  }

  &__7734-initiative-logo{
    height: 38px;

    img{
        height: 100%;
        width: auto;
    }
  }

  &__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    max-width: 480px;

    &__title{
      font-family: $font-family-bold;
      color: #72638C;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 3px;
      line-height: 16px;
    }

    &__text{
      font-size: 14px;
      font-weight: 500;
      color: #72638C;
      text-align: center;
    }
  }
}



.NFTAssetsList{
  display: flex;
  flex-direction: row;
  height: 52px;
  width: 100%;
  max-width: 350px;
  justify-content: space-between;
}

.NFTAssetsListItem{
  width: 52px;
  height: 52px;
  border: 1px solid #382F47;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &--selected{
    border: 1px solid #76FF80;
  }

  &--type-video, &--type-iframe{
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 6px solid transparent; /* Upper part of the triangle */
      border-bottom: 6px solid transparent; /* Lower part of the triangle */
      border-left: 12px solid white; /* The color and width of the triangle */
      transform: translate(-50%, -50%); /* Center the triangle */
      cursor: pointer; /* Optional, for a pointer cursor on hover */
    }
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    cursor: inherit;
  }
}