.CollectionPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  .Logo{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.NFTCards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.NFTCard{
  margin: 10px;

  img{
    width: 250px;
    height: 250px;
    background-color: #202326;
    transition: all ease-in-out 0.2s;
  }

  &__name{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    margin-top: 26px;
    text-align: center;
  }

  &:hover{
    img{
      opacity: 0.85;
    }
  }
}

.BadgeCard{
  margin: 10px;

  img{
    width: 100px;
    height: 100px;
    transition: all ease-in-out 0.2s;
    object-fit: contain;
  }

  &__name{
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
    color: white;
    margin-top: 8px;
    text-align: center;
  }

  &:hover{
    img{
      opacity: 0.85;
    }
  }
}
