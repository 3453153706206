.ApprovalsPage{
  margin-left: auto;
  margin-right: auto;
  max-width: 1185px;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  &__cards{
    display: flex;
    flex-direction: row;
    margin-left: -22.5px;
    margin-right: -22.5px;
    width: 100%;

    @include breakpoint("tablet", max){
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__title{
    font-size: 20px;
    color: white;
    line-height: 24px;
    font-weight: bold;
    font-family: $font-family-bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.78px;

    @include breakpoint("700px", max){
      font-size: 18px;
    }
  }

  &__subtitle{
    font-size: 16px;
    color: #72638C;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  &__cards{
    display: flex;
    flex-wrap: wrap;
  }
}


.ApprovalCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 14px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #2E273B;
  width: calc(50% - 16px);
  margin-bottom: 16px;

  @include breakpoint("700px", max) {
    width: 100%;
  }

  &__description{
    font-weight: 500;
    font-size: 16px;
    color: #72638C;
    //white-space: pre;
    //color: white;
    margin-right: 10px;
    line-height: 19px;
    color: white;
    color: #72638C;

  }

  &__contract{
    font-weight: bold;
    font-family: $font-family-bold;
    font-size: 15px;
    //color: white;
    //color: #72638C;

  }

  .Button{
    margin-left: auto;
  }
}