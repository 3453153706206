.Tooltip {
  font-size: 12px !important;
  font-weight: 500 !important;
  pointer-events: auto !important;
  background-color: #2E273B !important;
  border-radius: 4px !important;
  opacity: 1.0!important;
  padding: 4px 12px !important;
}

.Tooltip::before,
.Tooltip::after {
  display: none !important;
}