.DreadfulValentineEventPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__title{
    font-size: 58px;
    font-family: $font-family-bold;
font-weight: bold;
    color: $color-orange;
    line-height: 69px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;


    @include breakpoint("700px", max){
      font-size: 40px;
      line-height: 50px;
    }
  }

  &__card{
    width: 100%;
    max-width: 338px;
    margin-bottom: 26px;
    margin-top: 15px;
  }

  &__welcome{
    font-size: 16px;
    color: white;
    line-height: 19px;
    letter-spacing: 1.6px;
    font-family: $font-family-bold;
font-weight: bold;
    text-align: center;
    max-width: 630px;
    text-transform: uppercase;
  }

  &__welcome-subtitle{
    font-size: 16px;
    color: #75808C;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  &__cta-button{
    margin-bottom: 60px;
  }

  &__section-title{
    font-size: 16px;
    color: white;
    line-height: 19px;
    letter-spacing: 1.6px;
    font-family: $font-family-bold;
    font-weight: bold;
    text-align: center;
    max-width: 630px;
    text-transform: uppercase;
  }
}

.DreadfulValentineEntries{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: calc(100vw - 20px);
  max-width: 1080px;
  margin-top: 30px;
}

.DreadfulValentineEntry{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 338px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;

  &__card{
    width: 100%;
    margin-bottom: 20px;
  }

  &__avatar-wrapper{
    position: relative;
  }

  &__avatar{
    height: 38px;
    width: 38px;
    border-radius: 50%;
  }

  &__twitter{

  }

  &__username{
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    color: white;
    line-height: 24px;
  }

  &__date{
    font-size: 14px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 24px;
  }

  &__votes{
    font-size: 20px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 1px;
    color: $color-orange;
  }

  &__vote-button{
    margin-top: 20px;
    width: 270px;
  }
}
