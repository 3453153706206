$border-radius: 5px;
$accordion-border-color: #d9d9d9;
$accordion-title-bg: #f6f6f6;
$icon-color: #777;
$active-title-bg: #eeeeee;
$title-bg: #444;

.accordion {
  border: 0;

  .accordion-item {
    .title {
      color: $color-text-grey;
      cursor: pointer;
      padding: 0px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      background-color: transparent;
      border-bottom: 0.5px solid transparentize(#75808C, 0.5);
      height: 65px;

      @include breakpoint("tablet", max){
        font-size: 16px;
        line-height: 20px;
      }

      &:after {
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        font-size: 18px;
        color: $color-green !important;
        float: right;
        margin-left: 5px;
      }
    }

    .panel {
      max-height: 0;
      transition: max-height 0.15s ease-out;
      overflow: hidden;
    }

    &.active {
      .title {
        color: white;
        border-color: transparent;
        &:after {
          content: '\2796'; /* Unicode character for "minus" sign (-) */
          color: $color-orange;
        }
      }
    }

    &.active {
      .panel {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
    }
  }
}
