.AccessDeniedPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__logo{
    height: 128px;
    margin-bottom: 15px;
    margin-top: 50px;
  }

  &__title{
    font-size: 86px;
    font-family: $font-family-bold;
font-weight: bold;
    color: white;
    line-height: 106px;
    text-transform: uppercase;
    margin-bottom: 15px;

    @include breakpoint("700px", max){
      font-size: 40px;
      line-height: 45px;
    }

    @include breakpoint("phone-small", max){
      font-size: 36px;
      line-height: 42px;
    }
  }

  &__description{
    font-size: 18px;
    font-weight: 500;
    color: $color-text-grey;
    line-height: 22px;
    text-align: center;
    max-width: 700px;
    margin-bottom: 60px;

    @include breakpoint("700px", max){
      font-size: 14px;
      line-height: 18px;
      max-width: 334px;
      margin-bottom: 80px;
    }
  }

  &__prove-ownership-link{
    font-size: 16px;
    font-family: $font-family-bold;
font-weight: bold;
    color: $color-orange;
    line-height: 23px;
    margin-top: 30px;
    cursor: pointer;
    text-transform: uppercase;
  }
}
