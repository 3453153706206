.Button{
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0 !important;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  transition: 0.1s ease-in-out opacity;
  text-decoration: none !important;
  background-color: $color-orange;
  font-family: $font-family-normal;
  font-family: $font-family-bold;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: $color-background;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;

  *, div{
    cursor: inherit !important;
  }

  &:hover{
    opacity: 0.9;
  }

  &:active{
    opacity: 0.8;
  }

  &--is-disabled{
    cursor: not-allowed;
    opacity: 0.8 !important;
  }

  &--is-small{
    height: 28px;
    line-height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    border-radius: 4px;
    letter-spacing: 0.6px;
  }

  &--is-medium{
    height: 36px;
    line-height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-family: $font-family-bold;
    font-weight: bold;
    border-radius: 9px;
    letter-spacing: 0.6px;
  }

  &--is-large{
    height: 40px;
    line-height: 40px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 14px;
    border-radius: 9px;
  }

  &--is-xlarge{
    height: 56px;
    line-height: 62px;
    font-size: 18px;
    font-weight: bold;
    font-family: $font-family-bold;
    letter-spacing: 0.91px;
    border-radius: 10px;
    padding-left: 36px;
    padding-right: 36px;

    svg{
      font-size: 17px;
    }
  }

  &--is-rounded{
    border-radius: 4px !important;
    box-shadow: 0px 0px 6px transparent;
    transition: all 0.2s ease-in-out;

    &.LoginButton{
      border-radius: 10px !important;
    }

    &.Button--is-xlarge{
      border-radius: 6px !important;
    }

    &:hover{
      &.Button--green{
        box-shadow: 0px 0px 6px #76FF80CC;
      }

      &.Button--red{
        box-shadow: 0px 0px 6px #FF2965CC;
      }

      &.Button--plasm{
        box-shadow: 0px 0px 6px #76FF80CC;
      }

      &.Button--tdrop{
        box-shadow: 0px 0px 6px #00D5F9CC;
      }

      &.Button--tfuel{
        box-shadow: 0px 0px 6px #FF9F13CC;
      }
    }

    &.Button--is-disabled{
      background: #72638C;
      opacity: 1.0 !important;
      box-shadow: none !important;
    }
  }

  &--is-pill{
    border-radius: 13px !important;
    box-shadow: 0px 0px 6px transparent;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0px;
    font-size: 13px;
    font-weight: bold;
    font-family: $font-family-bold;

    &:hover{
      &.Button--green{
        box-shadow: 0px 0px 6px #76FF80CC;
      }

      &.Button--red{
        box-shadow: 0px 0px 6px #FF2965CC;
      }
    }

    &.Button--red{
      background: rgba(64, 19, 36, 0.8) !important;
      color: $color-red !important;;
    }
  }

  &--is-outline{
    border-radius: 4px !important;
  }

  &--orange{
    background-color: $color-orange;
    color: $color-background;
  }

  &--red{
    background-color: $color-red;
    color: $color-background;
  }

  &--green{
    background-color: $color-green;
    color: $color-background;
  }

  &--black-green{
    background-color: #000000;
    color: $color-green;
    box-shadow: 0px 0px 20px #76FF8033;
  }

  &--plasm{
    background-color: $color-plasm;
    color: $color-background;
  }

  &--tdrop{
    background-color: $color-tdrop;
    color: $color-background;
  }

  &--tfuel{
    background-color: $color-tfuel;
    color: $color-background;
  }

  &--green-outline{
    border: 2px solid $color-green;
    color: $color-green;
    background-color: transparent;
  }

  &--grey{
    color: #2E273B;
    background-color: #72638C;
    box-shadow: none !important;
  }

  &--grey-outline{
    border: 2px solid #72638C;
    color: #72638C;
    background-color: transparent;
    box-shadow: none !important;

    &:hover{
      border-color: $color-green;
      color: $color-green;
    }
  }

  &--red-outline{
    border: 2px solid $color-red;
    color: $color-red;
    background-color: transparent;
    box-shadow: none !important;

    &:hover{
      border-color: $color-red;
      color: $color-red;
    }
  }

  &--black-orange{
    background-color: $color-orange;
  }

  &--thetacon-pink{
    background-color: #f24d84;
    color: $color-background;
  }

  &--thetacon-green{
    background-color: #3f9870;
    color: $color-background;
  }

  &--black{
    background-color: #1F1B26;
    color: white;

    &:hover{
      background-color: lighten(#1F1B26, 7%);
    }
  }

  &--black-with-grey{
    background-color: #1F1B26;
    color: #72638C;

    &:hover{
      background-color: lighten(#1F1B26, 7%);
    }
  }

  &--text-only-green{
    border-color: transparent;
    background-color: transparent;
    color: $color-green;
  }

  &--vip-tier-bronze {
    background: transparent linear-gradient(0deg, #733A2B 0%, #814635 17%, #A86652 50%, #E79A7F 94%, #F0A286 100%) 0% 0% no-repeat padding-box;
  }

  &--vip-tier-silver {
    background: transparent linear-gradient(0deg, #415F73 0%, #4E6C81 17%, #728FA8 50%, #ABC7E7 94%, #B4D0F0 100%) 0% 0% no-repeat padding-box;

  }

  &--vip-tier-gold {
    background: transparent linear-gradient(180deg, #F0BA17 0%, #B28612 47%, #8B6510 82%, #7D590F 100%) 0% 0% no-repeat padding-box;

  }

  &--vip-tier-essu {
    background: transparent linear-gradient(180deg, #F400EF 0%, #6B0A91 100%) 0% 0% no-repeat padding-box;

  }
}



.GetMorePlasmButton{
  background: transparentize($color-green, 0.88);
  color: $color-green;
  font-size: 12px;
  font-weight: bold;
  text-transform:uppercase;
  letter-spacing: 0.6px;
  width: 280px;
  border-radius: 4px;
  height: 32px;

  &__title{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img{
    width: 15px;
    height: 15px;
    margin-left: 6px;
    margin-right: 6px;
  }
}