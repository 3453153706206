.NFTPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__panels{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @include breakpoint("900px", max){
      flex-direction: column;
    }
  }
}

.NFTAssetsPanel{
  display: flex;
  flex-direction: column;
  max-width: 550px;

  @include breakpoint("900px", max){
    max-width: unset;
    margin-bottom: 20px;
  }
}

.NFTInfoPanel{
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  flex: 1;

  @include breakpoint("1060px", max){
    padding-left: 20px;
  }

  @include breakpoint("900px", max){
    align-items: center;
    padding-left: 0;
  }

  &__name-and-tags{
    display: flex;
    flex-direction: row;
  }

  &__name{
    font-size: 36px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 5.4px;
    text-transform: uppercase;
    color: white;

    @include breakpoint("900px", max){
      letter-spacing: 3.0px;
      font-size: 24px;
    }
  }

  &__owner{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  &__actions{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;

    @include breakpoint("900px", max){
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &__section-title{
    font-size: 13px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
  }

  &__attributes{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 10px;
  }

  &__tva-link{
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;      /*keep text on one line */
    overflow: hidden;         /*prevent text from being shown outside the border */
    text-overflow: ellipsis;  /*cut off text with an ellipsis*/
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 60px);

    &:hover{
      opacity: 0.85;
    }
  }
}

.NFTAssetContainer{
  width: 550px;
  height: 550px;

  @include breakpoint("1060px", max){
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint("phone-wide", max){
    width: 100%;
    height: calc(100vw - 40px);
  }

  img, iframe{
    width: 100%;
    height: 100%;
  }

  .NFTPFPContainer{
    width: 100%;
    height: 100%;
    background-color: #202326;
    position: relative;

    &__background-img{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    &__background-overlay{
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.85);
      width: 100%;
      height: 100%;
      z-index: 0;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title{
      font-size: 28px;
      font-family: $font-family-bold;
      font-weight: bold;
      line-height: 32px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      text-align: center;
      color: white;
      margin-bottom: 6px;

      @include breakpoint("phone-wide", max){
        font-size: 22px;
        line-height: 26px;
      }
    }

    &__subtitle{
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
      text-align: center;

      @include breakpoint("phone-wide", max){
        font-size: 12px;
      }
    }

    &__avatar-img{
      max-width: 200px;
      max-height: 200px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;

      @include breakpoint("phone-wide", max){
        max-width: 140px;
        max-height: 140px;
      }
    }

    &__download-link{
      font-size: 14px;
      font-family: $font-family-bold;
      font-weight: bold;
      color: $color-green;
      line-height: 23px;
      cursor: pointer;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
}

.NFTAttributeCard{
  background-color: #202326;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 8px;
  width: calc(50% - 10px);

  &__name{
    font-size: 12px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-bottom: 6px;
    text-align: center;
  }

  &__value{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: white;
    text-align: center;
  }
}

.NFTAssetTabs{

}

.NFTAssetTabList{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;
  padding-inline-start: 0;

  .react-tabs__tab{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    cursor: pointer;

    &:last-of-type{
      margin-right: 0;
    }

    *{
      cursor: inherit;
    }

    img{
      height: 48px;
      width: 48px;
    }

    span{
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center;
    }

    &--selected{
      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background-color: $color-green;
      }
    }
  }
}

