.TPMCWarning{
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  background: transparentize(#FF2965, 0.88);
  color: #FF2965;
  border-radius: 4px;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
}
