.RequiredApprovals{
  display: flex;
  flex-direction: row;
  justify-content: center;
  //align-items: center;
  width: 100%;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;

  &__title{
    font-size: 14px;
    color: #72638C;
    flex-shrink: 0;
  }

  &__approvals{
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scrollbar-face-color: transparent;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .Loader{
    margin-left: 12px;
  }
}

.RequiredApproval{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  flex-shrink: 0;
  cursor: pointer;

  &:hover{
    opacity: 0.85;
  }

  &__name{
    font-size: 14px;
    color: #72638C;
    text-decoration: underline;
  }

  img{
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
}