$sizes: (
        "phone-small":  350px,
        "phone":        400px,
        "phone-wide":   480px,
        "500px": 500px,
        "phablet":      560px,
        "tablet-small": 640px,
        "700px": 700px,
        "tablet":       800px,
        "900px": 900px,
        "950px": 950px,
        "1000px": 1000px,
        "1060px": 1060px,
        "tablet-wide":  1024px,
        "desktop":      1248px,
        "desktop-wide": 1440px,
        "1100px": 1100px,
        "1110px": 1110px
);

@mixin breakpoint($width, $type: min) {
  @if map_has_key($sizes, $width) {
    $width: map_get($sizes, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin breakpoint-height($height, $type: min) {
  @if $type == max {
    $height: $height - 1px;
  }
  @media only screen and (#{$type}-height: $height) {
    @content;
  }
}

.HideOnMobile{
  @include breakpoint("tablet", max){
    display: none !important;
  }
}

.HideOnNonMobile{
  @include breakpoint("tablet", min){
    display: none !important;
  }
}

.HideOnNonMobilePhones{
  @include breakpoint("phone-wide", min){
    display: none !important;
  }
}
