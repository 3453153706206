.PlasmModal{

  @include breakpoint("tablet", max){
    max-height: 90vh;
  }

  &__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;

    @include breakpoint("tablet", max){
      flex-direction: column;
      align-items: center;
    }
  }
}

.PlasmActionContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-width: 218px;

  @include breakpoint("tablet", max){
    margin-bottom: 60px;
  }

  &__title{
    font-size: 14px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 17px;
    color: $color-green;
    text-transform: uppercase;
  }

  &__subtitle{
    font-size: 12px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 16px;
    color: #72638C;
    text-transform: uppercase;
  }

  &__balance{
    font-size: 28px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 34px;
    color: white;
    margin-top: 4px;
    margin-bottom: 25px;
  }

  .Button{
    width: 94px;
  }

  &--center{
    align-items: center;

    .PlasmActionInputField{
      align-items: center;
    }
  }
}

.PlasmActionInputField{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title{
    font-size: 13px;
    font-family: $font-family-bold;
    font-weight: bold;
    line-height: 15px;
    color: #72638C;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .PlasmInput{
    max-width: 218px;
    margin-bottom: 12px;
  }

  .PercentageInput{
    max-width: 78px;
    width: 100%;
    margin-bottom: 12px;

    input{
      width: 100%;
    }
  }
}

.PlasmInput, .PercentageInput{
  display: flex;
  align-items: center;
  background-color: #100E14;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #382F47;
  position: relative;
  transition: all 0.2s ease-in-out;

  &--focused{
    border-color: $color-green;
  }

  input::placeholder {
    opacity: 0.75;
    color: #72638C;
  }


  img{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 8px;
    pointer-events: none;
  }

  input{
    background-color: transparent;
    height: 100%;
    border: none;
    outline: none;
    color: white;
    margin-left: 2px;
    font-weight: 500;
    font-size: 16px;
    text-indent: 36px;
    font-family: $font-family-bold;
    //font-size: 20px;
  }

  &--xlarge{
    height: 60px;

    img{
      width: 36px;
      height: 36px;
      left: 12px;
    }

    input{
      text-indent: 60px;
      font-size: 29px;

    }
  }

  &__all-button{
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    margin-left: 8px;
    height: 100%;
    line-height: 40px;
    cursor: pointer;
    color: #72638C;
    position: absolute;
    right: 4px;
    font-family: $font-family-bold;


    &:hover{
      opacity: 0.9;
    }
  }

  &__percentage{
    font-size: 16px;
    font-weight: bold;
    font-family: $font-family-bold;
    padding-right: 6px;
    padding-left: 6px;
    color: #72638C;
  }

  // Hide the arrows on number inputs
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.PercentageInput{
  input{
    text-indent: 6px;
  }
}
