.UnstakePlasmModal{
  max-width: 320px;
  max-height: 360px;

  &__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;

    @include breakpoint("tablet", max){
      flex-direction: column;
    }
  }
}