.Logo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 395px;

  @include breakpoint("phone-wide", max){
    max-width: 280px;
  }

  .LogoWords{
    margin-bottom: 3px;
  }
}

.LogoWords{

}

.LogoCharacters{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

  &__left{
    margin-right: 13px;
    animation: idle ease-in-out 4s infinite;
  }

  &__right{
    margin-left: 13px;
    animation: idle ease-in-out 4s infinite;
    animation-delay: 467ms;
  }
}

