.SupplyStoreModal{

  .RoundedModalHeader{
    height: 168px;
    position: relative;

    &__logo{
      height: 100%;
      position: absolute;
      z-index: -1;
    }

    .TextTabs{
      margin-top: auto !important;
    }

    .TextTabList{
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .RoundedModal__content{
    height: calc(100% - 168px);
  }

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    padding-top: 30px;
  }

  .EmptyState{
    height: calc(100% - 168px);
  }
}